import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as BaseApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphQlErrorToaster } from "../helpers/toaster";
import { RootState } from "../reducers";

import { removeAuthToken } from "../actions/auth";
import Debug, { NETWORK_ERROR } from "../app-debug";

import { removeRoles } from "../actions/roles";
import {
  ApolloContextName,
  BACKEND_SERVER_URLS,
  DeploymentEnvironment,
  OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY,
} from "../constants";

const ApolloProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  let token = useSelector<RootState>((state) => state.auth.token);
  const roles = useSelector<RootState>((state) => state.roles.roles) as string[];

  const getRole = (roleType: string) => {
    let role = roles[0];
    switch (roleType) {
      case "legal":
        if (roles.includes("legal_manager_supervisor")) {
          role = "legal_manager_supervisor";
        } else if (roles.includes("legal_manager")) {
          role = "legal_manager";
        }
        break;
      case "finance":
        if (roles.includes("finance_manager_supervisor")) {
          role = "finance_manager_supervisor";
        } else if (roles.includes("finance_manager")) {
          role = "finance_manager";
        }
        break;
      case "cps":
        if (roles.includes("case_agent_supervisor")) {
          role = "case_agent_supervisor";
        } else if (roles.includes("case_agent")) {
          role = "case_agent";
        }
        break;
    }
    return role;
  };

  const httpLink = createHttpLink({
    uri: (operation) => {
      const serviceName: ApolloContextName | undefined =
        operation.getContext()["serviceName"] || ApolloContextName.ClaimPurchasingService;

      if (!serviceName) {
        throw new Error("ApolloProvider: No service name found in context");
      }

      const deploymentEnvironment = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT as
        | DeploymentEnvironment
        | undefined;

      if (!deploymentEnvironment) {
        throw new Error("ApolloProvider: No deployment env variable found");
      }

      // No change in production environment
      if (deploymentEnvironment === DeploymentEnvironment.Production) {
        console.log(
          `ApolloProvider: serviceName=${serviceName} deploymentEnvironment=${deploymentEnvironment} using production urls`
        );

        return BACKEND_SERVER_URLS[DeploymentEnvironment.Production][serviceName] || "";
      }

      // if no overridden server in local storage it should be the current env
      const backendServer =
        (localStorage.getItem(
          OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY
        ) as DeploymentEnvironment) || deploymentEnvironment;

      console.log(
        `ApolloProvider: serviceName=${serviceName} deploymentEnvironment=${deploymentEnvironment} backendServer=${backendServer} storageItem=${
          localStorage.getItem(OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY) as DeploymentEnvironment
        }`
      );

      return BACKEND_SERVER_URLS[backendServer][serviceName];
    },
  });

  const authLink = new ApolloLink((operation, forward) => {
    const xHasuraRole: { [key: string]: string } = {};

    const roleType = (operation.getContext()["roleType"] as string) || undefined;

    if (roleType) {
      xHasuraRole["x-hasura-role"] = getRole(roleType);
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(!!token && { Authorization: `Bearer ${token}` }),
        ...xHasuraRole,
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (graphQLErrors[0]?.extensions?.code === "invalid-jwt") {
        dispatch(removeAuthToken());
        dispatch(removeRoles());
      } else {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message.startsWith("[{")) {
            // Validation error object
            const error: {
              constraints: {
                [k: string]: string;
              };
            }[] = JSON.parse(message);

            error.forEach((e) => {
              for (const [, value] of Object.entries(e.constraints)) {
                graphQlErrorToaster(value);
              }
            });
          } else {
            graphQlErrorToaster(message);
          }
        });
      }
    } else if (networkError) {
      graphQlErrorToaster(networkError?.message);
      Debug(NETWORK_ERROR)(networkError);
    } else {
      graphQlErrorToaster("An unknow error happened");
    }
  });

  const client = new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      mutate: {
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};

export default ApolloProvider;

import React from "react";
import { Icon } from "@blueprintjs/core";
import { Order_By } from "../../../types/graphql.types";

export interface ISort {
  onSortByColumn: Function;
  sortableColumns: String[];
  sortColumn: String;
  sortOrder?: String;
}

export interface IColumnHeaderProps {
  id: String;
  label: String;
  sort?: ISort;
}

const ColumnHeader: React.FC<IColumnHeaderProps> = (props) => {
  const sortOrder = props.sort?.sortOrder || Order_By.Desc;

  function toggleOrder(order: String) {
    if (order === Order_By.Asc) {
      return Order_By.Desc;
    }
    return Order_By.Asc;
  }

  function handleOnClick() {
    props.sort?.onSortByColumn({
      sortColumn: props.id,
      sortOrder: props.sort?.sortColumn === props.id ? toggleOrder(sortOrder) : Order_By.Desc,
    });
  }

  if (props.sort?.sortableColumns.includes(props.id)) {
    return (
      <th className="sortable" onClick={handleOnClick}>
        <div>
          {props.label}
          {props.sort?.sortColumn === props.id ? (
            sortOrder === Order_By.Asc ? (
              <Icon
                htmlTitle="Sort Ascending"
                icon="symbol-triangle-up"
                intent="primary"
                iconSize={12}
              />
            ) : (
              <Icon
                htmlTitle="Sort Descending"
                icon="symbol-triangle-down"
                intent="primary"
                iconSize={12}
              />
            )
          ) : (
            <Icon
              htmlTitle="Sort Descending"
              className="faded"
              icon="symbol-triangle-down"
              intent="primary"
              iconSize={12}
            />
          )}
        </div>
      </th>
    );
  }

  return <th>{props.label}</th>;
};

export default ColumnHeader;

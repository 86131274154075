import { useMutation, useQuery } from "@apollo/client";
import { Intent, Spinner, Tag } from "@blueprintjs/core";
import { ErrorMessage } from "components/shared/ErrorMessage";
import List from "components/shared/List";
import { GenericListHeader } from "components/shared/ListHeader";
import { Paginator } from "components/shared/Paginator";
import { humanizeString } from "helpers/humanize-string";
import { formatMoneyVerticals } from "helpers/moneyFormatter";
import { graphQlErrorToaster, successToaster } from "helpers/toaster";
import { useHandleOnSortByColumn } from "hooks/use-handle-on-sort-by-column";
import moment from "moment";
import omitEmpty from "omit-empty-es";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Fs_Customer_Payout_Order_By, Order_By } from "types/graphql.types";
import { ApolloContextName } from "../../../../constants";
import { ActionButtons } from "./action-buttons";
import { RESOLVE_CUSTOMER_PAYOUT } from "./mutation";
import { CustomerPayoutItem, PaymentStatus, GetCustomerPayoutsQueryResponse } from "./types";
import { getCustomerPayoutsQuery } from "./queries";

export const CustomerPayouts: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const handleOnSortByColumn = useHandleOnSortByColumn();

  const [customerPayoutList, setCustomerPayoutList] = useState<CustomerPayoutItem[]>();

  const status = searchParams.get("state");
  const sortColumn = searchParams.get("sortColumn");
  const sortOrder = searchParams.get("sortOrder");

  const [perPage, setPerPage] = React.useState(25);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { data, loading, error } = useQuery<GetCustomerPayoutsQueryResponse>(
    getCustomerPayoutsQuery,
    {
      variables: {
        where: omitEmpty({
          status: {
            _in: status,
          },
        }),
        orderBy: ({
          [sortColumn || "created_at"]: sortOrder?.toLowerCase() || "desc",
        } as unknown) as Fs_Customer_Payout_Order_By[],
        skip: perPage * (currentPage - 1),
        take: perPage,
      },
      context: {
        roleType: "finance",
      },
    }
  );

  const [resolveCustomerPayout] = useMutation(RESOLVE_CUSTOMER_PAYOUT, {
    context: { serviceName: ApolloContextName.FinanceService },
  });

  useEffect(() => {
    setCustomerPayoutList(
      data?.fs_customer_payout.map((item) => {
        return {
          amount: item.amount,
          caseId: item.case_id,
          createdAt: item.created_at,
          currency: item.currency,
          customerIban: item.customer_iban,
          customerName: item.customer_name,
          id: item.id,
          paymentReference: item.payment_reference,
          status: item.status,
          updatedAt: item.updated_at,
          aliasId: item.alias_id,
          vertical: item.vertical,
        };
      })
    );
  }, [data]);

  const onResolvePayment = async (id: string, resolvedStatus: PaymentStatus) => {
    try {
      await resolveCustomerPayout({
        variables: {
          id: id,
          resolveStatus: resolvedStatus,
        },
      });
      const updatedCustomerPayoutList: CustomerPayoutItem[] =
        customerPayoutList?.map((item: CustomerPayoutItem) => {
          return item.id === id ? { ...item, status: resolvedStatus } : item;
        }) || [];
      setCustomerPayoutList(updatedCustomerPayoutList);
      successToaster(humanizeString(resolvedStatus));
    } catch {
      graphQlErrorToaster("Payment status could not be changed");
    }
  };

  if (loading) return <Spinner />;
  if (error) return <ErrorMessage error={error.message} />;
  if (!data) return <ErrorMessage error={"No Payout data found"} />;

  const columns = [
    { label: "Alias Id", id: "alias_id" },
    { label: "Iban", id: "iban" },
    { label: "Customer", id: "customer" },
    { label: "Date", id: "created_at" },
    { label: "Amount", id: "amount" },
    { label: "Status", id: "status" },
    { label: "", id: "action" },
  ];

  const rows = customerPayoutList?.map((item: CustomerPayoutItem) => ({
    id: item.id,
    url: "",
    cells: [
      <Link to={`/cases/${item.caseId}`} key={item.caseId}>
        {item.paymentReference}
      </Link>,
      item.customerIban,
      item.customerName,
      moment.utc(item.createdAt).local().format("DD.MM.YYYY"),
      formatMoneyVerticals(item.amount),
      <Tag key={`${item.id}--status`} intent={Intent.SUCCESS}>
        {humanizeString(item.status)}
      </Tag>,
      <ActionButtons
        key={item.id}
        id={item.id}
        status={item.status}
        onResolvePayment={onResolvePayment}
      />,
    ],
  }));

  return (
    <div className="list-cont">
      <GenericListHeader headerLabel={<>Customer Payouts</>} />
      <List
        columns={columns}
        rows={rows || []}
        sort={{
          onSortByColumn: handleOnSortByColumn,
          sortableColumns: ["status", "amount", "createdAt"],
          sortColumn: sortColumn || "createdAt",
          sortOrder: sortOrder || Order_By.Desc,
        }}
      />
      <Paginator
        total={data.aggregate.aggregate?.count || 0}
        perPage={perPage}
        onPerPageChange={setPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

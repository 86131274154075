import React from "react";
import Stepper from "./stepper";
import { Step } from "./step";
import "./style.scss";
import { Props } from "./types";

export const Steps: React.FC<Props> = (props) => {
  const next = props.config.find((g) => g.id === props.activeStep);
  if (!next) {
    throw new Error(`No step found to continue with active step ${props.activeStep}`);
  }

  return (
    <>
      <Stepper {...props} />
      <Step config={next} />
    </>
  );
};

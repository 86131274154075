import CaseListPage from "pages/case-list";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  BankTransactionList,
  BankTransactionsImport,
  BankTransactionView,
  CustomerPayouts,
} from "services/finance-service/pages";
import LawsuitListView from "services/legal-service/pages/lawsuit-list";
import LawsuitView from "services/legal-service/pages/lawsuit-view";
import Layout from "../components/Layout";
import {
  URL_FINANCE,
  URL_FINANCE_CUSTOMER_PAYOUTS,
  URL_FINANCE_TRANSACTIONS,
  URL_FINANCE_TRANSACTIONS_IMPORT,
  URL_FINANCE_TRANSACTION_VIEW,
  URL_LEGAL,
  URL_LEGAL_CASES,
  URL_LEGAL_CASE_VIEW,
  URL_LEGAL_LAWSUITS,
  URL_LEGAL_LAWSUIT_VIEW,
} from "../constants";
import { AuthContext } from "../context/auth";
import CaseView from "../pages/case-view";
import Login from "../pages/login";
import LegalCaseProcessor from "../services/legal-service/pages/case-view";
import LegalCases from "../services/legal-service/pages/legal-cases";
interface locationStateType {
  from: string;
}

const RootRouter: React.FC = () => {
  const history = useHistory();
  const location = useLocation<locationStateType>();
  const authContext = useContext(AuthContext);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!authContext.isLoggedIn && location.pathname !== "/login") {
      history.push("/login", {
        from: `${location.pathname}${location.search}`,
      });
    }
    if (authContext.isLoggedIn && location.pathname === "/login") {
      const next = location.state?.from || "/";
      history.push(next);
    }
  }, [authContext, location.pathname, history, location.state, location.search]);

  return (
    <BrowserRouter forceRefresh={false}>
      <Switch location={location} key={location.pathname}>
        <Route path="/login" component={Login} />
        <ProtectedArea />
      </Switch>
    </BrowserRouter>
  );
};

const ProtectedArea: React.FC = () => {
  return (
    <Layout>
      <Route path={"/cases"}>
        <CpsRoutes />
      </Route>
      <Route path={URL_LEGAL}>
        <LegalRoutes />
      </Route>
      <Route path={URL_FINANCE}>
        <FinanceRoutes />
      </Route>
    </Layout>
  );
};

const CpsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={"/cases/"}>
        <CaseListPage />
      </Route>
      <Route exact path={"/cases/:id"}>
        <CaseView />
      </Route>
    </Switch>
  );
};

const LegalRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={URL_LEGAL_CASE_VIEW}>
        <LegalCaseProcessor />
      </Route>
      <Route path={URL_LEGAL_CASES}>
        <LegalCases />
      </Route>
      <Route path={URL_LEGAL_LAWSUIT_VIEW}>
        <LawsuitView />
      </Route>
      <Route path={URL_LEGAL_LAWSUITS}>
        <LawsuitListView />
      </Route>
    </Switch>
  );
};

const FinanceRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={URL_FINANCE_TRANSACTIONS}>
        <BankTransactionList />
      </Route>
      <Route exact path={URL_FINANCE_TRANSACTIONS_IMPORT}>
        <BankTransactionsImport />
      </Route>
      <Route path={URL_FINANCE_TRANSACTION_VIEW}>
        <BankTransactionView />
      </Route>
      <Route path={URL_FINANCE_CUSTOMER_PAYOUTS}>
        <CustomerPayouts />
      </Route>
    </Switch>
  );
};

export default RootRouter;

export const SET_ACTIVE_SIDEBAR_LINK = "UI/SET_ACTIVE_SIDEBAR_LINK";

export interface SetActiveSidebarLinkAction {
  type: typeof SET_ACTIVE_SIDEBAR_LINK;
  payload: {
    id: string | number;
  };
}

export type UiActionTypes = SetActiveSidebarLinkAction;

export function setActiveSidebarLink(id: string | number) {
  return {
    type: SET_ACTIVE_SIDEBAR_LINK,
    payload: { id },
  };
}

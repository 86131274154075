import { Card, Elevation, H5, HTMLTable, Intent, Tag } from "@blueprintjs/core";
import { dateFormatter } from "helpers/dateFormatter";
import { formatMoneyVerticals } from "helpers/moneyFormatter";
import React from "react";
import { Payment_State_Enum } from "types/graphql.types";
import "./style.scss";

type Payment = {
  state: Payment_State_Enum;
  amount: number;
  updated_at: string;
};

interface IProps {
  payments: Payment[];
}

const PaymentHistoryCard: React.FunctionComponent<IProps> = ({ payments }) => {
  return (
    <React.Fragment>
      <Card elevation={Elevation.ONE} className="payment-history base-card">
        <H5>Payment History</H5>
        <HTMLTable className="history-table">
          <thead>
            <tr>
              <th>Creation Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((p, index) => (
              <tr key={index}>
                <td>{dateFormatter(p.updated_at)}</td>
                <td>{formatMoneyVerticals(p.amount)}</td>
                <td className="state-value">
                  <Tag intent={Intent.PRIMARY}>{p.state}</Tag>
                </td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </Card>
    </React.Fragment>
  );
};

export default PaymentHistoryCard;

import { Card, Collapse, H5, Icon, Intent } from "@blueprintjs/core";
import { TextField } from "process-ui-builder";
import { useEffect, useState } from "react";
import { GffPerson } from "types/graphql.types";

import "./style.scss";

interface IProps {
  person?: GffPerson;
  count: number;
  isOpen: boolean;
  disableCollapse?: boolean;
  onDeletePerson?: (count: number) => void;
  readOnly: boolean;
}

const Person: React.FunctionComponent<IProps> = ({
  person,
  count,
  isOpen,
  disableCollapse,
  onDeletePerson,
  readOnly,
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(isOpen);

  const toggleCollapsible = () => {
    if (!disableCollapse) {
      setIsCollapsibleOpen(!isCollapsibleOpen);
    }
  };

  useEffect(() => {
    setIsCollapsibleOpen(isOpen);
  }, [isOpen]);

  return (
    <Card interactive={true} elevation={1} className="person">
      {person && onDeletePerson && !readOnly && (
        <Icon
          icon="cross"
          intent={Intent.DANGER}
          iconSize={25}
          className={"person--delete-icon"}
          onClick={() => onDeletePerson(count)}
        />
      )}

      <H5 onClick={toggleCollapsible}>
        <Icon icon={!isCollapsibleOpen ? "caret-right" : "caret-down"} iconSize={18} />
        {count === 0 ? "Main Person" : `Person ${count + 1}`}
      </H5>
      {person && (
        <Collapse isOpen={!isCollapsibleOpen}>
          <pre onClick={toggleCollapsible}>
            {`Name: ${person.name}`}
            <br />
            {person.ticket_number && `Ticket Number:${person.ticket_number}`}
          </pre>
        </Collapse>
      )}
      <Collapse isOpen={isCollapsibleOpen}>
        <TextField
          config={{
            label: "Name",
            id: `name_${count}`,
            type: "text",
            value: { path: `case.claim.persons[${count}].name` },
            validation: { required: true },
          }}
          readOnly={readOnly}
        />

        <TextField
          config={{
            label: "Ticket Number",
            id: `ticket_number_${count}`,
            type: "text",
            value: { path: `case.claim.persons[${count}].ticket_number` },
          }}
          readOnly={readOnly}
        />
      </Collapse>
    </Card>
  );
};
export default Person;

import { FormGroupSchema } from "process-ui-builder/config/types";

export const OcgClaim: FormGroupSchema = {
  id: "claim_data",
  type: "form",
  size: {
    colSpan: 4,
    rowSpan: 1,
  },
  title: "Claim Data",
  readOnly: true,
  fields: [
    {
      id: "vertical",
      label: "Vertical",
      type: "text",
      value: { path: "case.vertical" },
    },
    {
      id: "debtor",
      label: "Debtor",
      type: "text",
      value: { path: "case.debtorName" },
    },
    {
      id: "company_id",
      label: "Company",
      type: "text",
      emptyValue: true,
      value: { path: "case.companyId" },
    },
    {
      id: "money_spent",
      label: "Money spent",
      type: "money",
      value: { path: "case.claim.moneySpent" },
    },
    {
      id: "is_sports_betting",
      label: "Betting Type",
      type: "text",
      value: { path: "case.claim.bettingType" },
    },
  ],
};

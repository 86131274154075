import { Callout, Checkbox, FormGroup, Intent } from "@blueprintjs/core";
import React, { useContext, useState } from "react";
import { Props } from "./types";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { validator } from "process-ui-builder/validator";
import _ from "lodash";
import { ValueContext } from "process-ui-builder";
import { resolveValue } from "process-ui-builder/utils";

export const CheckboxField: React.FC<Props> = observer(({ config, readOnly }) => {
  const [validationError, setValidationError] = useState(false);

  const valueContext = useContext(ValueContext);
  const value = resolveValue(config.value, valueContext);
  const isReadOnly = resolveValue(config.readOnly, valueContext) || readOnly;

  const handleChange = action((event: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      if (!config.value?.path) {
        return;
      }
      _.set(valueContext, config.value.path, event.target.checked);
      setValidationError(validator(event.target.checked, config.validation));
    });
  });

  return (
    <>
      <FormGroup labelFor={config.id} intent={validationError ? Intent.DANGER : undefined}>
        <Checkbox
          label={config.label}
          id={config.id}
          checked={value}
          disabled={isReadOnly}
          onChange={handleChange}
        />
      </FormGroup>
      {validationError && (
        <Callout intent={Intent.DANGER} icon="error" className="error">
          Value is not valid
        </Callout>
      )}
    </>
  );
});

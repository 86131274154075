import { Product_Enum } from "types/graphql.types";

export enum LegalCaseStatus {
  INITIALIZED = "INITIALIZED",
  RIGHTNOW_LETTER_CREATED = "RIGHTNOW_LETTER_CREATED",
  RIGHTNOW_LETTER_SENT = "RIGHTNOW_LETTER_SENT",
  LAWYER_ASSIGNED = "LAWYER_ASSIGNED",
  LAWYER_LETTER_CREATED = "LAWYER_LETTER_CREATED",
  LAWYER_LETTER_SENT = "LAWYER_LETTER_SENT",
  REVOKED = "REVOKED",
  GDPR_DELETED = "GDPR_DELETED",
}

export const defaultLegalCaseStatus = Object.values(LegalCaseStatus).filter(
  (status) => status !== LegalCaseStatus.REVOKED
);

export enum LawsuitStatus_Enum {
  POOLING = "POOLING",
  ASSIGNED = "ASSIGNED",
  FILED = "FILED",
  ORAL_PROCEEDING = "ORAL_PROCEEDING",
  OUT_OF_COURT_SETTLEMENT = "OUT_OF_COURT_SETTLEMENT",
  IN_COURT_SETTLEMENT = "IN_COURT_SETTLEMENT",
  JUDGEMENT_WON = "JUDGEMENT_WON",
  JUDGEMENT_PARTIALLY_WON = "JUDGEMENT_PARTIALLY_WON",
  JUDGEMENT_LOST = "JUDGEMENT_LOST",
  TERMINATED = "TERMINATED",
  RIGHTNOW_APPEALED = "RIGHTNOW_APPEALED",
  OPPONENT_APPEALED = "OPPONENT_APPEALED",
  DELETED = "DELETED",
}

export const LAWSUIT_STATUSES = Object.values(LawsuitStatus_Enum);

export const VERTICALS = Object.values(Product_Enum);

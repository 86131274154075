import { Callout, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { ValueContext } from "process-ui-builder";
import { resolveValue } from "process-ui-builder/utils";
import React, { useState } from "react";
import { useContext } from "react";
import { Props } from "./types";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { validator } from "process-ui-builder/validator";

export const TextField: React.FC<Props> = observer(({ config, readOnly }) => {
  const [validationError, setValidationError] = useState(false);

  const valueContext = useContext(ValueContext);
  const value = resolveValue(config.value, valueContext);
  const isReadOnly = resolveValue(config.readOnly, valueContext) || readOnly;
  const handlers = valueContext.handlers;

  const handleChange = action((event: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      if (!config.value?.path) {
        return;
      }
      _.set(valueContext, config.value.path, event.target.value);
      const isInvalid = validator(event.target.value, config.validation);
      setValidationError(isInvalid);

      if (!isInvalid && config.autoFills && handlers[config.autoFills.handler]) {
        _.set(
          valueContext,
          config.autoFills.path,
          handlers[config.autoFills.handler](event.target.value)
        );
      }
    });
  });

  return (
    <>
      <FormGroup
        label={config.label}
        labelFor={config.id}
        labelInfo={config.validation?.required ? "(required)" : ""}
      >
        <InputGroup
          fill
          id={config.id}
          value={value}
          type={config.type}
          onChange={handleChange}
          readOnly={isReadOnly}
          intent={validationError ? Intent.DANGER : undefined}
        />
      </FormGroup>
      {validationError && (
        <Callout intent={Intent.DANGER} icon="error" className="error">
          Value is not valid
        </Callout>
      )}
    </>
  );
});

import { Classes, Drawer } from "@blueprintjs/core";
import React from "react";
import { useGetCaseCommentQuery } from "types/graphql.types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CommentEditor from "./CommentEditor";
import CommentListItem from "./CommentListItem";
import "./style.scss";

dayjs.extend(utc);

interface IProps {
  caseId: string;
  showCommentsDrawer: boolean;
  handleHideComments: (newCommentCount: number | null) => void;
}

const CaseCommentsList: React.FunctionComponent<IProps> = ({
  caseId,
  showCommentsDrawer,
  handleHideComments,
}) => {
  const { data, loading, error, refetch } = useGetCaseCommentQuery({
    variables: {
      caseId,
    },
  });

  const handleOnClose = () => {
    handleHideComments(data?.case_comment?.length || null);
  };

  return (
    <Drawer
      className={"comment-drawer"}
      autoFocus={true}
      hasBackdrop={true}
      size={"25vw"}
      canOutsideClickClose={true}
      canEscapeKeyClose={true}
      isCloseButtonShown={true}
      isOpen={showCommentsDrawer}
      onClose={handleOnClose}
      title={<h2>Comments</h2>}
    >
      <div className={Classes.DRAWER_BODY}>
        {!loading && !error && data ? (
          data.case_comment.map((comment) => (
            <CommentListItem key={comment.id} comment={comment} onRefetch={refetch} />
          ))
        ) : (
          <></>
        )}
      </div>
      <div className={`${Classes.DRAWER_FOOTER}`}>
        <CommentEditor caseId={caseId} onRefetch={refetch} comment={""} />
      </div>
    </Drawer>
  );
};
export default CaseCommentsList;

import { AuthActionTypes, REMOVE_AUTH_TOKEN, SET_AUTH_TOKEN } from "../actions/auth";

interface AuthToken {
  token?: string;
}

type AuthTokenReducerTypes = AuthToken;

const initialState: AuthTokenReducerTypes = {};

export default function reducer(
  state = initialState,
  action: AuthActionTypes
): AuthTokenReducerTypes {
  switch (action.type) {
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case REMOVE_AUTH_TOKEN: {
      return {
        ...state,
        token: undefined,
      };
    }
    default:
      break;
  }
  return state;
}

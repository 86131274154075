import {
  PERMISSION_ASSIGNMENT_RESEND,
  PERMISSION_FIT_CASE_REJECT_CONFIRM,
  PERMISSION_OFFER_APPROVE,
} from "config/acl";
import { Can } from "helpers/can";
import { GetCaseDataQuery, Product_Enum } from "types/graphql.types";
import { GffFlight, GffPerson, InsuredPerson } from "types/custom.types";
import {
  CREATE_OFFER_ALLOWED_STATES,
  DOCUMENTS_UPLOAD_CONFIRM_BOX_VERTICALS,
  FIT_MAX_OFFER_AMOUNT,
  GFF_OFFER_PERCENTAGE,
  NRG_DATE_OF_SUSPENSION,
  NRG_OFFER_PERCENTAGE,
  OTHER_OPTION,
  NFLX_OFFER_PERCENTAGE,
} from "./constants";
import {
  ACCEPTED,
  ASSIGNMENT_EXPIRED,
  ASSIGNMENT_RECEIVED,
  ASSIGNMENT_SENT,
  CREATED,
  DELETED,
  EXPIRED,
  NEW,
  PAYMENT_FAILED,
  PAYMENT_INITIATED,
  PAYMENT_PENDING,
  PAYMENT_SUCCESSFUL,
  REJECTED,
  SENT,
  WAITING_DOCUMENTS,
} from "../../constants";
import {
  Claim,
  FitClaim,
  GffClaim,
  NflxCalculation,
  NrgClaim,
  OcgClaim,
  PkvClaim,
  GdprClaim,
} from "./types";
import dayjs from "dayjs";
import { checkValidFlightData } from "components/GffClaimData/Flights/utils";
import { checkValidPersonData } from "components/GffClaimData/Persons/utils";
import { isValidDate, isValidString } from "helpers/utils";
import { PLACEHOLDER_FLIGHT, PLACEHOLDER_PERSON } from "components/GffClaimData/const";
import { getNumberValueFromLocale } from "helpers/moneyFormatter";

function assertCase(
  data: GetCaseDataQuery["baseCase"]
): asserts data is NonNullable<GetCaseDataQuery["baseCase"]> {
  if (!data) {
    throw new Error("No case in data");
  }
}

export function canRejectCase(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  const rejectableState = [NEW, ACCEPTED, WAITING_DOCUMENTS].includes(data.state);

  return rejectableState && !hasActiveOffer(data);
}

export function hasActiveOffer(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  if (!data.offers) {
    return false;
  }

  return data.offers.filter((o) => o.is_active).length > 0;
}

export function getActiveOffer(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  if (!data.offers) {
    return;
  }

  return data.offers.filter((o) => o.is_active)[0];
}

function isCaseDataUpdatable(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  const updatableState = [NEW, ACCEPTED, WAITING_DOCUMENTS].includes(data.state);

  return updatableState && !hasActiveOffer(data);
}

export function canUpdateCustomer(data: GetCaseDataQuery["baseCase"]) {
  return isCaseDataUpdatable(data);
}

export function canUpdateClaim(data: GetCaseDataQuery["baseCase"]) {
  return isCaseDataUpdatable(data);
}

export function canChangeOfferAddress(data: GetCaseDataQuery["baseCase"], isSupervisor: boolean) {
  if (!isSupervisor) {
    return false;
  }

  const offer = getActiveOffer(data);
  if (!offer || (offer && offer.address === null)) {
    return false;
  }

  return [ACCEPTED, ASSIGNMENT_SENT].includes(offer.state);
}

export function canSendOffer(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  const offer = getActiveOffer(data);
  if (!offer) {
    return false;
  }

  if (data.state === ACCEPTED && offer.state === CREATED) {
    return true;
  }

  return false;
}

export function canDeleteOffer(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  const offer = getActiveOffer(data);
  if (!offer) {
    return false;
  }

  if (data.state === ACCEPTED && (offer.state === CREATED || offer.state === SENT)) {
    return true;
  }

  return false;
}

export function canRefuseOffer(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  const offer = getActiveOffer(data);
  if (!offer) {
    return false;
  }

  if (data.state === ACCEPTED && offer.state === SENT) {
    return true;
  }

  return false;
}

export function canConfirmRejectCase(data: GetCaseDataQuery["baseCase"]) {
  if (!Can(PERMISSION_FIT_CASE_REJECT_CONFIRM)) {
    return false;
  }
  assertCase(data);

  const rejectableState = REJECTED === data.state;

  return rejectableState;
}

export function getCurrentStep(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);
  const activeOffer = getActiveOffer(data);
  const isOfferExist = data.offers.length > 0;

  if (activeOffer && [ASSIGNMENT_SENT, ASSIGNMENT_RECEIVED].includes(activeOffer.state)) {
    return "assignment";
  }

  if (
    activeOffer &&
    [PAYMENT_PENDING, PAYMENT_INITIATED, PAYMENT_FAILED, PAYMENT_SUCCESSFUL].includes(
      activeOffer.state
    )
  ) {
    return "payment";
  }
  if (isOfferExist) {
    return "offer";
  }

  return "data_validation";
}

export function getActiveAssignment(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);
  const activeOffer = getActiveOffer(data);

  if (!activeOffer) {
    return;
  }

  let assignment;

  for (let i = 0; i < activeOffer.assignments.length; i++) {
    const item = activeOffer.assignments[i];
    if (item.state !== DELETED) {
      assignment = { ...item };
      break;
    }
  }

  return assignment;
}

export function getFitClaimAmount(claim: FitClaim) {
  let lockdownMonthsLength = 0;
  if (claim.lockdown_months) {
    for (const key in claim.lockdown_months) {
      lockdownMonthsLength += claim.lockdown_months[key].length;
    }
    return Number(claim.monthly_amount) * lockdownMonthsLength;
  } else {
    return 0;
  }
}

export function getFitOfferAmount(claimAmount: number) {
  return claimAmount * 0.4 < FIT_MAX_OFFER_AMOUNT ? claimAmount * 0.4 : FIT_MAX_OFFER_AMOUNT;
}

export function getLastPayment(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);
  const activeOffer = getActiveOffer(data);
  return activeOffer?.payments[0];
}

export function getNextCaseStates(data: GetCaseDataQuery["baseCase"], isSuperVisor: boolean) {
  if (data) {
    assertCase(data);
    const nextCaseState = data.product_id === Product_Enum.Ocg ? [NEW, WAITING_DOCUMENTS] : [NEW];
    if (isSuperVisor) {
      nextCaseState.push(REJECTED);
    }
    return nextCaseState;
  } else {
    return [];
  }
}
export function canApproveOrCancelOffer(data: GetCaseDataQuery["baseCase"]) {
  if (!Can(PERMISSION_OFFER_APPROVE)) {
    return false;
  }

  assertCase(data);

  const offer = getActiveOffer(data);
  if (!offer) {
    return false;
  }

  const allowedState = [ACCEPTED, ASSIGNMENT_SENT, ASSIGNMENT_RECEIVED, ASSIGNMENT_EXPIRED];
  return !!offer.payment_on_hold && allowedState.includes(offer.state);
}

export function canResendAssignment(data: GetCaseDataQuery["baseCase"]) {
  if (!Can(PERMISSION_ASSIGNMENT_RESEND)) {
    return false;
  }

  assertCase(data);
  const offer = getActiveOffer(data);
  if (!offer) {
    return false;
  }
  const assignment = getActiveAssignment(data);

  return (
    offer.state === ASSIGNMENT_SENT &&
    offer.assignments.length < 3 &&
    dayjs(new Date()).diff(dayjs(new Date(assignment?.sent_at)), "day") >= 3
  );
}

export function isOcgClaim(claim: Claim): claim is OcgClaim {
  return !!(claim as OcgClaim).money_spent;
}

export function isGdprClaim(claim: Claim): claim is GdprClaim {
  const emailCompromised = (claim as GdprClaim).email_compromised;
  return typeof emailCompromised === "boolean";
}

export function isFitClaim(claim: Claim): claim is FitClaim {
  return !!(claim as FitClaim).lockdown_months;
}

export function isPkvClaim(claim: Claim): claim is PkvClaim {
  return !!(claim as PkvClaim).insured_persons;
}

export function isGffClaim(claim: Claim): claim is GffClaim {
  return !!(claim as GffClaim).flights;
}

export function isNrgClaim(claim: Claim): claim is NrgClaim {
  return !!(
    (claim as NrgClaim).meter_number ||
    (claim as NrgClaim).meter_number === null ||
    (claim as NrgClaim).meter_number === ""
  );
}

export function canCreateOffer(
  data: GetCaseDataQuery["baseCase"],
  claim: Claim,
  offerAmount?: number
) {
  if (!data) {
    throw new Error("No case in data");
  }

  const creatableState = CREATE_OFFER_ALLOWED_STATES.includes(data.state);
  //Validating claim only if case is fit as claim amount is dependant on the claim data.
  //Claim amount and offer amount will be auto-filed once claim is updated.
  //TODO: Add proper validation for claim for FIT, OCG, and Pkv
  const areClaimValuesValid = (() => {
    if (isFitClaim(claim)) {
      return claim.has_paid && !!claim.monthly_amount && !!offerAmount;
    }
    if (isGffClaim(claim)) {
      return isValidGffClaim(claim);
    }
    if (isNrgClaim(claim)) {
      return isValidNrgClaim(claim);
    }
    return true;
  })();

  return creatableState && areClaimValuesValid && !hasActiveOffer(data);
}

export function getClaimAmount(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);
  const activeOffer = getActiveOffer(data);
  return activeOffer?.claim_amount ?? 0;
}

export function getOfferAmount(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);
  const activeOffer = getActiveOffer(data);
  return activeOffer?.offer_amount ?? 0;
}

export function canResendOffer(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);

  const offer = getActiveOffer(data);
  if (!offer) {
    // if no active offer check for expired offer
    const expiredOffer = data.offers.filter((offer) => offer.state === EXPIRED);
    return expiredOffer.length > 0;
  }

  return [SENT].includes(offer.state);
}

export const sanitizeInsuredPersons = (insuredPersons: InsuredPerson[]) => {
  const sanitizeInsuredPersons: InsuredPerson[] = insuredPersons.map((insuredPerson) => {
    const insuredPersonUpdated = {
      ...insuredPerson,
      tariffs: insuredPerson.tariffs.filter((tariff) => tariff.length),
      insured_since: insuredPerson.insured_since
        ? dayjs(insuredPerson.insured_since).format("YYYY-MM-DD")
        : "",
    };

    return insuredPersonUpdated;
  });
  return sanitizeInsuredPersons;
};

export const validatePkvClaim = (claim: PkvClaim) => {
  if (!claim.company_id || (claim.company_id === OTHER_OPTION && !claim.other_company)) {
    alert("Invalid Claim:  Company data in claim is invalid");
    return false;
  }

  if (typeof claim.is_estimated_claim_amount !== "boolean") {
    alert("Invalid Claim: Please select if actual claim amount or estimated claim amount");
    return false;
  }
  if (claim.is_estimated_claim_amount === true && !claim.source_of_estimation) {
    alert("Invalid Claim: Please select source of estimation of the claim amount");
    return false;
  }

  if (!claim.insurance_increase_date) {
    alert("Invalid Claim: Please select valid insurance increase date");
    return false;
  }
  if (!claim.case_type) {
    alert("Invalid Claim: Please select valid case type");
    return false;
  }

  if (
    !claim.insured_persons ||
    !claim.insured_persons.length ||
    !claim.insured_persons.every(
      (person: InsuredPerson) =>
        person.name &&
        person.insured_since &&
        person.insurance_number &&
        person.tariffs &&
        person.tariffs.length
    )
  ) {
    alert("Invalid Claim: Insured Person data is not valid");
    return false;
  }

  return true;
};

export function isShowAllDocumentsUploadedConfirmationBox(
  data: GetCaseDataQuery["baseCase"]
): boolean {
  assertCase(data);
  if (
    DOCUMENTS_UPLOAD_CONFIRM_BOX_VERTICALS.includes(data.product_id) &&
    data.state === WAITING_DOCUMENTS &&
    !!data.documents_aggregate.aggregate?.count
  ) {
    return true;
  } else {
    return false;
  }
}

export const sanitizeGffFlights = (flights: GffFlight[]): GffFlight[] | false => {
  const sanitizeFlights: (GffFlight | null)[] = flights.map((flight) => {
    if (!checkValidFlightData(flight)) {
      return null;
    }
    const updatedFlight = {
      ...flight,
      flight_date: dayjs(flight.flight_date).format("YYYY-MM-DD"),
    };
    return updatedFlight;
  });

  if (sanitizeFlights.some((f) => f === null)) {
    return false;
  }

  return sanitizeFlights.filter((flight) => flight !== null) as GffFlight[];
};

export const sanitizeGffPerson = (persons: GffPerson[]): GffPerson[] | false => {
  const sanitizeGffPerson: (GffPerson | null)[] = persons.map((person) => {
    if (!checkValidPersonData(person)) {
      return null;
    }
    const updatedPerson: GffPerson = {
      name: person.name,
      ...(isValidString(person.ticket_number) && { ticket_number: person.ticket_number }),
    };

    return updatedPerson;
  });

  if (sanitizeGffPerson.some((p) => p === null)) {
    return false;
  }

  return sanitizeGffPerson.filter((person) => person !== null) as GffPerson[];
};

export const checkGffClaimMandatoryFields = (
  claim: GffClaim,
  showAlert: boolean = true
): boolean => {
  if (!claim.flight_type) {
    showAlert && alert("Invalid Claim: Please select valid flight type");
    return false;
  }

  if (!claim.company_id) {
    showAlert && alert("Invalid Claim: Please select valid company");
    return false;
  }

  if (!claim.ticket_amount || isNaN(claim.ticket_amount) || claim.ticket_amount <= 0) {
    showAlert && alert("Invalid Claim: Please select valid ticket amount");
    return false;
  }

  return true;
};

export const isPlaceholderFlight = (flight: GffFlight): boolean => {
  return (
    flight.flight_date === PLACEHOLDER_FLIGHT.flight_date &&
    flight.flight_number === PLACEHOLDER_FLIGHT.flight_number &&
    flight.departure_airport === PLACEHOLDER_FLIGHT.departure_airport &&
    flight.arrival_airport === PLACEHOLDER_FLIGHT.arrival_airport
  );
};

export const isPlaceholderPerson = (person: GffPerson): boolean => {
  return (
    person.name === PLACEHOLDER_PERSON.name &&
    (person.ticket_number === PLACEHOLDER_PERSON.ticket_number ||
      person.ticket_number === undefined)
  );
};

export const getNrgDateOfSuspension = (value: any): string | undefined => {
  return NRG_DATE_OF_SUSPENSION[value];
};

export const getNrgOfferAmount = (claimAmount: any, locale: string = "de-DE") => {
  const claimAmountInNumbers = getNumberValueFromLocale(claimAmount, locale);

  return isNaN(claimAmountInNumbers)
    ? 0
    : Math.round(claimAmountInNumbers * (NRG_OFFER_PERCENTAGE / 100));
};

export const getGffOfferAmount = (claimAmount: any, locale: string = "de-DE") => {
  const claimAmountInNumbers = getNumberValueFromLocale(claimAmount, locale);

  return isNaN(claimAmountInNumbers)
    ? 0
    : Math.round(claimAmountInNumbers * (GFF_OFFER_PERCENTAGE / 100));
};

export const isValidNrgClaim = (claim: NrgClaim) => {
  return (
    claim.company_id &&
    claim.date_of_suspension &&
    isValidDate(claim.date_of_suspension) &&
    claim.customer_type &&
    claim.contract_type_after_suspension &&
    isValidString(claim.contract_number) &&
    claim.contract_end_date &&
    isValidDate(claim.contract_end_date) &&
    isValidString(claim.meter_number)
  );
};

export const isValidGffClaim = (claim: GffClaim): boolean => {
  const hasAllMandatoryFields = checkGffClaimMandatoryFields(claim, false);
  const isEstimateClaimAmountBool = typeof claim.is_estimated_claim_amount === "boolean";
  const hasBookingReference = Boolean(claim.booking_reference);
  const areFlightsDataValid =
    claim.flights?.every((flight) => checkValidFlightData(flight)) || false;
  const arePersonsDataValid =
    claim.persons?.every((person) => checkValidPersonData(person)) || false;
  return (
    hasAllMandatoryFields &&
    isEstimateClaimAmountBool &&
    hasBookingReference &&
    areFlightsDataValid &&
    arePersonsDataValid
  );
};

export const getNflxOfferAmountAutofill = (claimAmount: any, locale: string = "de-DE") => {
  const claimAmountInNumbers = claimAmount && getNumberValueFromLocale(claimAmount, locale);
  return isNaN(claimAmountInNumbers)
    ? 0
    : (claimAmountInNumbers * (NFLX_OFFER_PERCENTAGE / 100)).toFixed(2);
};

export function getNflxClaimAmount(data: GetCaseDataQuery["baseCase"]): number {
  assertCase(data);
  const activeOffer = getActiveOffer(data);
  if (activeOffer) {
    return activeOffer.claim_amount;
  }
  if (data.nflx_claim) {
    if (data.nflx_claim.calculation) {
      return (data.nflx_claim.calculation as NflxCalculation).claimAmount;
    }
    return 0;
  }

  return 0;
}

export function getNflxOfferAmount(data: GetCaseDataQuery["baseCase"]) {
  assertCase(data);
  const activeOffer = getActiveOffer(data);
  if (activeOffer) {
    return activeOffer.offer_amount;
  }
  if (data.nflx_claim) {
    if (data.nflx_claim.calculation) {
      const claimAmountInNumbers = (data.nflx_claim.calculation as NflxCalculation).claimAmount;
      return claimAmountInNumbers * (NFLX_OFFER_PERCENTAGE / 100);
    }
    return 0;
  }

  return 0;
}

import React from "react";
import { MenuItem } from "@blueprintjs/core";

import { TCommonPopup } from "document-manager/types";

type TProps = {
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
  documentId: string;
};

export const EditOption: React.FC<TProps> = ({ setDocumentIdAndOpenPopUpBox, documentId }) => {
  const handleEdit = () => {
    setDocumentIdAndOpenPopUpBox(documentId, "edit");
  };

  return <MenuItem icon="edit" text="Edit" onClick={handleEdit} />;
};

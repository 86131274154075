import { Button, Intent } from "@blueprintjs/core";
import get from "lodash.get";
import set from "lodash/set";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ValueContext } from "process-ui-builder";
import React, { useContext, useMemo, useState } from "react";
import { GffPerson, Product_Enum } from "types/graphql.types";
import { READ_ONLY_VERTICALS } from "../../../constants";
import { MAX_PERSONS_COUNT, PLACEHOLDER_PERSON } from "../const";
import Person from "./Person";
import { checkValidPersonData } from "./utils";

const Persons: React.FunctionComponent = () => {
  const valueContext = useContext(ValueContext);

  const persons: GffPerson[] = get(valueContext, "case.claim.persons");
  const readOnly = get(valueContext, "flow.read_only_group.claim_data");
  const isProductReadOnly = READ_ONLY_VERTICALS.includes(Product_Enum.Gff);

  const [personCount, setPersonCount] = useState<number>(persons.length);

  const addAnotherPerson = action(() => {
    runInAction(() => {
      const lastPerson: { [key: string]: any } | undefined = persons[persons.length - 1];
      if (persons.length === personCount && checkValidPersonData(lastPerson)) {
        const updatedPersons = [...persons];
        updatedPersons.push(PLACEHOLDER_PERSON);
        setPersonCount(persons.length + 1);
        set(valueContext, "case.claim.persons", updatedPersons);
      } else {
        alert(`Name is required, Check Person ${personCount ? personCount : 1}`);
      }
    });
  });

  const getPerson = useMemo(() => {
    const deletePerson = action((count: number) => {
      runInAction(() => {
        if (persons.length <= 1) {
          alert("Minimum one person is required for claim.");
          return;
        }
        const persons1 = [...persons];
        persons1.splice(count, 1);
        set(valueContext, "case.claim.persons", persons1);
        setPersonCount(personCount - 1);
      });
    });

    const personArray = persons.map((person, index) => {
      return (
        <Person
          person={person}
          count={index}
          readOnly={!!readOnly || isProductReadOnly}
          isOpen={index + 1 === personCount}
          onDeletePerson={deletePerson}
          key={index}
        />
      );
    });

    if (persons.length === 0 || persons.length < personCount) {
      const updatedPersons = [...persons];
      updatedPersons.push(PLACEHOLDER_PERSON);
      setPersonCount(persons.length + 1);
      set(valueContext, "case.claim.persons", updatedPersons);
    }

    return personArray;
  }, [personCount, readOnly, persons, valueContext, isProductReadOnly]);

  return (
    <>
      {getPerson}
      {!readOnly && !isProductReadOnly && persons.length <= MAX_PERSONS_COUNT - 1 && (
        <Button
          icon={"add"}
          text={"Add another Person"}
          intent={Intent.WARNING}
          onClick={addAnotherPerson}
        />
      )}
    </>
  );
};
export default observer(Persons);

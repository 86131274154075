import { IConfig, GroupSchema } from "process-ui-builder/config/types";

const groups: GroupSchema[] = [
  {
    id: "customer_data",
    type: "form",
    title: "Customer Data",
    size: {
      colSpan: 4,
    },
    readOnly: true,
    fields: [
      {
        id: "name",
        label: "Name",
        type: "text",
        value: { path: "case.customerData.name" },
      },
    ],
  },
  {
    id: "claim_data",
    type: "customComponent",
    component: "ClaimData",
  },
  {
    id: "financial_data",
    type: "form",
    title: "Financials",
    size: {
      colSpan: 4,
    },
    readOnly: true,
    fields: [
      {
        id: "claim_amount",
        label: "Claim Amount",
        type: "money",
        value: { path: "case.financialData.claimAmount" },
      },
      {
        id: "lawyer_fee",
        label: "Lawyer Fee",
        type: "money",
        value: { path: "case.financialData.lawyerFee" },
      },
      {
        id: "interest_fees",
        label: "Interest Fees",
        type: "money",
        value: { path: "case.financialData.interestFees" },
      },
      {
        id: "open_claim_amount",
        label: "Open Claim Amount",
        type: "money",
        value: { path: "case.financialData.openClaimAmount" },
      },
    ],
  },
  {
    id: "documents",
    type: "customComponent",
    component: "DocumentsList",
  },
  {
    id: "lawsuitData",
    type: "customComponent",
    component: "LawsuitData",
  },
];

export const LegalJson: IConfig = {
  id: "legal_case",
  version: "0.0.1",
  checkedSteps: {
    path: "flow.checked_steps",
  },
  currentStep: {
    path: "flow.current_step",
  },
  steps: [
    {
      id: "right_now_letter",
      label: "RightNow Letter",
      readOnly: {
        path: "flow.read_only_steps.right_now_letter",
      },
      disabled: true,
      groups,
    },
    {
      id: "lawyer_letter",
      label: "Lawyer Letter",
      readOnly: {
        path: "flow.read_only_steps.lawyer_letter",
      },
      disabled: true,
      groups,
    },
    {
      id: "pooling",
      label: "Pooling",
      readOnly: {
        path: "flow.read_only_steps.pooling",
      },
      disabled: true,
      groups,
    },
    {
      id: "in_lawyer_area",
      label: "In Lawyer Area",
      readOnly: {
        path: "flow.read_only_steps.in_lawyer_area",
      },
      disabled: true,
      groups,
    },
    {
      id: "closed",
      label: "Closed",
      readOnly: {
        path: "flow.read_only_steps.closed",
      },
      disabled: true,
      groups,
    },
  ],
};

import React, { useContext, useMemo, useState } from "react";
import {
  Dialog,
  InputGroup,
  FormGroup,
  HTMLSelect,
  Button,
  Intent,
  Callout,
} from "@blueprintjs/core";

import { TDocument } from "document-manager/types";
import { DocumentManagerContext } from "document-manager/DocumentManager";
import { getOptionsWithEmptyOption } from "document-manager/utils";
import { getFileExtension } from "document-manager/utils";
import { FILE_EXTENSION_REGEX } from "document-manager/constants";

import "./styles.scss";

export type TProps = {
  document: TDocument;
  onHide: () => void;
  isOpen: boolean;
};

export const EditModal: React.FC<TProps> = ({ onHide, isOpen, document }) => {
  const [displayName, setDisplayName] = useState<string>(
    document.displayFileName.replace(FILE_EXTENSION_REGEX, "")
  );
  const [category, setCategory] = useState<string>(document.documentType);

  const { categories, updateDocumentHandler } = useContext(DocumentManagerContext);

  const [fileNameError, setFileNameError] = useState<string>("");
  const [categoryError, setCategoryError] = useState<string>("");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(e.target.value);
    setFileNameError("");
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
    setCategoryError("");
  };

  const ValidateInput = () => {
    if (displayName.trim().length < 2) {
      setFileNameError("The file name is too short");
      return false;
    }
    if (!category) {
      setCategoryError("Category is required");
      return false;
    }
    return true;
  };

  const handleSaveChanges = () => {
    const isValid = ValidateInput();
    if (isValid) {
      updateDocumentHandler &&
        updateDocumentHandler({
          id: document.id,
          displayFileName: `${displayName.trim()}.${getFileExtension(document.displayFileName)}`,
          documentType: category,
          addToLawsuit: document.addToLawsuit,
        }).then(() => {
          onHide();
        });
    }
  };

  const options = useMemo(() => {
    const categoriesOptions = categories?.map((category) => {
      return { label: category.label, value: category.id };
    });
    return getOptionsWithEmptyOption(categoriesOptions, "Select a document category");
  }, [categories]);

  return (
    <Dialog title={"Edit"} icon="edit" onClose={onHide} isOpen={isOpen} className="edit-modal">
      <div className="edit-modal--body">
        <FormGroup
          label="Document name"
          labelFor={`name-${document.id}`}
          labelInfo="(required)"
          helperText="File name cannot be empty"
        >
          <InputGroup
            fill
            id={`name-${document.id}`}
            value={displayName}
            type="text"
            onChange={handleNameChange}
          />
          {fileNameError && (
            <Callout intent={Intent.DANGER} icon="error" className="error">
              {fileNameError}
            </Callout>
          )}
        </FormGroup>

        <FormGroup
          label="Document Category"
          labelFor={`category-${document.id}`}
          labelInfo="(required)"
        >
          <HTMLSelect
            fill
            options={options}
            id={`category-${document.id}`}
            value={category}
            onChange={handleCategoryChange}
          />
          {categoryError && (
            <Callout intent={Intent.DANGER} icon="error" className="error">
              {categoryError}
            </Callout>
          )}
        </FormGroup>

        <Button text="Save Changes" onClick={handleSaveChanges} intent={Intent.PRIMARY} />
      </div>
    </Dialog>
  );
};

import { Intent } from "@blueprintjs/core";
import InfoCard, { IProps as IInfoCardProps } from "components/InfoCard";
import React from "react";
import { Product_Enum, useDuplicateCustomerAddressCountQuery } from "types/graphql.types";

interface IProps {
  hash: string;
  productId: Product_Enum;
}

const DuplicateCustomerAddressSubmissionCard: React.FC<IProps> = ({ hash, productId }) => {
  // TODO: find a better way to select active offers
  const { data } = useDuplicateCustomerAddressCountQuery({
    variables: {
      hash,
      productId,
    },
  });

  const currentCount = data?.case?.aggregate?.count;

  const infoCardProps: IInfoCardProps = {
    message: <>There are multiple submissions with this customer address.</>,
    icon: "delete",
    intent: Intent.DANGER,
    buttons: [
      {
        text: "Show Cases",
        link: `/cases/?vertical=${productId}&address_hash=${hash}`,
      },
    ],
  };

  return <>{currentCount && currentCount > 1 ? <InfoCard {...infoCardProps} /> : null}</>;
};

export default DuplicateCustomerAddressSubmissionCard;

import { Intent, Spinner, Tag } from "@blueprintjs/core";
import { ErrorMessage } from "components/shared/ErrorMessage";
import List from "components/shared/List";
import { GenericListHeader } from "components/shared/ListHeader";
import { Paginator } from "components/shared/Paginator";
import { humanizeString } from "helpers/humanize-string";
import { useHandleOnSortByColumn } from "hooks/use-handle-on-sort-by-column";
import { useNavigateTo } from "hooks/use-navigate-to";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import Filter from "services/legal-service/components/filter";
import {
  Ls_Case_Order_By,
  Order_By,
  useGetDebtorsQuery,
  useGetLegalCasesQuery,
} from "types/graphql.types";
import { defaultLegalCaseStatus, LegalCaseStatus } from "../constants";
import { IDebtor } from "./types";
import { filterStatusOptions, getStatusFromUrl } from "./utils";
import omitEmpty from "omit-empty-es";

const LegalCases = () => {
  const location = useLocation();
  const navigateTo = useNavigateTo();
  const searchParams = new URLSearchParams(location.search);
  const handleOnSortByColumn = useHandleOnSortByColumn();
  const statusParam = searchParams.get("status");

  const [perPage, setPerPage] = React.useState(25);
  const [currentPage, setCurrentPage] = React.useState(1);
  const debtorId = searchParams.get("debtor_id");
  const vertical = searchParams.get("vertical");
  const aliasId = searchParams.get("alias_id");
  const status = statusParam ? getStatusFromUrl(statusParam) : defaultLegalCaseStatus;
  const sortColumn = searchParams.get("sortColumn");
  const sortOrder = searchParams.get("sortOrder");

  const listLegalCasesQueryResult = useGetLegalCasesQuery({
    variables: {
      where: omitEmpty({
        debtor_id: {
          _eq: debtorId,
        },
        vertical: {
          _eq: vertical,
        },
        alias_id: {
          _ilike: aliasId,
        },
        status: {
          _in: status,
        },
      }),
      orderBy: ({
        [sortColumn || "legal_process_started_at"]: sortOrder?.toLowerCase() || "desc",
      } as unknown) as Ls_Case_Order_By[],
      skip: perPage * (currentPage - 1),
      take: perPage,
    },
    context: {
      roleType: "legal",
    },
  });

  const getDebtorsQueryResult = useGetDebtorsQuery({
    context: {
      roleType: "legal",
    },
  });

  if (listLegalCasesQueryResult.error) {
    return <ErrorMessage error={listLegalCasesQueryResult.error.message} />;
  }

  if (getDebtorsQueryResult.error) {
    return <ErrorMessage error={getDebtorsQueryResult.error?.message} />;
  }

  const legalCases = listLegalCasesQueryResult.data?.ls_case;
  const debtors = getDebtorsQueryResult.data?.ps_debtor;
  const sortedDebtors = sortObjectListByNameField(debtors) as Array<IDebtor>;

  const columns = [
    { label: "Alias ID", id: "alias_id" },
    { label: "Vertical", id: "vertical" },
    { label: "Debtor", id: "debtor_name" },
    { label: "Status", id: "status" },
    { label: "Legal Process Started At", id: "legal_process_started_at" },
  ];

  const rows = legalCases?.map((caseItem) => ({
    id: caseItem.id,
    url: `/legal/cases/${caseItem.id}`,
    cells: [
      `#${caseItem.alias_id}`,
      caseItem.vertical,
      <Tag key={`${caseItem.id}--debtor`} intent={Intent.PRIMARY}>
        {caseItem.debtor_name}
      </Tag>,
      <Tag key={`${caseItem.id}--status`} intent={Intent.SUCCESS}>
        {humanizeString(caseItem.status)}
      </Tag>,
      moment.utc(caseItem.legal_process_started_at).local().fromNow(),
    ],
  }));

  const filterOptions = [
    {
      name: "debtor_id",
      type: "dropdown",
      defaultValue: sortedDebtors ? sortedDebtors[0].id : "",
      options: sortedDebtors?.map((debtor) => ({ label: debtor.name, value: debtor.id })),
    },
    {
      name: "vertical",
      type: "dropdown",
      defaultValue: "GDPR",
      options: [
        {
          label: "GDPR",
          value: "GDPR",
        },
        {
          label: "FIT",
          value: "FIT",
        },
        {
          label: "GFF",
          value: "GFF",
        },
        {
          label: "NRG",
          value: "NRG",
        },
        {
          label: "OCG",
          value: "OCG",
        },
        {
          label: "PKV",
          value: "PKV",
        },
      ],
    },
    {
      name: "alias_id",
      type: "input",
      placeholder: "Alias ID",
    },
    {
      name: "status",
      type: "dropdown",
      defaultValue: LegalCaseStatus.INITIALIZED,
      options: filterStatusOptions,
    },
  ];

  const filterFields = ["debtor_id", "vertical", "alias_id", "status"];

  const handleOnFilter = (filters: any) => {
    const navigateToParams = filterFields.map((filter: string) => ({
      name: filter,
      value: filters[filter],
    }));

    navigateTo(navigateToParams);
  };

  return (
    <div className="list-cont">
      <GenericListHeader
        headerLabel={<>Legal Cases</>}
        filter={<Filter options={filterOptions} onFilter={handleOnFilter} />}
      />
      {(listLegalCasesQueryResult.loading || getDebtorsQueryResult.loading) && <Spinner />}
      {legalCases && (
        <List
          columns={columns}
          rows={rows || []}
          sort={{
            onSortByColumn: handleOnSortByColumn,
            sortableColumns: ["alias_id", "vertical", "status", "legal_process_started_at"],
            sortColumn: sortColumn || "legal_process_started_at",
            sortOrder: sortOrder || Order_By.Desc,
          }}
        />
      )}
      <Paginator
        total={listLegalCasesQueryResult.data?.aggregate.aggregate?.count || 0}
        perPage={perPage}
        onPerPageChange={setPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

function sortObjectListByNameField(list: Array<{ name: string }> | undefined) {
  if (!list) return list;
  return list?.sort((firstItem: { name: string }, secondItem: { name: string }) =>
    firstItem.name.toLowerCase() > secondItem.name.toLowerCase() ? 1 : -1
  );
}

export default LegalCases;

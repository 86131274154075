import { AnchorButton } from "@blueprintjs/core";
import DocumentPreview from "../DocumentPreview";
import React from "react";

const ViewAndDownloadLink: React.FunctionComponent<{
  signedDocument: { previewUrl: string; downloadUrl: string };
}> = ({ signedDocument }) => {
  return (
    <>
      {signedDocument && (
        <>
          <DocumentPreview url={signedDocument.previewUrl} />
          <AnchorButton href={signedDocument.downloadUrl} icon="download" minimal target="_blank" />
        </>
      )}
    </>
  );
};

export default ViewAndDownloadLink;

import { isValidString, isValidDate } from "helpers/utils";
import { GffFlight } from "types/custom.types";

export const checkValidFlightData = (flight: GffFlight): boolean => {
  return !!(
    flight &&
    isValidString(flight.flight_number) &&
    isValidDate(flight.flight_date) &&
    isValidAirportCode(flight.departure_airport) &&
    isValidAirportCode(flight.arrival_airport) &&
    flight.arrival_airport !== flight.departure_airport
  );
};

export const isValidAirportCode = (airportCode: string): boolean => {
  return isValidString(airportCode) && airportCode.length === 3;
};

import React from "react";
import { Icon } from "@blueprintjs/core";

import "./styles.scss";

export const Head: React.FC = () => {
  return (
    <thead className={"table-heading"}>
      <tr>
        <th>
          <Icon icon="numbered-list" iconSize={Icon.SIZE_STANDARD} />
        </th>
        <th>File Format</th>
        <th>Name</th>
        <th>Upload Date/Time</th>
        <th>Category</th>
        <th>Add to Lawsuit</th>
        <th>
        </th>
      </tr>
    </thead>
  );
};

import { Checkbox, FormGroup, Label } from "@blueprintjs/core";
import _ from "lodash";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ValueContext } from "process-ui-builder";
import { isOptionHandler } from "process-ui-builder/config";
import { StaticOption } from "process-ui-builder/config/types";
import { resolveValue } from "process-ui-builder/utils";
import React, { useContext } from "react";
import { Props } from "./types";

export const CheckboxGroupField: React.FC<Props> = observer(({ config, readOnly }) => {
  const valueContext = useContext(ValueContext);
  const value = config.value ? resolveValue<unknown[]>(config.value, valueContext) : [];

  const isReadOnly = resolveValue(config.readOnly, valueContext) || readOnly;
  const optionsArray: StaticOption[] = [];
  if (config.options) {
    if (!isOptionHandler(config.options)) {
      optionsArray.push(...config.options);
    }
  }

  const handleChange = action((event: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      if (!config.value?.path) {
        return;
      }
      let newValue;
      const valueArray = event.target.getAttribute("data-name");
      if (event.target.checked) {
        newValue = _.concat(value, valueArray);
      } else {
        newValue = _.difference(value, [valueArray] || []);
      }
      var filtered = newValue.filter(function (el) {
        return el != null;
      });
      _.set(valueContext, config.value.path, filtered);
    });
  });

  const ifAllChecked = () => {
    const newValue = optionsArray.map((option) => value?.includes(option.value));
    return newValue.filter(Boolean).length === optionsArray.length;
  };

  const handleChangeSelectAll = action((event: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      if (!config.value?.path) {
        return;
      }
      let newValue: any[] = [];
      if (event.target.checked) {
        newValue = optionsArray.map((option) => option.value);
      }
      _.set(valueContext, config.value.path, newValue);
    });
  });

  return (
    <>
      <FormGroup labelFor={config.id}>
        {config.isSelectAllAllowed === true ? (
          <Checkbox
            labelElement={<b>{config.label}</b>}
            checked={ifAllChecked()}
            disabled={isReadOnly}
            onChange={handleChangeSelectAll}
          />
        ) : (
          <Label>{config.label}</Label>
        )}
        {optionsArray.map((option, index) => (
          <Checkbox
            key={index}
            label={option.label}
            checked={value?.includes(option.value)}
            disabled={isReadOnly}
            data-name={option.value}
            onChange={handleChange}
          />
        ))}
      </FormGroup>
    </>
  );
});

import { Callout, Intent } from "@blueprintjs/core";
import React from "react";

export const ErrorMessage: React.FC<{ error?: string; className?: string }> = ({
  error,
  className,
}) => {
  return error ? (
    <Callout intent={Intent.DANGER} icon="error" className={className}>
      {error}
    </Callout>
  ) : null;
};

import { IconName, ITreeNode } from "@blueprintjs/core";
import { READ_ONLY_VERTICALS } from "../../../constants";
import { IS_EXPANDED } from ".";
import { NodeData } from "./node-data";
import { cpsMenuConfig } from "./node-data";
import { Product_Enum } from "types/graphql.types";

export const CLAIM_PURCHASING_MENU_ID = "claimPurchasingMenu";

const activeVerticalNodeData: ITreeNode<NodeData>[] = cpsMenuConfig
  .filter((v) => !READ_ONLY_VERTICALS.includes(v.id))
  .sort((a, b) => a.id.localeCompare(b.id));

const readOnlyVerticalNodeData: ITreeNode<NodeData>[] = cpsMenuConfig
  .filter((v) => READ_ONLY_VERTICALS.includes(v.id))
  .sort((a, b) => a.id.localeCompare(b.id));

const buildNodes = (verticals: ITreeNode<NodeData>[], isReadOnly: boolean) => {
  return verticals.map((vertical) => {
    return {
      id: vertical.id,
      icon: vertical.icon as IconName,
      label: vertical.label,
      nodeData: {
        link: `/cases/?vertical=${vertical.id}${isReadOnly ? "" : "&view=pending"}`,
      },
    };
  });
};

export const getClaimPurchasingNode = (vertical?: Product_Enum): ITreeNode<NodeData> => {
  let isExpanded;
  const savedIsExpanded = localStorage.getItem(`${CLAIM_PURCHASING_MENU_ID}${IS_EXPANDED}`);
  if (savedIsExpanded) {
    isExpanded = savedIsExpanded === "true";
  } else {
    isExpanded = true;
  }

  return {
    id: CLAIM_PURCHASING_MENU_ID,
    isExpanded,
    label: <strong>Claim Purchasing</strong>,
    childNodes: [
      {
        id: "active",
        label: <strong>Active Verticals</strong>,
        hasCaret: false,
        isExpanded: true,
        childNodes: buildNodes(activeVerticalNodeData, false),
      },
      {
        id: "readOnly",
        hasCaret: true,
        isExpanded: vertical && READ_ONLY_VERTICALS.includes(vertical),
        label: <strong>Read-Only Verticals</strong>,
        childNodes: buildNodes(readOnlyVerticalNodeData, true),
      },
    ],
  };
};

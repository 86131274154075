import get from "lodash/get";
import { isValuePointer, ValuePointer } from "process-ui-builder/config";

export function resolveValue<T>(value: ValuePointer | T, valueContext: any): T {
  if (isValuePointer(value)) {
    return get(valueContext, value.path) as T;
  }

  return value;
}

import queryString from "query-string";
import { useHistory } from "react-router-dom";

export function useNavigateTo() {
  const history = useHistory();

  return (queryStrings: Array<{ name: string; value: string }> = []) => {
    const parsedQueryString = queryString.parse(history.location.search);
    queryStrings.forEach((qs) => {
      if (qs.name) {
        parsedQueryString[qs.name] = qs.value;
      }
    });

    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(parsedQueryString),
    });
  };
}

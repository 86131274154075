import React, { useMemo } from "react";

import { dateFormatter } from "helpers/dateFormatter";
import { humanizeString } from "helpers/humanize-string";

import { TCommonPopup, TDocument } from "document-manager/types";
import { getFileExtension } from "document-manager/utils";

import { Actions, AddToLawsuit } from "./actions";
import { SortArrows } from "./SortArrows";
import { Preview } from "./Preview";
import { GetIcon } from "./GetIcon";

import "./styles.scss";

type TProps = {
  document: TDocument;
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
  reorderDocuments: (oldIndex: number, newIndex: number) => void;
  currentIndex: number;
  noOfDocuments: number;
};

export const Row: React.FC<TProps> = ({
  document,
  setDocumentIdAndOpenPopUpBox,
  reorderDocuments,
  currentIndex,
  noOfDocuments,
}) => {
  const fileExtension = useMemo(() => {
    return getFileExtension(document.displayFileName);
  }, [document.displayFileName]);

  return (
    <tr>
      <td className="sort-arrows">
        {noOfDocuments > 1 && (
          <SortArrows
            reorderDocuments={reorderDocuments}
            currentIndex={currentIndex}
            noOfDocuments={noOfDocuments}
          />
        )}
      </td>
      <td className="file-format">
        <GetIcon extension={getFileExtension(document.displayFileName)} />{" "}
        <span>{fileExtension.toUpperCase()}</span>
      </td>
      <td className="display-name">
        <Preview
          documentId={document.id}
          setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
          displayName={document.displayFileName}
          previewUrl={document.previewUrl}
        />
      </td>
      <td className="document-date">
        <span>{dateFormatter(document.createdAt, " | ")}</span>
      </td>
      <td className="document-type">
        <span>{humanizeString(document.documentType)}</span>
      </td>
      <td className="add-to-lawsuit">
        <AddToLawsuit document={document} fileExtension={fileExtension} />
      </td>
      <td className="actions">
        <Actions
          documentId={document.id}
          displayFileName={document.displayFileName}
          downloadUrl={document.downloadUrl}
          setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
        />
      </td>
    </tr>
  );
};

import React, { useContext, useState } from "react";
import { Alert, Intent } from "@blueprintjs/core";

import { DocumentManagerContext } from "document-manager/DocumentManager";

type TProps = {
  documentId: string;
  displayFileName: string;
  isOpen: boolean;
  onHide?: () => void;
};

export const ConfirmBox: React.FC<TProps> = ({ documentId, isOpen, onHide, displayFileName }) => {
  const { handleDelete } = useContext(DocumentManagerContext);
  const [loading, setLoading] = useState<boolean>(false);

  if (!handleDelete) {
    return <></>;
  }

  const handleDeleteClick = () => {
    setLoading(true);
    handleDelete(documentId).then(() => {
      setLoading(false);
      onHide && onHide();
    });
  };

  return (
    <Alert
      cancelButtonText="Keep document"
      confirmButtonText="Yes, delete!"
      icon="trash"
      intent={Intent.DANGER}
      isOpen={isOpen}
      onCancel={onHide}
      onConfirm={handleDeleteClick}
      loading={loading}
    >
      <p>Are you sure you want to delete {displayFileName} ?</p>
    </Alert>
  );
};

import { Button, Card, Elevation, H5, Intent } from "@blueprintjs/core";
import get from "lodash/get";
import set from "lodash/set";
import { observer } from "mobx-react-lite";
import { READ_ONLY_VERTICALS } from "../../constants";
import { OTHER_OPTION } from "pages/case-view/constants";
import { ValueContext } from "process-ui-builder";
import { DateField } from "process-ui-builder/components/form-fields/date";
import { SelectField } from "process-ui-builder/components/form-fields/select";
import { TextField } from "process-ui-builder/components/form-fields/text";
import React, { useContext } from "react";
import { Product_Enum } from "types/graphql.types";
import { CASE_TYPE_OPTIONS, CLAIM_AMOUNT_OPTIONS, SOURCE_OF_ESTIMATION_OPTIONS } from "./const";
import Persons from "./Persons";
import "./style.scss";
import { getInsuranceIncreaseDate } from "./utils";

const PkvClaimData: React.FunctionComponent = () => {
  const valueContext = useContext(ValueContext);

  const handlers = valueContext.handlers;
  const companies = handlers["getCompanies"]();
  const updateClaim = () => handlers["updatePkvClaim"]();

  const companyId = get(valueContext, "case.claim.company_id");
  const isEstimatedClaimAmount = get(valueContext, "case.claim.is_estimated_claim_amount");
  const readOnly = get(valueContext, "flow.read_only_group.claim_data") || false;
  const insuranceIncreaseDate = get(valueContext, "case.claim.insurance_increase_date");
  if (!insuranceIncreaseDate && insuranceIncreaseDate !== "" && companyId) {
    const defaultInsuranceIncreaseDate = getInsuranceIncreaseDate(companyId);
    defaultInsuranceIncreaseDate &&
      set(valueContext, "case.claim.insurance_increase_date", defaultInsuranceIncreaseDate);
  }

  return (
    <Card elevation={Elevation.ONE} className="base-card pkv-claim-card">
      <H5>Claim Data</H5>

      {companies && (
        <SelectField
          config={{
            id: "company_id",
            label: "Company",
            type: "select",
            options: companies,
            validation: {
              required: true,
            },
            value: { path: "case.claim.company_id" },
            emptyValue: true,
          }}
          readOnly={readOnly}
        />
      )}

      {companyId === OTHER_OPTION && (
        <TextField
          config={{
            label: "Other Company",
            id: "other_company",
            type: "text",
            value: { path: "case.claim.other_company" },
            validation: { required: true },
          }}
          readOnly={readOnly}
        />
      )}
      <DateField
        config={{
          label: "Insurance Increase Date",
          id: "insurance_increase_date",
          type: "date",
          validation: { required: true },
          value: { path: "case.claim.insurance_increase_date" },
        }}
        readOnly={readOnly}
      />
      <SelectField
        config={{
          id: "case_type_option",
          label: "Case Type",
          type: "select",
          options: CASE_TYPE_OPTIONS,
          validation: {
            required: true,
          },
          value: { path: "case.claim.case_type" },
          emptyValue: true,
        }}
        readOnly={readOnly}
      />
      <SelectField
        config={{
          id: "claim_amount_option",
          label: "Claim Amount",
          type: "select",
          options: CLAIM_AMOUNT_OPTIONS,
          validation: {
            required: true,
          },
          value: { path: "case.claim.is_estimated_claim_amount" },
          emptyValue: true,
          isBoolean: true,
        }}
        readOnly={readOnly}
      />

      {isEstimatedClaimAmount && (
        <SelectField
          config={{
            id: "source_of_estimation",
            label: "Source of estimation",
            readOnly,
            type: "select",
            options: SOURCE_OF_ESTIMATION_OPTIONS,
            validation: {
              required: true,
            },
            value: { path: "case.claim.source_of_estimation" },
            emptyValue: true,
          }}
          readOnly={readOnly}
        />
      )}
      <Persons />
      <Button
        onClick={updateClaim}
        intent={Intent.PRIMARY}
        text="Update Claim"
        disabled={READ_ONLY_VERTICALS.includes(Product_Enum.Pkv)}
      />
    </Card>
  );
};
export default observer(PkvClaimData);

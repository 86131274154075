import { Case_List_View_Bool_Exp } from "../../types/graphql.types";
import { getCaseStates } from "./state-filters";

const getOperator = (name: string, value: string | string[]) => {
  if (["offer.payment_on_hold_reason"].includes(name)) {
    return {
      _has_key: value,
    };
  }

  if (["state", "offer_state"].includes(name)) {
    return {
      [Array.isArray(value) ? "_in" : "_eq"]: value,
    };
  }

  return {
    _ilike: `%${value}%`,
  };
};

export const getWhere = (
  searchParams: URLSearchParams,
  isCaseAgentSupervisor: boolean
): Case_List_View_Bool_Exp => {
  const vertical = searchParams.get("vertical")?.toUpperCase();
  if (!vertical) {
    throw new Error("Invalid vertical given");
  }

  const caseState = searchParams.get("state");
  const offerState = searchParams.get("offer_state");

  let where: Case_List_View_Bool_Exp = {
    product_id: { _eq: vertical },
  };

  let dynamicWhere: Record<string, any> = {
    _or: [
      {
        state: {
          // getting default case state
          _in: getCaseStates(vertical, isCaseAgentSupervisor, ""),
        },
      },
    ],
  };

  //should clear dynamic where if any other filter is applied
  searchParams.forEach((value, key) => {
    if (dynamicWhere && value && !["vertical", "view", "page"].includes(key)) {
      dynamicWhere = {};
    }
  });

  // create where clause from search params
  searchParams.forEach((value, key) => {
    if (key === "vertical" || key === "page" || key === "perPage") {
      return;
    }

    if (!value) {
      return;
    }

    // view filter is only active if no case or offer site explicitly put
    if (key === "view" && !caseState && !offerState) {
      const caseStates = getCaseStates(vertical, isCaseAgentSupervisor, value);

      dynamicWhere = {
        _or: [
          {
            state: {
              _in: caseStates,
            },
          },
        ],
      };

      // do not process "key = view" any more
      return;
    }

    // nested filters. eg: "ocg_claim.company.name"
    if (key.includes(".")) {
      // create a json object from the dot notation
      // e.g. foo.bar -> { foo: { bar: value } }
      const json = key.split(".").reduceRight((acc, cur, currentIndex, ar) => {
        // set value if last element
        if (currentIndex === ar.length - 1) {
          return {
            [cur]: getOperator(key, value),
          };
        }

        return { [cur]: acc };
      }, {});

      dynamicWhere = {
        ...dynamicWhere,
        ...json,
      };
    } else {
      // simple filters
      dynamicWhere[key] = getOperator(key, value);
    }
  });

  where = { ...where, ...dynamicWhere };

  return where;
};

import React from "react";
import { comment } from "./types";
import moment from "moment";

interface IProps {
  comment: comment;
}

const CommentDisplay: React.FC<IProps> = ({ comment }) => {
  return (
    <>
      <div key={comment.id} className={"comment"}>
        <div className={"comment-text"}>{comment.comment}</div>
        <div className="comment-flex comment-no-padding">
          <div className={"comment-time"}>{moment.utc(comment.created_at).local().fromNow()}</div>
          <div className={"comment-time"}>
            {moment.utc(comment.created_at).format("D MMMM, YYYY")}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentDisplay;

import { Intent } from "@blueprintjs/core";
import InfoCard, { IProps as IInfoCardProps } from "components/InfoCard";
import React from "react";
import { useGffBookingRefSubmissionCountQuery } from "types/graphql.types";

interface IProps {
  bookingReference: string;
}

const DuplicateGffBookingCard: React.FC<IProps> = ({ bookingReference }) => {
  const { data } = useGffBookingRefSubmissionCountQuery({
    variables: {
      bookingReference,
    },
  });

  const currentCount = data?.case?.aggregate?.count ?? 0;

  const infoCardProps: IInfoCardProps = {
    message: (
      <>
        There are multiple submissions with the booking reference{" "}
        <strong>{bookingReference}</strong>.
      </>
    ),
    icon: "delete",
    intent: Intent.DANGER,
    buttons: [
      {
        text: "Show Cases",
        link: `/cases/?vertical=GFF&gff_claim.booking_reference=${bookingReference}`,
      },
    ],
  };

  return <>{currentCount > 1 ? <InfoCard {...infoCardProps} /> : null}</>;
};

export default DuplicateGffBookingCard;

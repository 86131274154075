import React from "react";

export const BankTransactionView: React.FC = () => {
  return (
    <div>
      <h1>THIS IS A PLACEHOLDER for the bank transaction mapping page! :)</h1>
    </div>
  );
};


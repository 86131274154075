import * as React from "react";
import { AuthContext } from "../../context/auth";
import { useLoginMutationMutation } from "../../types/graphql.types";
import { Component } from "./Component";

const Login: React.FC = () => {
  const authContext = React.useContext(AuthContext);
  const [loginMutationMutation, { loading, error }] = useLoginMutationMutation();

  const handleSubmit = React.useCallback(
    (email: string, password: string) => {
      email &&
        password &&
        loginMutationMutation({ variables: { email, password } }).then((resp) => {
          if (!resp.data?.login?.idToken) {
            return;
          }
          authContext.login(resp.data?.login?.idToken);
        });
    },
    [loginMutationMutation, authContext]
  );

  return <Component onSubmit={handleSubmit} loading={loading} error={error?.message} />;
};

export default Login;

import React from "react";
import { Callout, Intent, IconName, Tag } from "@blueprintjs/core";
import "./style.scss";
import InfocardLinkButton from "./InfocardLinkButton";

interface ButtonContent {
  text: string;
  link?: string;
  onClick?: () => void;
  intent?: Intent; // if not specified intent is the same as the incfocard
  isMainAction?: boolean; // controls whether the button is minimal (default/false) or bold 
}

export interface IProps {
  message: React.ReactNode;
  intent: Intent;
  icon: IconName;
  buttons?: ButtonContent[];
}

const InfoCard: React.FC<IProps> = ({ message, buttons, intent, icon }) => {
  return (
    <div className="info-card">
      <Callout intent={intent} icon={icon} className="sub-card-content">
        <div className="sub-card-text">
          <span>{message}</span>
          <div>
            {buttons !== undefined &&
              buttons.map(
                (button, index) =>
                  button.text && (
                    <span className="infocard-button-spacing">
                      {button.link ? (
                        <InfocardLinkButton
                          link={button.link}
                          text={button.text}
                          isMainAction={button.isMainAction}
                          intent={button.intent || intent}
                        />
                      ) : (
                        <Tag
                          className="infocard-button"
                          onClick={button.onClick}
                          intent={button.intent || intent}
                          minimal={!button.isMainAction}
                          interactive={true}
                          large={true}
                        >
                          {button.text}
                        </Tag>
                      )}
                    </span>
                  )
              )}
          </div>
        </div>
      </Callout>
    </div>
  );
};

export default InfoCard;

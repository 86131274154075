import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import { TCommonPopup, TDocument } from "document-manager/types";

import { SortableItem } from "./SortableItem";
import { Row } from "./row";

type TProps = {
  items: TDocument[];
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
  reorderDocuments: (oldIndex: number, newIndex: number) => void;
};

const SortableListComponent: React.FC<TProps> = ({
  items,
  setDocumentIdAndOpenPopUpBox,
  reorderDocuments,
}) => {
  return (
    <tbody>
      {items.map((value, index) => {
        return items.length > 1 ? (
          <SortableItem
            key={value.id}
            index={index}
            currentIndex={index}
            noOfDocuments={items.length}
            value={value}
            setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
            reorderDocuments={reorderDocuments}
          />
        ) : (
          <Row
            document={value}
            setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
            reorderDocuments={reorderDocuments}
            currentIndex={0}
            noOfDocuments={1}
          />
        );
      })}
    </tbody>
  );
};

export const SortableList = SortableContainer(SortableListComponent);

import { observer } from "mobx-react-lite";
import { Steps } from "process-ui-builder/components/steps";
import { IConfig } from "process-ui-builder/config";
import { resolveValue } from "process-ui-builder/utils";
import React, { createContext, useContext, useState, useEffect } from "react";

interface Props {
  config: IConfig;
}

// TODO: make type safe
export const ValueContext = createContext<any>({});

function UIBuilder(props: Props) {
  const { config } = props;

  const valueContext = useContext(ValueContext);

  const currentStep = resolveValue(config.currentStep, valueContext);
  const checkedSteps = resolveValue<string[]>(config.checkedSteps || [], valueContext);
  const [activeStep, setActiveStep] = useState<string>(currentStep);

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  return (
    <Steps
      config={config.steps}
      currentStep={currentStep}
      activeStep={activeStep}
      checkedSteps={checkedSteps}
      onStepChange={setActiveStep}
    />
  );
}

export default observer(UIBuilder);

export {
  TextField,
  CheckboxField,
  SelectField,
  CheckboxGroupField,
  TagList,
  DateField,
  MoneyField,
} from "process-ui-builder/components/form-fields/";

import { Card, Elevation, H5, Switch } from "@blueprintjs/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Product_Enum } from "types/graphql.types";
import { READ_ONLY_VERTICALS } from "../../../constants";
import "./style.scss";

export const CpsListHeader: React.FC<{
  vertical: Product_Enum;
  view: string | null;
  listIsFiltered: boolean;
  filter?: React.ReactNode;
}> = ({ vertical, view, filter, listIsFiltered }) => {
  const history = useHistory();

  const handleToggleClick = () => {
    history.push(`/cases/?vertical=${vertical}${view === "pending" ? "" : "&view=pending"}`);
  };
  return (
    <Card elevation={Elevation.ZERO} className={"header"}>
      {READ_ONLY_VERTICALS.includes(vertical) ? (
        <H5>{vertical} All Cases</H5>
      ) : (
        <H5>
          {vertical}&nbsp;
          <Switch
            className={"cps-list-toggle"}
            checked={view !== "pending"}
            innerLabel={view !== "pending" ? "All" : "Pending"}
            inline={true}
            onChange={handleToggleClick}
            large={true}
          />
          &nbsp;Cases
        </H5>
      )}
      {filter}
    </Card>
  );
};

import { Intent } from "@blueprintjs/core";
import { READ_ONLY_VERTICALS } from "../../../constants";
import ActionModalApproveOrCancelOffer from "components/ActionModalApproveOrCancelOffer";
import DuplicateGffBookingCard from "components/DuplicateBookingReferenceSubmissionCard";
import DuplicateCustomerAddressSubmissionCard from "components/DuplicateCustomerAddressCard";
import DuplicateMembershipSubmissionCard from "components/DuplicateMembershipSubmissionCard";
import DuplicateSubmissionCard from "components/DuplicateSubmissionCard";
import InfoCard, { IProps as IInfoCardProps } from "components/InfoCard";
import PaymentOnHoldCard from "components/PaymentOnHoldCard";
import { formatMoneyVerticals } from "helpers/moneyFormatter";
import { round } from "helpers/round";
import { successToaster } from "helpers/toaster";
import { isValidString, matchStrings } from "helpers/utils";
import {
  canApproveOrCancelOffer,
  getActiveOffer,
  isShowAllDocumentsUploadedConfirmationBox,
} from "pages/case-view/utils";
import { getReason } from "pages/utils";
import React, { useMemo, useState } from "react";
import {
  GetCaseDataQuery,
  Product_Enum,
  useAdminCaseDocumentsCompleteMutation,
} from "types/graphql.types";
import {
  DELETED,
  GDPR_DELETED,
  PAYMENT_ON_HOLD_REASON,
  REJECTED,
  REJECTION_CONFIRMED,
  WAITING_DOCUMENTS,
  WAITING_FTS_DOCUMENTS,
} from "../../../constants";

interface IProps {
  data: GetCaseDataQuery;
  setShowLoader: (bool: boolean) => void;
  onRefetch: () => void;
  claimAmount?: number;
}
const InfoCards: React.FC<IProps> = ({ data, setShowLoader, onRefetch, claimAmount }) => {
  const isProductReadOnly =
    data.baseCase?.product_id && READ_ONLY_VERTICALS.includes(data.baseCase.product_id);
  const [confirmCaseDocumentsUpload] = useAdminCaseDocumentsCompleteMutation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [confirmAction, setConfirmAction] = useState<"cancel" | "approve" | undefined>(undefined);

  const isShowOfferAmountRecommendation = useMemo(() => {
    if (data.baseCase) {
      return (
        claimAmount &&
        !!data.baseCase.ocg_claim &&
        ![REJECTED, REJECTION_CONFIRMED].includes(data.baseCase.state) &&
        !isProductReadOnly
      );
    } else {
      return false;
    }
  }, [claimAmount, data.baseCase, isProductReadOnly]);

  const isNameMatching = useMemo(() => {
    if (
      !isValidString(data.baseCase?.nflx_claim?.first_name) ||
      !isValidString(data.baseCase?.nflx_claim?.last_name)
    ) {
      return true;
    }
    if (
      data.baseCase?.nflx_claim?.first_name &&
      data.baseCase?.nflx_claim?.last_name &&
      data.baseCase?.customer?.first_name &&
      data.baseCase?.customer?.last_name
    ) {
      return (
        matchStrings(data.baseCase?.nflx_claim?.first_name, data.baseCase?.customer?.first_name) &&
        matchStrings(data.baseCase?.nflx_claim?.last_name, data.baseCase?.customer?.last_name)
      );
    } else {
      return false;
    }
  }, [data.baseCase]);

  const isEmailMatching = useMemo(() => {
    if (
      !isValidString(data.baseCase?.nflx_claim?.account_email) ||
      !isValidString(data.baseCase?.customer?.email)
    ) {
      return true;
    }
    if (data.baseCase?.customer?.email && data.baseCase?.nflx_claim?.account_email) {
      return matchStrings(data.baseCase?.nflx_claim?.account_email, data.baseCase?.customer?.email);
    } else {
      return false;
    }
  }, [data.baseCase]);

  if (!data.baseCase) {
    return <></>;
  }

  const activeOffer = getActiveOffer(data.baseCase);

  const productIsReadOnlyInfoCardProps: IInfoCardProps = {
    message: (
      <>
        <b>{data.baseCase.product_id}</b> product is <b>READ-ONLY</b>.
      </>
    ),
    intent: Intent.WARNING,
    icon: "info-sign",
  };

  const rejectionReasonInfoCardProps: IInfoCardProps = {
    message:
      data.baseCase?.rejection_reason &&
      `Rejection reason: ${getReason(
        data.baseCase.rejection_reason,
        data.baseCase.other_rejection_reason as string
      )}`,
    intent: Intent.WARNING,
    icon: "warning-sign",
  };

  const documentRequestReasonInfoCardProps: IInfoCardProps = {
    message: data.baseCase?.document_request_reason && data.baseCase.requested_documents && (
      <>
        Documents requested: <b>{data.baseCase.requested_documents.join(", ")}</b>
        <br />
        Request reason:{" "}
        <b>
          {getReason(
            data.baseCase.document_request_reason,
            data.baseCase.other_document_reason as string
          )}
        </b>
      </>
    ),
    intent: Intent.WARNING,
    icon: "warning-sign",
  };

  const allDocumentsUploadedActionBoxProps: IInfoCardProps = {
    message: "Are all the documents uploaded?",
    intent: Intent.PRIMARY,
    icon: "info-sign",
    buttons: [
      {
        onClick: () => {
          if (data.baseCase?.id) {
            confirmCaseDocumentsUpload({
              variables: {
                id: data.baseCase.id,
              },
            }).then((resp) => {
              if (resp.errors?.length) {
                setShowLoader(false);
                return;
              }
              successToaster("Documents uploaded confirmed.");
              onRefetch();
            });
          }
        },
        text: "Confirm",
      },
    ],
  };

  const offerAmountInfo: IInfoCardProps = {
    message: claimAmount && (
      <>
        Recommended offer amount for &nbsp;
        <strong>Germany: {formatMoneyVerticals(round(claimAmount * 0.1))}</strong>
        <br />
        Recommended offer amount for &nbsp;
        <strong>Austria: {formatMoneyVerticals(round(claimAmount * 0.3))}</strong>
      </>
    ),
    intent: Intent.WARNING,
    icon: "warning-sign",
  };

  const nameComparatorInfoCardProps: IInfoCardProps = {
    message: (
      <>
        The customer&apos;s &quot;First and/or Last Names&quot; are not matching to the account
        owner&apos;s &quot;First and/or Last Names&quot;.
      </>
    ),
    icon: "warning-sign",
    intent: Intent.WARNING,
  };

  const emailAddressesComparatorInfoCardProps: IInfoCardProps = {
    message: (
      <>
        The customer&apos;s &quot;E-mail Address&quot; is not matching to the &quot;Netflix Account
        E-mail&quot;.
      </>
    ),
    icon: "warning-sign",
    intent: Intent.WARNING,
  };

  const getPaymentOnHoldReason = () => {
    const result = [];

    if (data.baseCase && activeOffer) {
      const baseCase = data.baseCase;
      if (activeOffer.payment_on_hold && activeOffer.payment_on_hold_reason?.length) {
        const nflxAccountEmail = baseCase.nflx_claim?.account_email;

        result.push(
          <PaymentOnHoldCard
            reasons={activeOffer.payment_on_hold_reason as string[]}
            activeOfferId={activeOffer.id}
            iban={activeOffer.bank_account?.iban}
            addressHash={activeOffer.address?.hash || undefined}
            nflxAccountEmail={nflxAccountEmail || undefined}
            canApproveOrCancelOffer={!isProductReadOnly && canApproveOrCancelOffer(baseCase)}
            productId={baseCase.product_id}
            setConfirmAction={setConfirmAction}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        );
      }

      if (
        activeOffer.address?.hash &&
        !activeOffer.payment_on_hold_reason.includes(
          PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS
        )
      ) {
        result.push(
          <DuplicateCustomerAddressSubmissionCard
            hash={activeOffer.address.hash}
            productId={baseCase.product_id}
          />
        );
      }
    }
    return result;
  };

  const approveOrCancelOfferProps = {
    productId: data.baseCase.product_id,
    caseId: data.baseCase.id,
    onMutationSuccess: () => {
      setIsConfirmModalOpen(false);
      setShowLoader(false);
      onRefetch();
    },
    onMutationError: () => {
      setIsConfirmModalOpen(false);
      setShowLoader(false);
    },
    isOpen: isConfirmModalOpen,
    onHide: () => {
      setIsConfirmModalOpen(false);
    },
    mode: confirmAction,
  };

  return (
    <div className="info-card-wrapper">
      {isProductReadOnly ? <InfoCard {...productIsReadOnlyInfoCardProps} /> : null}
      {getPaymentOnHoldReason()}
      {data.baseCase.state !== DELETED && (
        <DuplicateSubmissionCard
          productId={data.baseCase?.product_id!}
          email={data.baseCase?.customer.email}
        />
      )}
      {data.baseCase.product_id === Product_Enum.Fit &&
        data.baseCase.fit_claims[0]?.membership_number &&
        ![DELETED, GDPR_DELETED].includes(data.baseCase.state) && (
          <DuplicateMembershipSubmissionCard
            membershipNumber={data.baseCase.fit_claims[0].membership_number}
            vertical="FIT"
          />
        )}
      {data.baseCase.product_id === Product_Enum.Gff &&
        data.baseCase.gff_claim?.booking_reference && (
          <DuplicateGffBookingCard bookingReference={data.baseCase.gff_claim.booking_reference} />
        )}
      {data.baseCase.product_id === Product_Enum.Nflx && !isNameMatching && (
        <InfoCard {...nameComparatorInfoCardProps} />
      )}
      {data.baseCase.product_id === Product_Enum.Nflx && !isEmailMatching && (
        <InfoCard {...emailAddressesComparatorInfoCardProps} />
      )}

      {data.baseCase?.rejection_reason && <InfoCard {...rejectionReasonInfoCardProps} />}

      {data.baseCase?.document_request_reason &&
        [WAITING_DOCUMENTS, WAITING_FTS_DOCUMENTS].includes(data.baseCase?.state) && (
          <InfoCard {...documentRequestReasonInfoCardProps} />
        )}

      {data.baseCase && isShowAllDocumentsUploadedConfirmationBox(data.baseCase) && (
        <InfoCard {...allDocumentsUploadedActionBoxProps} />
      )}

      {isShowOfferAmountRecommendation ? <InfoCard {...offerAmountInfo} /> : <></>}
      <ActionModalApproveOrCancelOffer {...approveOrCancelOfferProps} />
    </div>
  );
};

export default InfoCards;

export const CLAIM_AMOUNT_OPTIONS = [
  { value: "false", label: "Actual Claim Amount" },
  { value: "true", label: "Estimated Claim Amount" },
];

//date format below is MM/DD/YYYY
export const INSURANCE_INCREASED_DATE: { [key: string]: string } = {
  BARMENIA: "01/01/2019",
  BAYERISCHE_BEAMTENKRANKENKASSE: "05/01/2018",
  DEBEKA: "01/01/2019",
  DKV: "01/01/2020",
  GENERALI_CENTRAL: "01/01/2019",
  GOTHAER: "01/01/2019",
  HALLESCHE: "01/01/2019",
  INTER: "01/01/2019",
  LANDESKRANKENHILFE_LKH: "01/01/2021",
  MUNCHENER_VEREIN: "01/01/2019",
  NURNBERGER_KRANKENVERSICHERUNG: "01/01/2020",
  UNI_VERSA: "01/01/2020",
  LVM: "01/01/2019",
  SDK_SUDDEUTSCHE: "01/01/2019",
  SIGNAL_IDUNA_DEUTSCHER_RING: "01/01/2019",
  CONTINENTALE: "01/01/2018",
};

export const MAX_INSURED_PERSON = 5;

export const PLACEHOLDER_PERSON = {
  name: "",
  tariffs: ["", "", "", "", ""],
  insured_since: "",
  insurance_number: "",
};

export const SOURCE_OF_ESTIMATION_OPTIONS = [
  { value: "RIGHTNOW", label: "Claim Amount Estimated by RightNow" },
  { value: "MINERVA", label: "Claim Amount Estimated by Minerva" },
];

export const CASE_TYPE_OPTIONS = [
  { value: "GUETESTELLE_CASE", label: "Gütestelle Case" },
  { value: "OTHER_CASE", label: "Other Case" },
];

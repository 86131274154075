import { AnchorButton, ButtonGroup, Intent } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import React, { useMemo, useState } from "react";
import {
  canSendOffer,
  canDeleteOffer,
  getActiveOffer,
  canResendOffer,
  canRefuseOffer,
} from "./../utils";
import {
  GetCaseDataQuery,
  useAdminOfferDeleteMutation,
  useAdminResendOfferMutation,
  Product_Enum,
  useAdminSendOfferMutation,
} from "types/graphql.types";
import { successToaster, warningToaster } from "helpers/toaster";
import {
  tooltipContentNoActiveOffer,
  tooltipContentPermission,
  tooltipContentRefuseOffer,
} from "pages/const";
import ConfirmRefuseOfferModal from "components/ConfirmRefuseOfferModal";
import { EXPIRED } from "../../../constants";

import "./style.scss";

interface IProps {
  data: GetCaseDataQuery;
  onActionSuccess: () => Promise<void>;
  isCaseAgentSupervisor: boolean;
}

export const StepTwoActions: React.FC<IProps> = ({
  data,
  onActionSuccess,
  isCaseAgentSupervisor,
}) => {
  const [isDeleteOfferLoading, setIsDeleteOfferLoading] = useState<boolean>(false);
  const [isSendOfferLoading, setIsSendOfferLoading] = useState<boolean>(false);
  const [isResendOfferLoading, setIsResendOfferLoading] = useState<boolean>(false);
  const [offerDeleteMutation] = useAdminOfferDeleteMutation();

  //auto vertical mutation
  const [resendOfferMutation] = useAdminResendOfferMutation();
  const [sendOfferMutation] = useAdminSendOfferMutation();

  const activeOffer = useMemo(() => getActiveOffer(data.baseCase), [data.baseCase]);

  const handleOfferSend = () => {
    if (!activeOffer) {
      return;
    }

    setIsSendOfferLoading(true);
    const vertical = data.baseCase?.product_id!;
    const input = {
      case: {
        id: data.baseCase?.id!,
      },
    };
    sendOfferMutation({
      variables: {
        input: { configName: `${vertical.toLowerCase()}-claim-purchasing`, input },
      },
    }).then((resp) => {
      handleMutationResponse(!!resp.errors?.length, "The offer is sent.", setIsSendOfferLoading);
    });
    return;
  };

  const handleOfferDelete = () => {
    if (!activeOffer) {
      return;
    }
    setIsDeleteOfferLoading(true);
    offerDeleteMutation({
      variables: { id: activeOffer.id },
    }).then((resp) => {
      handleMutationResponse(
        !!resp.errors?.length,
        "The offer is deleted.",
        setIsDeleteOfferLoading
      );
    });
  };

  const [showConfirmRefuseOfferModal, setShowConfirmRefuseOfferModal] = useState<boolean>(false);

  const handleOfferResend = () => {
    setIsResendOfferLoading(true);

    let offerId = activeOffer?.id;

    if (!offerId) {
      const expiredOffer = data.baseCase?.offers.filter((offer) => offer.state === EXPIRED);
      if (expiredOffer && expiredOffer.length > 0) {
        //  there can only be one expired offer
        offerId = expiredOffer[0].id;
      }
    }

    if (!offerId) {
      // very unlikely to happen as we will have an offerId if the offer is active or an expired offer
      // else we don't show the button
      return alert("No offer to resend");
    }

    //call auto vertical resend offer mutation
    resendOffer(data.baseCase?.product_id!, { case: { offer: { id: offerId } } });
  };

  //Auto vertical resend offer
  const resendOffer = (
    vertical: Product_Enum,
    input: {
      case: {
        offer: {
          id: string;
        };
      };
    }
  ) => {
    resendOfferMutation({
      variables: {
        input: { configName: `${vertical.toLowerCase()}-claim-purchasing`, input },
      },
    }).then((resp) => {
      handleMutationResponse(
        !!resp.errors || false,
        "The offer is resend.",
        setIsResendOfferLoading
      );
    });
  };

  const handleMutationResponse = (
    error: boolean,
    successMessage: string,
    setLoader: (value: boolean) => void
  ) => {
    if (error) {
      setLoader(false);
      return;
    }
    successToaster(successMessage);
    onActionSuccess().then(() => {
      setLoader(false);
    });
  };

  const refuseOfferProps = {
    vertical: data.baseCase?.product_id!,
    isOpen: showConfirmRefuseOfferModal,
    onHide: () => setShowConfirmRefuseOfferModal(false),
    onRefuseOfferSuccess: () => {
      successToaster("The offer has been successfully refused");
      onActionSuccess();
    },
    onRefuseOfferFailure: () => {
      warningToaster("The offer could not be refused");
      onActionSuccess();
    },
  };

  const disabledButtonTooltip = (buttonType: string) => {
    if (!isCaseAgentSupervisor) {
      return tooltipContentPermission;
    }
    if (buttonType === "refuse") {
      return tooltipContentRefuseOffer;
    }
    return tooltipContentNoActiveOffer;
  };

  const renderButton = (buttonProps: any) => {
    const toolTip = buttonProps.disabled
      ? disabledButtonTooltip(buttonProps.text.toLowerCase())
      : "";

    return (
      <Tooltip2 content={toolTip} intent={Intent.WARNING}>
        <AnchorButton {...buttonProps} />;
      </Tooltip2>
    );
  };

  const deleteButton = {
    text: "Delete",
    intent: Intent.WARNING,
    large: true,
    onClick: handleOfferDelete,
    disabled: !isCaseAgentSupervisor || !canDeleteOffer(data.baseCase),
    loading: isDeleteOfferLoading,
  };

  const refuseButton = {
    text: "Refuse",
    intent: Intent.WARNING,
    large: true,
    onClick: () => setShowConfirmRefuseOfferModal(true),
    disabled: !isCaseAgentSupervisor || !canRefuseOffer(data.baseCase),
  };

  const resendButton = {
    text: "Resend Offer",
    intent: Intent.PRIMARY,
    large: true,
    onClick: handleOfferResend,
    disabled: !isCaseAgentSupervisor,
    loading: isResendOfferLoading,
  };

  const sendButton = {
    text: "Send Offer",
    intent: Intent.PRIMARY,
    large: true,
    onClick: handleOfferSend,
    disabled: !isCaseAgentSupervisor || !canSendOffer(data.baseCase),
    loading: isSendOfferLoading,
  };

  return (
    <div className="one">
      <ButtonGroup>
        {renderButton(deleteButton)}
        {renderButton(refuseButton)}
      </ButtonGroup>
      <div className="right-group">
        {renderButton(canResendOffer(data.baseCase) ? resendButton : sendButton)}
      </div>
      {activeOffer && data.baseCase && (
        <ConfirmRefuseOfferModal caseId={data.baseCase.id} {...refuseOfferProps} />
      )}
    </div>
  );
};

import { paginationCalculator } from "pagination-calculator";
import React from "react";
import { Pages } from "./Pages";
import { PerPage } from "./PerPage";
import "./style.scss";

interface Props {
  perPage: number;
  total: number;
  currentPage: number;
  onPerPageChange: (v: number) => void;
  onPageChange: (page: number) => void;
}

export const Paginator: React.FC<Props> = ({
  total,
  perPage,
  currentPage,
  onPerPageChange,
  onPageChange,
}) => {
  const paginator = paginationCalculator({
    total,
    current: currentPage,
    pageSize: perPage,
    pageLimit: 10,
  });

  return (
    <div className="pager">
      <PerPage perPage={perPage} onChange={onPerPageChange} />
      <Pages paginator={paginator} onPageChange={onPageChange} />
    </div>
  );
};

import React from "react";
import { SortableElement } from "react-sortable-hoc";

import { TCommonPopup, TDocument } from "document-manager/types";
import { Row } from "./row";

type TProps = {
  value: TDocument;
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
  currentIndex: number;
  noOfDocuments: number;
  reorderDocuments: (oldIndex: number, newIndex: number) => void;
};

const SortableItemComponent: React.FC<TProps> = ({
  value,
  setDocumentIdAndOpenPopUpBox,
  currentIndex,
  noOfDocuments,
  reorderDocuments,
}) => {
  return (
    <Row
      document={value}
      setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
      reorderDocuments={reorderDocuments}
      currentIndex={currentIndex}
      noOfDocuments={noOfDocuments}
    />
  );
};

export const SortableItem = SortableElement(SortableItemComponent);

import { gql } from "@apollo/client";

export const RESOLVE_CUSTOMER_PAYOUT = gql`
  mutation resolveCustomerPayout($id: ID!, $resolveStatus: String!) {
    resolvePayment(input: { id: $id, resolveStatus: $resolveStatus }) {
      id
      status
    }
  }
`;

import React from "react";
import { Card, Elevation, H5, HTMLTable, Intent, Tag } from "@blueprintjs/core";
import "./style.scss";

interface Props {
  state: string;
}

const PaymentCard: React.FC<Props> = ({ state }) => {
  return (
    <Card elevation={Elevation.ONE} className="active-payment-details base-card">
      <H5>Payment</H5>
      <HTMLTable>
        <table>
          <tbody>
            <tr>
              <td>Status:</td>
              <td className="state-value">
                <Tag intent={Intent.WARNING} large={true}>
                  {state}
                </Tag>
              </td>
            </tr>
          </tbody>
        </table>
      </HTMLTable>
    </Card>
  );
};

export default PaymentCard;

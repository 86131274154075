import { Spinner } from "@blueprintjs/core";
import { ErrorMessage } from "components/shared/ErrorMessage";
import UIBuilder, { ValueContext } from "process-ui-builder";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ClaimData, DocumentsList, Header } from "../../components";
import { LegalJson } from "./config";
import { getCurrentStep, getCustomerName, isOcgClaim } from "./utils";
import {
  useGetLegalCaseByIdQuery,
  useGetPrevNextLegalCaseByIdLazyQuery,
} from "types/graphql.types";
import { Document } from "services/legal-service/types";
import { LawsuitData } from "services/legal-service/components/LawsuitData";
import { NoLawsuitDataAvailable } from "services/legal-service/components/NoLawsuitDataAvailable";

const LegalCaseProcessor: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetLegalCaseByIdQuery({
    variables: {
      id,
    },
    context: {
      roleType: "legal",
    },
  });

  const [
    getPrevNextCase,
    { data: prevNextLsCaseData, error: nextCaseError },
  ] = useGetPrevNextLegalCaseByIdLazyQuery();

  useEffect(() => {
    if (data?.lsCase) {
      getPrevNextCase({
        variables: {
          id,
          legal_process_started_at: data.lsCase.legal_process_started_at,
        },
        context: {
          roleType: "legal",
        },
      });
    }
  }, [getPrevNextCase, id, data?.lsCase]);

  const claim = useMemo(() => {
    if (data && data.lsCase) {
      if (isOcgClaim(data.lsCase.claim)) {
        return {
          ...data.lsCase.claim,
          // isSportsBetting needs to be converted to betting type
          ...{
            bettingType:
              data.lsCase.claim.isSportsBetting === true ? "Sports Betting" : "Casino Betting",
          },
        };
      } else {
        return data.lsCase.claim;
      }
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error || nextCaseError)
    return <ErrorMessage error={error?.message || nextCaseError?.message} />;

  if (!data || !data.lsCase) return <ErrorMessage error={"No legal case data found"} />;
  const customComponents = {
    DocumentsList: data.lsCase.documents?.length ? (
      <DocumentsList documents={data.lsCase.documents as Document[]} />
    ) : (
      <></>
    ),
    ClaimData: <ClaimData vertical={data.lsCase.vertical} />,
    LawsuitData: data.lsCase.ls_lawsuit ? <LawsuitData /> : null,
  };

  return (
    <>
      <Header
        page="Case"
        state={data.lsCase.status}
        createdAt={data.lsCase.legal_process_started_at}
        aliasId={data.lsCase.alias_id}
        nextCase={prevNextLsCaseData?.nextLsCase[0]?.id}
        prevCase={prevNextLsCaseData?.prevLsCase[0]?.id}
      />

      {!data.lsCase.ls_lawsuit && <NoLawsuitDataAvailable />}
      <ValueContext.Provider
        value={{
          flow: { current_step: getCurrentStep(data) },
          case: {
            debtorName: data.lsCase.debtor_name,
            vertical: data.lsCase.vertical,
            companyId: data.lsCase.company_id,
            financialData: {
              claimAmount: data.lsCase.claim_amount,
              openClaimAmount: data.lsCase.open_claim_amount,
              lawyerFee: data.lsCase.lawyer_fee,
            },
            lawsuitData: data.lsCase.ls_lawsuit,
            claim,
            customerData: {
              name: getCustomerName(data),
            },
          },
          customComponents,
        }}
      >
        <UIBuilder config={LegalJson} />
      </ValueContext.Provider>
    </>
  );
};

export default LegalCaseProcessor;

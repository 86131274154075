import { Rules, PermissionsType } from "../config/acl";
import { store } from "../store/store";

export const Can = (perform: PermissionsType) => {
  let allowed = false;
  const state = store.getState();
  const roles = state.roles.roles;

  roles?.forEach((role) => {
    if (Rules[role] && Rules[role].includes(perform as any as string)) {
      allowed = true;
    }
  });

  return allowed;
};

import { Intent } from "@blueprintjs/core";
import {
  DELETED,
  GDPR_DELETED,
  REJECTION_CONFIRMED,
  ACCEPTED,
  WAITING_DOCUMENTS,
  WAITING_FTS_DOCUMENTS,
  REFUSED,
  EXPIRED,
  PAYMENT_PENDING,
  PAYMENT_INITIATED,
  CREATED,
  SENT,
  PAYMENT_SUCCESSFUL,
  ASSIGNMENT_SENT,
  ASSIGNMENT_RECEIVED,
  REVOKED,
} from "../../constants";

export const caseStateTagIsMinimal = (state: string, caseHasOffer: boolean): boolean => {
 return ([DELETED, GDPR_DELETED, REJECTION_CONFIRMED, REVOKED].includes(state) || (caseHasOffer && state === ACCEPTED));
};

export const caseStateTagIntent = (state: string, pending: boolean): Intent => {
  if (pending) {
    return Intent.WARNING;
  }
  else if ([ACCEPTED, WAITING_DOCUMENTS, WAITING_FTS_DOCUMENTS].includes(state)) {
    return Intent.SUCCESS;
  }
  return Intent.NONE;
};

export const offerStateTagIsMinimal = (state: string): boolean => [
  REFUSED,
  DELETED,
  EXPIRED,
  PAYMENT_PENDING,
  PAYMENT_INITIATED,
  PAYMENT_SUCCESSFUL,
].includes(state);

export const offerStateTagIntent = (state: string): Intent => {
  if (
    [
      CREATED,
      SENT,
      ASSIGNMENT_SENT,
      ACCEPTED,
      ASSIGNMENT_RECEIVED,
      PAYMENT_SUCCESSFUL,
      PAYMENT_PENDING,
      PAYMENT_INITIATED,
    ].includes(state)
  ) {
    return Intent.SUCCESS;
  }
  return Intent.NONE;
};

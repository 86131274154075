import { Intent, Alert } from "@blueprintjs/core";
import React from "react";
import { observer } from "mobx-react-lite";
import { useAdminCaseDeleteMutation } from "types/graphql.types";

export interface IProps {
  product?: string;
  caseId?: string;
  onDeleteSuccess: () => void;
  isOpen: boolean;
  onHide: () => void;
}

const ActionModalDeleteCase: React.FC<IProps> = observer(
  ({ product, caseId, onDeleteSuccess, isOpen, onHide }) => {
    const [caseDeleteMutation] = useAdminCaseDeleteMutation();

    const onSuccess = () => {
      onHide();
      onDeleteSuccess();
    };

    const handleDelete = () => {
      caseDeleteMutation({
        variables: {
          id: caseId!,
        },
      }).then((resp) => {
        if (resp.errors?.length) {
          onHide();
          return;
        }
        onSuccess();
      });
    };

    return (
      <>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={isOpen}
          onCancel={() => onHide()}
          onConfirm={handleDelete}
        >
          <p>
            This action will only<b> soft delete</b> the case and will not remove the customer data
            completely.
          </p>
          <p>
            If your wish is to apply a GDPR data removal request, select &lsquo;GDPR Delete&rsquo;
            (Supervisor only).
          </p>
        </Alert>
      </>
    );
  }
);

export default ActionModalDeleteCase;

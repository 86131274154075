import { Button, Intent } from "@blueprintjs/core";
import get from "lodash.get";
import set from "lodash/set";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ValueContext } from "process-ui-builder";
import React, { useContext, useMemo, useState } from "react";
import { Product_Enum } from "types/graphql.types";
import { InsuredPerson } from "types/custom.types";
import { READ_ONLY_VERTICALS } from "../../../constants";
import { MAX_INSURED_PERSON, PLACEHOLDER_PERSON } from "../const";
import Person from "./Person";
import "./style.scss";
import { checkValidPersonData } from "./utils";

const Persons: React.FunctionComponent = () => {
  const valueContext = useContext(ValueContext);

  const persons: InsuredPerson[] = get(valueContext, "case.claim.insured_persons");
  const readOnly = get(valueContext, "flow.read_only_group.claim_data");

  const [personCount, setPersonCount] = useState<number>(persons.length);
  const isProductReadOnly = READ_ONLY_VERTICALS.includes(Product_Enum.Pkv);

  const addAnotherPerson = action(() => {
    runInAction(() => {
      const lastPerson: { [key: string]: any } | undefined = persons[persons.length - 1];
      if (persons.length === personCount && checkValidPersonData(lastPerson)) {
        const updatedPersons = [...persons];
        updatedPersons.push(PLACEHOLDER_PERSON);
        setPersonCount(persons.length + 1);
        set(valueContext, "case.claim.insured_persons", updatedPersons);
      } else {
        alert(
          `Name, Tariff 1, Insured since and insurance number is required, Check Person ${
            personCount ? personCount : 1
          }`
        );
      }
    });
  });

  const getPerson = useMemo(() => {
    let personArray: React.ReactNode[] = [];

    const deletePerson = action((count: number) => {
      runInAction(() => {
        if (persons.length <= 1) {
          alert("Minimum one person is required for claim.");
          return;
        }
        const persons1 = [...persons];
        persons1.splice(count, 1);
        set(valueContext, "case.claim.insured_persons", persons1);
        setPersonCount(personCount - 1);
      });
    });

    let personsIndex = 0;

    if (persons.length) {
      for (personsIndex = 0; personsIndex < MAX_INSURED_PERSON; personsIndex++) {
        if (personsIndex < persons.length && persons.length !== 0) {
          personArray.push(
            <Person
              person={personsIndex < persons.length ? persons[personsIndex] : undefined}
              count={personsIndex}
              readOnly={readOnly}
              isOpen={personsIndex + 1 === personCount}
              onDeletePerson={deletePerson}
              key={personsIndex}
            />
          );
        } else {
          break;
        }
      }
    }
    if (persons.length === 0 || persons.length < personCount) {
      const updatedPersons = [...persons];
      updatedPersons.push(PLACEHOLDER_PERSON);
      setPersonCount(persons.length + 1);
      set(valueContext, "case.claim.insured_persons", updatedPersons);
    }

    return personArray;
  }, [personCount, readOnly, persons, valueContext]);

  return (
    <>
      {getPerson}
      {!readOnly && !isProductReadOnly && persons.length <= MAX_INSURED_PERSON - 1 && (
        <Button
          icon={"add"}
          text={"Add another Person"}
          intent={Intent.WARNING}
          onClick={addAnotherPerson}
          className={"add-person-button"}
        />
      )}
    </>
  );
};
export default observer(Persons);

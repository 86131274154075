import React from "react";
import Topbar from "../shared/Topbar";
import Left from "./Left";
import "./style.scss";

const Layout: React.FC = ({ children }) => {
  return (
    <div className="layout-cont">
      <div className="left-cont">
        <Left />
      </div>
      <div className="right">
        <div className="header">
          <div className="topbar-cont">
            <Topbar />
          </div>
        </div>
        <div className="content-cont">
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;

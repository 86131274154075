import Debug from "../app-debug";
import jwt from "jsonwebtoken";
import { SET_AUTH_TOKEN } from "./auth";

export const SET_ROLES = "ROLES/SET_ROLES";
export const REMOVE_ROLES = "AUTH/REMOVE_ROLES";

export interface SetRolesAction {
  type: typeof SET_ROLES;
  payload: {
    roles: [];
  };
}

export interface RemoveRolesAction {
  type: typeof REMOVE_ROLES;
}

export type AuthActionTypes = SetRolesAction | RemoveRolesAction;

export function setRoles(token: string) {
  const tokenData: any = jwt.decode(token);

  Debug.debug("AUTH")(`${SET_AUTH_TOKEN} -> ${token}`);
  Debug.debug("AUTH")(`TOKEN DATA -> ${JSON.stringify(tokenData)}`);

  let roles = [];
  if (tokenData) {
    roles = tokenData["claims"]["allowed_roles"];
  }

  Debug.debug("ROLES")(`${SET_ROLES} -> ${JSON.stringify(roles)}`);

  return {
    type: SET_ROLES,
    payload: {
      roles: roles,
    },
  };
}

export function removeRoles() {
  return {
    type: REMOVE_ROLES,
  };
}

import { Card, H5 } from "@blueprintjs/core";
import { Field } from "process-ui-builder/components/form-fields";
import { OnSubmit } from "process-ui-builder/components/form-group/OnSubmit";
import { FormGroupSchema } from "process-ui-builder/config/types";
import React from "react";
import "./style.scss";

interface Props {
  config: FormGroupSchema;
  readOnly: boolean;
}

export const FormGroup: React.FC<Props> = ({ config, readOnly }) => {
  const panelWrapper =
    "panel-wrapper col-span-" +
    (config.size?.colSpan || 1) +
    " row-span-" +
    +(config.size?.rowSpan || 1);

  return (
    <React.Fragment>
      <Card elevation={1} className={panelWrapper}>
        <div className="panel--heading">
          <H5>{config.title}</H5>
        </div>
        <div className="panel--body">
          {config.fields.map((f) => (
            <Field key={f.id} config={f} readOnly={readOnly} />
          ))}
        </div>
        <OnSubmit config={config.onSubmit} />
      </Card>
    </React.Fragment>
  );
};

import { Button, Intent } from "@blueprintjs/core";
import { successToaster } from "helpers/toaster";
import { GetCaseDataQuery, useAdminAssignmentResendMutation } from "types/graphql.types";
import { canResendAssignment, getActiveOffer } from "../utils";
import React, { useState } from "react";
import "./style.scss";

interface IProps {
  onActionSuccess: () => Promise<void>;
  data: GetCaseDataQuery;
}

export const StepThreeActions: React.FC<IProps> = ({ data, onActionSuccess }) => {
  const [isResendAssignmentLoading, setIsResendAssignmentLoading] = useState<boolean>(false);

  const [assignmentResendMutation] = useAdminAssignmentResendMutation();

  const handleAssignmentResend = () => {
    const activeOffer = getActiveOffer(data.baseCase);

    if (activeOffer?.id) {
      setIsResendAssignmentLoading(true);
      assignmentResendMutation({
        variables: { id: activeOffer.id },
      }).then((resp) => {
        if (resp.errors?.length) {
          setIsResendAssignmentLoading(false);
          return;
        }
        successToaster("New assignment is created and will be sent soon");
        onActionSuccess().then(() => {
          setIsResendAssignmentLoading(false);
        });
      });
    }
  };
  return (
    <div className="one">
      {
        <Button
          text={"Resend Assignment"}
          intent={Intent.PRIMARY}
          large
          onClick={handleAssignmentResend}
          loading={isResendAssignmentLoading}
          disabled={!canResendAssignment(data.baseCase)}
        />
      }
    </div>
  );
};

import { useNavigateTo } from "./use-navigate-to";

export function useHandleOnSortByColumn() {
  const navigateTo = useNavigateTo();

  return (sort: { sortColumn: string; sortOrder: string }) => {
    navigateTo([
      {
        name: "sortColumn",
        value: sort.sortColumn,
      },
      {
        name: "sortOrder",
        value: sort.sortOrder,
      },
    ]);
  };
}

import { LegalCaseStatus } from "../../constants";
import { GetLegalCaseByIdQuery } from "types/graphql.types";
import { OcgClaim } from "services/legal-service/types";

function assertCase(
  data: GetLegalCaseByIdQuery["lsCase"]
): asserts data is NonNullable<GetLegalCaseByIdQuery["lsCase"]> {
  if (!data) {
    throw new Error("No legal case in data");
  }
}

export function getCustomerName({ lsCase }: GetLegalCaseByIdQuery) {
  assertCase(lsCase);
  return `${
    lsCase.salutation === "MASCULINE" ? "Herr" : lsCase.salutation === "FEMININE" ? "Frau" : ""
  } ${lsCase.first_name} ${lsCase.last_name}`;
}

export function getCurrentStep({ lsCase }: GetLegalCaseByIdQuery) {
  assertCase(lsCase);
  if (
    [
      LegalCaseStatus.INITIALIZED,
      LegalCaseStatus.RIGHTNOW_LETTER_CREATED,
      LegalCaseStatus.RIGHTNOW_LETTER_SENT,
    ].includes(lsCase.status as LegalCaseStatus)
  )
    return "right_now_letter";

  if (
    [LegalCaseStatus.LAWYER_LETTER_CREATED, LegalCaseStatus.LAWYER_LETTER_SENT].includes(
      lsCase.status as LegalCaseStatus
    )
  )
    return "lawyer_letter";

  return "right_now_letter";
}

export function isOcgClaim(claim: { [key: string]: any }): claim is OcgClaim {
  return !!(claim as OcgClaim).moneySpent;
}

import React, { useContext } from "react";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import flatten from "lodash/flatten";

import { DocumentManagerContext } from "document-manager";
import { TCommonPopup } from "document-manager/types";

import { SortableWrapper } from "./SortableWrapper";
import { PreviewModal, EditModal } from "./modals";

import "./styles.scss";

export const Body: React.FC = () => {
  const { documents } = useContext(DocumentManagerContext);
  const sortedDocument = sortBy(documents, ["documentType", "sortOrder", "createdAt"]);
  const documentsGroups = groupBy(sortedDocument, "documentType");
  const flattenDocuments = flatten(Object.values(documentsGroups));

  const [documentId, setDocumentId] = React.useState<string>("");
  const [isPreviewBoxOpen, setIsPreviewBoxOpen] = React.useState<boolean>(false);
  const [isEditBoxOpen, setIsEditBoxOpen] = React.useState<boolean>(false);

  const handlePreviewClose = () => {
    setDocumentId("");
    setIsPreviewBoxOpen(false);
  };

  const handleEditClose = () => {
    setDocumentId("");
    setIsEditBoxOpen(false);
  };

  const setDocumentIdAndOpenPopUpBox = (id: string, popUpName: TCommonPopup) => {
    setDocumentId(id);
    if (popUpName === "preview") {
      setIsPreviewBoxOpen(true);
    }
    if (popUpName === "edit") {
      setIsEditBoxOpen(true);
    }
  };

  return (
    <>
      {documents &&
        Object.keys(documentsGroups).map((documentType) => {
          const documents = documentsGroups[documentType];
          return (
            <SortableWrapper
              key={documentType}
              documents={documents}
              category={documentType}
              setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
            />
          );
        })}

      {documentId && (
        <>
          <PreviewModal
            documentsList={flattenDocuments}
            documentId={documentId}
            isOpen={isPreviewBoxOpen}
            onHide={handlePreviewClose}
          />
          <EditModal
            document={flattenDocuments.filter((document) => document.id === documentId)[0]}
            onHide={handleEditClose}
            isOpen={isEditBoxOpen}
          />
        </>
      )}
    </>
  );
};

import React from "react";
import { dateFormatter } from "helpers/dateFormatter";

interface IProps {
  createdAt: string;
  state: string;
  sentFileUrl: string | null;
  receivedFileUrl: string | null;
}

const AssignmentLinksRow: React.FC<IProps> = ({
  createdAt,
  state,
  sentFileUrl,
  receivedFileUrl,
}) => {
  return (
    <>
      <tr>
        <td>{dateFormatter(createdAt)}</td>
        <td>
          {sentFileUrl && (
            <a href={sentFileUrl} target="_blank" rel="noreferrer">
              View
            </a>
          )}
        </td>
        <td>
          {receivedFileUrl && (
            <a href={receivedFileUrl} target="_blank" rel="noreferrer">
              View
            </a>
          )}
        </td>
        <td>{state}</td>
      </tr>
    </>
  );
};

export default AssignmentLinksRow;

import React from "react";
import { Intent, Alert } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { useAdminCaseRevokeMutation } from "types/graphql.types";

interface IProps {
  caseId: string;
  isOpen: boolean;
  caseState: string;
  onRevokeSuccess: () => void;
  onRevokeFailure: () => void;
  onHide: () => void;
}

const ActionModalRevokeCase: React.FC<IProps> = observer(
  ({ caseId, isOpen, onRevokeSuccess, onRevokeFailure, onHide }) => {
    const [caseRevokeMutation] = useAdminCaseRevokeMutation();

    const handleDelete = async () => {
      const resp = await caseRevokeMutation({
        variables: { id: caseId },
      });

      onHide();

      if (resp.errors?.length) {
        onRevokeFailure();
        return;
      }
      onRevokeSuccess();
    };

    return (
      <>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Revoke"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={isOpen}
          onCancel={() => onHide()}
          onConfirm={handleDelete}
        >
          <p>This action will revoke the case. </p>
          <p>
            This action<b> cannot</b> be undone and you will<b> not</b> be able to create a new offer for this case.
          </p>
        </Alert>
      </>
    );
  }
);
export default ActionModalRevokeCase;

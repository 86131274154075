import { Card, Collapse, H5, Icon, Intent } from "@blueprintjs/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { GffFlight } from "types/custom.types";
import { SearchableSelectBox } from "../../../InputFields";
import { SelectSearchOption } from "react-select-search";
import { TextField, DateField } from "process-ui-builder";

import "./style.scss";

interface IProps {
  flight?: GffFlight;
  count: number;
  isOpen: boolean;
  disableCollapse?: boolean;
  onDeleteFlight?: (count: number) => void;
  readOnly: boolean;
  airportOptionData?: SelectSearchOption[];
}

const Flight: React.FunctionComponent<IProps> = ({
  flight,
  count,
  isOpen,
  disableCollapse,
  onDeleteFlight,
  readOnly,
  airportOptionData,
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(isOpen);

  const toggleCollapsible = () => {
    if (!disableCollapse) {
      setIsCollapsibleOpen(!isCollapsibleOpen);
    }
  };

  useEffect(() => {
    setIsCollapsibleOpen(isOpen);
  }, [isOpen]);

  return (
    <Card interactive={true} elevation={1} className="flight">
      {flight && onDeleteFlight && !readOnly && (
        <Icon
          icon="cross"
          intent={Intent.DANGER}
          iconSize={25}
          className={"flight--delete-icon"}
          onClick={() => onDeleteFlight(count)}
        />
      )}

      <H5 onClick={toggleCollapsible}>
        <Icon icon={!isCollapsibleOpen ? "caret-right" : "caret-down"} iconSize={18} />
        {`Flight ${count + 1}`}
      </H5>
      {flight && (
        <Collapse isOpen={!isCollapsibleOpen}>
          <pre onClick={toggleCollapsible}>
            {`Number: ${flight.flight_number}`}
            <br />
            {`Date: ${dayjs(flight.flight_date).format("DD.MM.YYYY")}`}
          </pre>
        </Collapse>
      )}
      <Collapse isOpen={isCollapsibleOpen}>
        <TextField
          config={{
            label: "Flight Number",
            id: `flight_number_${count}`,
            type: "text",
            value: { path: `case.claim.flights[${count}].flight_number` },
            validation: { required: true },
          }}
          readOnly={readOnly}
        />

        <DateField
          config={{
            id: `flight_date_${count}`,
            label: "Flight Date",
            type: "date",
            validation: { required: true },
            value: { path: `case.claim.flights[${count}].flight_date` },
          }}
          readOnly={readOnly}
        />

        {airportOptionData && (
          <>
            <SearchableSelectBox
              fieldOption={{
                id: `departure_airport_${count}`,
                label: "Departure Airport",
                readOnly,
                optionsData: airportOptionData,
                validation: {
                  required: true,
                },
                value: { path: `case.claim.flights[${count}].departure_airport` },
                emptyValue: true,
              }}
            />
            <SearchableSelectBox
              fieldOption={{
                id: `arrival_airport_${count}`,
                label: "Arrival Airport",
                readOnly,
                optionsData: airportOptionData,
                validation: {
                  required: true,
                },
                value: { path: `case.claim.flights[${count}].arrival_airport` },
                emptyValue: true,
              }}
            />
          </>
        )}
      </Collapse>
    </Card>
  );
};
export default Flight;

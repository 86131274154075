import { Button, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { ErrorMessage } from "../../components/shared/ErrorMessage";
import "./style.scss";

interface Props {
  loading: boolean;
  error?: string;
  onSubmit: (email: string, password: string) => void;
}

export const Component: React.FC<Props> = ({ loading, error, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.currentTarget.value);
    },
    [setEmail]
  );
  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );
  const togglePasswordVisibility = useCallback(() => setShowPassword(!showPassword), [
    showPassword,
  ]);
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit(email, password);
    },
    [email, onSubmit, password]
  );

  const lockButton = (
    <Button
      icon={showPassword ? "unlock" : "lock"}
      minimal={true}
      onClick={togglePasswordVisibility}
    />
  );

  return (
    <div className="form-page">
      <div className="form-cont">
        <ErrorMessage className="form-error" error={error} />
        <FormGroup
          helperText="Your official RightNow email address"
          label="Email Address"
          labelFor="email-input"
          labelInfo="(required)"
        >
          <InputGroup
            id="email-input"
            type="email"
            required
            value={email}
            onChange={handleEmailChange}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup
          helperText="If you don't remember your password contact the tech team."
          label="Password"
          labelFor="password-input"
          labelInfo="(required)"
        >
          <InputGroup
            id="password-input"
            type={showPassword ? "text" : "password"}
            required
            value={password}
            onChange={handlePasswordChange}
            rightElement={lockButton}
            disabled={loading}
          />
        </FormGroup>
        <Button
          text="Login"
          intent={Intent.PRIMARY}
          fill
          onClick={handleSubmit}
          disabled={!email || !password}
          loading={loading}
        />
      </div>
    </div>
  );
};

import React from "react";
import { Card, Elevation, H5, HTMLTable, Intent, Tag } from "@blueprintjs/core";
import "../index.scss";
import {formatMoneyVerticals} from "helpers/moneyFormatter";

interface IProps {
  claimAmount: number;
  offerAmount: number;
  company: string;
  rejectionReason?: string;
}

const ActiveOfferDetails: React.FC<IProps> = ({
  claimAmount,
  offerAmount,
  company,
  rejectionReason,
}) => {
  return (
    <Card elevation={Elevation.ONE} className="base-card">
      <H5>Offer</H5>
      <HTMLTable interactive>
        <tbody>
          <tr>
            <td className="title">Claim Amount:</td>
            <td>{formatMoneyVerticals(claimAmount)}</td>
          </tr>
          <tr>
            <td className="title">Offer Amount:</td>
            <td>{formatMoneyVerticals(offerAmount)}</td>
          </tr>
          <tr>
            <td className="title">Company:</td>
            <td>{company}</td>
          </tr>
          {rejectionReason && (
            <tr>
              <td className="title">Refusal Reason:</td>
              <td>
                <Tag intent={Intent.WARNING}>{rejectionReason}</Tag>
              </td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

export default ActiveOfferDetails;

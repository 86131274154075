import { Button, Intent, Spinner } from "@blueprintjs/core";
import get from "lodash.get";
import set from "lodash/set";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ValueContext } from "process-ui-builder";
import React, { useContext, useMemo, useState } from "react";
import { GffFlight } from "types/custom.types";
import { Product_Enum, useGetGffAirportQuery } from "types/graphql.types";
import { READ_ONLY_VERTICALS } from "../../../constants";
import { MAX_FLIGHT_COUNT, PLACEHOLDER_FLIGHT } from "../const";
import Flight from "./Flight";
import { checkValidFlightData } from "./utils";

const Flights: React.FunctionComponent = () => {
  const valueContext = useContext(ValueContext);

  const flights: GffFlight[] = get(valueContext, "case.claim.flights");
  const readOnly = get(valueContext, "flow.read_only_group.claim_data");
  const isProductReadOnly = READ_ONLY_VERTICALS.includes(Product_Enum.Gff);

  const [flightCount, setFlightCount] = useState<number>(flights.length);
  const { data: airportData, loading } = useGetGffAirportQuery();

  const addAnotherFlight = action(() => {
    runInAction(() => {
      const lastFlight: GffFlight | undefined = flights[flights.length - 1];
      if (flights.length === flightCount && checkValidFlightData(lastFlight)) {
        const updatedFlights = [...flights];
        PLACEHOLDER_FLIGHT.departure_airport = lastFlight.arrival_airport;
        updatedFlights.push(PLACEHOLDER_FLIGHT);
        setFlightCount(flights.length + 1);
        set(valueContext, "case.claim.flights", updatedFlights);
      } else {
        alert(`Flight details are required, Check Flight ${flightCount ? flightCount : 1}`);
      }
    });
  });

  const getFlight = useMemo(() => {
    const deleteFlight = action((count: number) => {
      runInAction(() => {
        if (flights.length <= 1) {
          alert("Minimum one flight is required for claim.");
          return;
        }
        const flights1 = [...flights];
        flights1.splice(count, 1);
        set(valueContext, "case.claim.flights", flights1);
        setFlightCount(flightCount - 1);
      });
    });

    const flightArray = airportData
      ? flights.map((flight, index) => (
          <Flight
            flight={flight}
            count={index}
            readOnly={!!readOnly || isProductReadOnly}
            isOpen={index + 1 === flightCount}
            onDeleteFlight={deleteFlight}
            key={index}
            airportOptionData={airportData.gff_airport}
          />
        ))
      : [];

    if (flights.length === 0 || flights.length < flightCount) {
      const updatedFlights = [...flights];
      updatedFlights.push(PLACEHOLDER_FLIGHT);
      setFlightCount(flights.length + 1);
      set(valueContext, "case.claim.flights", updatedFlights);
    }

    return flightArray;
  }, [flightCount, readOnly, flights, valueContext, airportData, isProductReadOnly]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {getFlight}
      {!readOnly && !isProductReadOnly && flights.length <= MAX_FLIGHT_COUNT - 1 && (
        <Button
          icon={"add"}
          text={"Add another Flight"}
          intent={Intent.WARNING}
          onClick={addAnotherFlight}
        />
      )}
    </>
  );
};
export default observer(Flights);

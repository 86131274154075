import { FILE_EXTENSION_REGEX } from "./constants";
import { TSelectOptions } from "./types";

export const getOptionsWithEmptyOption = (
  options?: TSelectOptions,
  label: string = "---select---"
) => {
  const emptyValue: TSelectOptions = [{ label, value: "" }];

  if (options) return [...emptyValue, ...options];
  return emptyValue;
};

export const getFileExtension = (fileName: string) => {
  const fileExtensionArray = FILE_EXTENSION_REGEX.exec(fileName);
  return fileExtensionArray?.length ? fileExtensionArray[0].replace(".", "") : "";
};

import React, { useState } from "react";
import { Button, TextArea, Intent } from "@blueprintjs/core";
import { useAdminCaseCommentAddMutation, useAdminUpdateCommentMutation } from "types/graphql.types";
import { successToaster } from "helpers/toaster";
import "./style.scss";

interface IProps {
  caseId?: string;
  commentId?: string;
  comment: string;
  isCommentAuthor?: boolean;
  onRefetch: () => void;
  onCloseEditor?: () => void;
}

const CommentEditor: React.FC<IProps> = ({
  caseId,
  commentId,
  comment,
  isCommentAuthor,
  onRefetch,
  onCloseEditor,
}) => {
  const [editedComment, setEditedComment] = useState<string>(comment);
  const [isSendLoading, setIsSendLoading] = useState<boolean>(false);
  const [addCommentMutation] = useAdminCaseCommentAddMutation();
  const [updateCommentMutation] = useAdminUpdateCommentMutation();

  const onCommentTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedComment(event.target.value);
  };

  const onSend = () => {
    if (caseId && editedComment.trim()) {
      // Save new comment
      setIsSendLoading(true);
      addCommentMutation({
        variables: {
          caseId,
          comment: editedComment.trim(),
        },
      }).then((resp) => {
        if (resp.errors?.length) {
          setIsSendLoading(false);
          return;
        }
        successToaster("Comment added successfully.");
        onRefetch();
        setIsSendLoading(false);
        setEditedComment("");
      });
    } else {
      // Update comment
      if (comment && commentId && editedComment.trim() !== comment && isCommentAuthor) {
        setIsSendLoading(true);
        updateCommentMutation({
          variables: {
            input: {
              id: commentId,
              comment: editedComment.trim(),
            },
          },
        }).then((resp) => {
          if (resp.errors?.length) {
            setIsSendLoading(false);
            return;
          }
          successToaster("The Comment was updated.");
          onRefetch();
          setIsSendLoading(false);
          setEditedComment("");
          if (onCloseEditor) onCloseEditor();
        });
      }
    }
  };

  const onCancel = () => {
    setEditedComment(comment || "");
    if (onCloseEditor) onCloseEditor();
  };

  return (
    <div className="add-comment-form">
      <TextArea
        growVertically={false}
        large={true}
        intent={Intent.PRIMARY}
        onChange={onCommentTextChange}
        value={editedComment}
        className="add-comment-textarea"
        placeholder="Type your message here..."
      />
      <div className="button-flex-column">
        <Button
          className="comment-button"
          intent={"primary"}
          onClick={onSend}
          disabled={!editedComment.trim()}
          loading={isSendLoading}
        >
          Send
        </Button>
        <Button className="comment-button" intent={"none"} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CommentEditor;

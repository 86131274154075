import { ValueContext } from "process-ui-builder";
import { isHandler } from "process-ui-builder/config/guards";
import { OnSubmitSchema } from "process-ui-builder/config/types";
import React from "react";
import { useContext } from "react";
import isFunction from "lodash/isFunction";
import { Button, Intent, Callout } from "@blueprintjs/core";

interface Props {
  config?: OnSubmitSchema;
}

export const OnSubmit: React.FC<Props> = ({ config }) => {
  const valueContext = useContext(ValueContext);

  if (isHandler(config)) {
    let handler = valueContext.handlers[config.handler];
    if (!!handler && !isFunction(handler)) {
      throw new Error(`Handler ${config.handler} is not callable`);
    }
    if (!handler) {
      handler = () => alert(`Handler ${config.handler} was not found in the context`);
    }

    return (
      <div className="panel--footer">
        <Button
          text={config.description}
          intent={Intent.PRIMARY}
          onClick={handler}
          disabled={config.disabled}
        />
      </div>
    );
  } else if (config?.type === "customComponent" && config.component) {
    let customComp = valueContext.customComponents[config.component];
    if (customComp === undefined) {
      customComp = (
        <Callout intent={Intent.DANGER}>{config.component} is not found in the context</Callout>
      );
    }
    return <div className="panel--footer">{customComp}</div>;
  }

  return null;
};

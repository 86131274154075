import {
  Card,
  Classes,
  Divider,
  Elevation,
  H5,
  H6,
  Intent,
  Tag,
  Menu,
  MenuItem,
  Button,
} from "@blueprintjs/core";
import moment from "moment";
import React, { useState } from "react";
import "./style.scss";
import { observer } from "mobx-react-lite";
import { Popover2 } from "@blueprintjs/popover2";
import { READ_ONLY_VERTICALS } from "../../constants";
import ActionModalDeleteCase from "components/ActionModalDeleteCase";
import { successToaster, warningToaster } from "helpers/toaster";
import { humanizeString } from "helpers/humanize-string";
import CaseCommentsList from "components/CaseCommentsList";
import ActionModalGdprDelete from "components/ActionModalGdprDelete";
import ActionModalRevokeCase from "components/ActionModalRevokeCase";
import { disableRevokeCaseButton, disableGdprDeleteButton, disableCaseDeleteButton } from "./utils";
import { Product_Enum } from "types/graphql.types";

interface IProps {
  page: string;
  aliasId: string;
  fullName: string;
  caseState: string;
  offerState?: string;
  createdAt: string;
  caseId: string;
  refetch?: () => void;
  hasActiveOffer?: boolean;
  onNextCase?: () => void;
  isCaseAgentSupervisor: boolean;
  commentCount: number;
  verticalWithoutComments?: boolean;
  acceptedAt?: string;
  productId: Product_Enum;
}

const Subject: React.FC<{ text: string }> = ({ text }) => (
  <H6 className={Classes.UI_TEXT}>{text}</H6>
);

const Header: React.FunctionComponent<IProps> = observer(
  ({
    page,
    fullName,
    caseState,
    offerState,
    createdAt,
    aliasId,
    caseId,
    refetch,
    hasActiveOffer,
    onNextCase,
    isCaseAgentSupervisor,
    acceptedAt,
    commentCount,
    verticalWithoutComments,
    productId,
  }) => {
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
    const [isGdprDeleteAlertOpen, setIsGdprDeleteAlertOpen] = useState<boolean>(false);
    const [isRevokeAlertOpen, setIsRevokeAlertOpen] = useState<boolean>(false);
    const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);
    const [currentCommentCount, setCurrentCommentCount] = useState<number | null>(commentCount);
    const isProductReadOnly = READ_ONLY_VERTICALS.includes(productId);

    const onDeleteCase = () => {
      setIsDeleteAlertOpen(true);
    };

    const onRevokeCase = () => {
      setIsRevokeAlertOpen(true);
    };

    const onDeleteGdprData = () => {
      setIsGdprDeleteAlertOpen(true);
    };

    const deleteCaseProps = {
      caseId,
      isOpen: isDeleteAlertOpen,
      onHide: () => setIsDeleteAlertOpen(false),
      onDeleteSuccess: () => {
        successToaster("The case was delete successfully");
        refetch && refetch();
      },
    };

    const deleteGdprDataProps = {
      caseId,
      isOpen: isGdprDeleteAlertOpen,
      onHide: () => setIsGdprDeleteAlertOpen(false),
      onDeleteSuccess: () => {
        successToaster("The personal data was delete successfully");
        refetch && refetch();
      },
      onDeleteFailure: () => {
        warningToaster("The personal data could not be deleted");
        refetch && refetch();
      },
      productId,
    };

    const revokeCaseProps = {
      caseState: caseState,
      caseId,
      isOpen: isRevokeAlertOpen,
      onHide: () => setIsRevokeAlertOpen(false),
      onRevokeSuccess: () => {
        successToaster("The case was successfully revoked");
        refetch && refetch();
      },
      onRevokeFailure: () => {
        warningToaster("The case could not be revoked");
        refetch && refetch();
      },
    };

    const handleShowComments = () => setIsCommentsDrawerOpen(true);

    const handleHideComments = (newCommentCount: number | null) => {
      setIsCommentsDrawerOpen(false);
      setCurrentCommentCount(newCommentCount);
    };

    return (
      <>
        <Card elevation={Elevation.ONE} className="gdpr-header">
          <div className="container">
            <div className="item">
              <H5>
                {page} / #{aliasId}
              </H5>
              <div>{fullName}</div>
            </div>
            <Divider />
            <div className="item">
              <Subject text="Case status" />
              <div>
                <Tag intent={Intent.SUCCESS} large={true} round={true}>
                  {humanizeString(caseState)}
                </Tag>
              </div>
            </div>
            <Divider />
            {offerState && (
              <>
                <div className="item">
                  <Subject text="Offer status" />
                  <div>
                    <Tag intent={Intent.SUCCESS} large={true} round={true}>
                      {humanizeString(offerState)}
                    </Tag>
                  </div>
                </div>
                <Divider />
              </>
            )}
            <div className="item">
              <Subject text="Time Since Submission" />
              <div>
                <b>{moment.utc(createdAt).local().fromNow()}</b>
              </div>
            </div>
            {page === "Case" && (
              <>
                <div className="item align-right button-wrapper">
                  {!verticalWithoutComments && (
                    <Button
                      text={"Comments".concat(
                        currentCommentCount ? ` (${currentCommentCount})` : ""
                      )}
                      intent={currentCommentCount ? Intent.WARNING : Intent.NONE}
                      large={true}
                      onClick={handleShowComments}
                    />
                  )}
                  <Popover2
                    content={
                      <Menu>
                        <MenuItem
                          icon="delete"
                          text="Delete Case"
                          intent={Intent.DANGER}
                          onClick={onDeleteCase}
                          disabled={
                            isProductReadOnly || disableCaseDeleteButton(caseState, hasActiveOffer)
                          }
                        />
                        {isCaseAgentSupervisor && (
                          <MenuItem
                            icon="undo"
                            text="Revoke Case"
                            intent={Intent.DANGER}
                            onClick={onRevokeCase}
                            disabled={
                              isProductReadOnly || disableRevokeCaseButton(offerState, acceptedAt)
                            }
                          />
                        )}
                        {isCaseAgentSupervisor && caseState !== "GDPR_DELETED" && (
                          <MenuItem
                            icon="delete"
                            text="GDPR Delete"
                            intent={Intent.DANGER}
                            onClick={onDeleteGdprData}
                            disabled={disableGdprDeleteButton(offerState)}
                          />
                        )}
                      </Menu>
                    }
                    placement="bottom-end"
                  >
                    <Button icon="chevron-down" large={true} />
                  </Popover2>
                </div>
                <Divider />
                <div className="item button-wrapper">
                  <Button
                    text={"Next case"}
                    rightIcon="chevron-right"
                    intent={Intent.SUCCESS}
                    onClick={onNextCase}
                    large={true}
                  />
                </div>
              </>
            )}
          </div>
          <ActionModalDeleteCase {...deleteCaseProps} />
          <ActionModalGdprDelete {...deleteGdprDataProps} />
          <ActionModalRevokeCase {...revokeCaseProps} />
        </Card>

        <CaseCommentsList
          caseId={caseId}
          showCommentsDrawer={isCommentsDrawerOpen}
          handleHideComments={handleHideComments}
        />
      </>
    );
  }
);

export default Header;

import React from "react";
import { Intent, Alert } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { useAdminHandleGdprDeletionRequestMutation, Product_Enum } from "types/graphql.types";

interface IProps {
  caseId: string;
  isOpen: boolean;
  onDeleteSuccess: () => void;
  onDeleteFailure: () => void;
  onHide: () => void;
  productId: Product_Enum;
}

const ActionModalGdprDelete: React.FC<IProps> = observer(
  ({ caseId, isOpen, onDeleteSuccess, onDeleteFailure, onHide, productId }) => {
    const [dataDeleteMutation, { loading }] = useAdminHandleGdprDeletionRequestMutation();

    const variables = {
      input: {
        configName: `${productId.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            id: caseId,
          },
        },
      },
    };

    const handleDelete = async () => {
      const resp = await dataDeleteMutation({
        variables,
      });

      onHide();

      if (resp.errors?.length) {
        onDeleteFailure();
        return;
      }
      onDeleteSuccess();
    };

    return (
      <>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={isOpen}
          onCancel={() => onHide()}
          onConfirm={handleDelete}
          loading={loading}
        >
          <p>This action will completely delete the customer data.</p>
          <p>
            This action<b> cannot</b> be undone and the customer data<b> cannot</b> be retrieved.
          </p>
        </Alert>
      </>
    );
  }
);
export default ActionModalGdprDelete;

import React, { useEffect, useMemo, useState } from "react";

import {
  Product_Enum,
  useAdminDeleteDocumentsMutation,
  useAdminRequestDocumentMutation,
  useGetDocumentCategoriesQuery,
  useAdminUpdateDocumentMutation,
  useAdminUpdateDocumentOrderMutation,
  useAdminGetUploadDocsSignUrlMutation,
  useGetDocumentsQuery,
  useAdminDownloadDocumentMutation,
  useAdminGetSignedUrlsQuery,
  AdminGetSignedUrlsOutput,
  useAdminAddDocumentsMutation,
} from "types/graphql.types";

import { DocumentManager, DocumentManagerContext } from "document-manager";
import { TDocument } from "document-manager/types";
import { graphQlErrorToaster, successToaster } from "helpers/toaster";
import { DOCUMENT_REQUEST_REASON } from "./constants";
import { uploadFileToS3 } from "helpers/utils";
import { READ_ONLY_VERTICALS } from "../../constants";

interface IProps {
  allowRequestDocument: boolean;
  allowUpload: boolean;
  refetchCase: () => Promise<void>;
  caseId: string;
  productId: Product_Enum;
  isCaseRefetchEnabled: boolean;
  aliasId: string;
}

const CaseDocumentList: React.FunctionComponent<IProps> = ({
  allowRequestDocument,
  allowUpload,
  refetchCase,
  caseId,
  productId,
  isCaseRefetchEnabled,
  aliasId,
}) => {
  const [deleteDocumentsMutation] = useAdminDeleteDocumentsMutation();
  const {
    data: documentCategoryData,
    loading: loadingDocumentCategories,
  } = useGetDocumentCategoriesQuery({
    variables: { vertical: productId },
  });
  const {
    data: documentsData,
    loading: loadingDocuments,
    refetch: refetchDocuments,
  } = useGetDocumentsQuery({
    variables: { id: caseId },
  });

  const { refetch } = useAdminGetSignedUrlsQuery({
    skip: true,
  });

  const [requestDocumentMutation] = useAdminRequestDocumentMutation();
  const [updateDocumentMutation] = useAdminUpdateDocumentMutation();
  const [updateDocumentOrderMutation] = useAdminUpdateDocumentOrderMutation();

  const [adminGetUploadDocsSignUrlMutation] = useAdminGetUploadDocsSignUrlMutation();
  const [adminDownloadDocumentMutation] = useAdminDownloadDocumentMutation();
  const [adminAddDocumentsMutation] = useAdminAddDocumentsMutation();

  const [signedDocuments, setSignedDocuments] = useState<AdminGetSignedUrlsOutput["documents"]>([]);

  useEffect(() => {
    if (documentsData?.documents.length) {
      const input = {
        download: true,
        documents: documentsData?.documents.map((doc) => {
          return { key: doc.fileName, type: "K1", displayFileName: doc.displayFileName };
        }),
      };
      refetch({ input }).then(({ data }) => {
        setSignedDocuments(data.admin_get_signed_urls?.documents || []);
      });
    }
  }, [documentsData, refetch]);

  const getDocuments = useMemo(() => {
    if (documentsData && documentsData.documents.length === signedDocuments.length) {
      return documentsData.documents.map((document) => {
        const { downloadUrl, previewUrl } = signedDocuments.find(
          (d: { key: string }) => d.key === document.fileName
        );

        return ({ ...document, downloadUrl, previewUrl } as unknown) as TDocument;
      });
    }
  }, [documentsData, signedDocuments]);

  if (
    loadingDocumentCategories ||
    loadingDocuments ||
    !documentCategoryData?.documentCategories.length
  ) {
    return <></>;
  }

  const handleDelete = async (documentId: string) => {
    const variables = {
      input: {
        configName: `${productId.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            id: caseId,
            documents: [{ id: documentId }],
          },
        },
      },
    };

    deleteDocumentsMutation({
      variables,
    }).then((resp) => {
      if (resp.errors?.length) {
        return;
      }
      if (getDocuments?.length === 1 && isCaseRefetchEnabled) {
        refetchCase();
      }
      refetchDocuments();
    });
  };

  const downloadZipFile = async (lawSuitOnly: boolean) => {
    if (getDocuments) {
      const documents = getDocuments.filter((document) => {
        return lawSuitOnly ? document.addToLawsuit : true;
      });

      const documentsObj = documents.map((document) => {
        return { type: "K1", key: document.fileName, fileName: document.displayFileName };
      });
      const input = {
        input: {
          configName: `${productId.toLowerCase()}-claim-purchasing`,
          input: {
            zipFile: {
              fileName: `${aliasId}_${
                lawSuitOnly ? "all_lawsuit_relevant_files" : "all_files"
              }.zip`,
              documents: documentsObj,
            },
          },
        },
      };
      adminDownloadDocumentMutation({
        variables: { ...input },
      }).then((resp) => {
        window.open(resp.data?.admin_download_documents?.url, "_blank");
      });
    }
  };

  const requestDocumentHandler = async (
    documents: string[],
    reason: string,
    otherReason?: string
  ) => {
    const input = {
      input: {
        configName: `${productId.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            requestedDocuments: documents,
            id: caseId,
            documentRequestReason: reason,
            ...(otherReason && { otherDocumentReason: otherReason }),
          },
        },
      },
    };
    requestDocumentMutation({
      variables: { ...input },
    }).then((resp) => {
      handleResponse(!!resp.errors?.length, "Document requested successfully");
      // No need to check if it is enabled, case state gets updated, so case refetch is mandatory to show the updated state
      refetchCase();
    });
  };

  const updateDocumentHandler = async (document: {
    id: string;
    addToLawsuit: boolean;
    documentType: string;
    displayFileName: string;
  }) => {
    const input = {
      input: {
        configName: `${productId.toLowerCase()}-claim-purchasing`,
        input: {
          document,
        },
      },
    };
    updateDocumentMutation({
      variables: { ...input },
    }).then((resp) => {
      handleResponse(!!resp.errors?.length, "Document Updated successfully");
    });
  };

  const updateDocumentOrderHandler = async (documents: {
    categoryId: string;
    sortOrder: Array<string>;
  }) => {
    const input = {
      input: {
        configName: `${productId.toLowerCase()}-claim-purchasing`,
        input: {
          documents,
        },
      },
    };
    updateDocumentOrderMutation({
      variables: { ...input },
    }).then((resp) => {
      handleResponse(!!resp.errors?.length, "Document ordered successfully");
    });
  };

  const handleResponse = (error: boolean, message: string) => {
    if (error) {
      return;
    }
    successToaster(message);
    refetchDocuments();
  };

  const handleAddCaseIdToDocuments = (
    documents: { id: string; contentType: string }[],
    errorFileNames: string[]
  ) => {
    if (documents.length && caseId) {
      const input = {
        input: {
          configName: `${productId.toLowerCase()}-claim-purchasing`,
          input: {
            case: {
              id: caseId,
              documents,
            },
          },
        },
      };

      adminAddDocumentsMutation({
        variables: { ...input },
      }).then((res) => {
        if (res?.errors) {
          return;
        } else {
          if (errorFileNames.length) {
            graphQlErrorToaster(
              `There was problem uploading  ${errorFileNames
                .join(", ")
                .replace(/, ((?:.(?!, ))+)$/, " and $1")}`
            );
            return;
          }
          successToaster("Documents uploaded successfully.");
          refetchDocuments();

          if (isCaseRefetchEnabled) {
            refetchCase();
          }
        }
      });
    } else if (errorFileNames.length) {
      graphQlErrorToaster("Uploading Failed! Please try again.");
    }
  };

  const uploadFilesHandler = async (filesList: { file: File; category: string }[]) => {
    const documents: { id: string; contentType: string }[] = [];
    const errorFiles: string[] = [];

    for (let index = 0; index < filesList.length; index++) {
      const fileObj = filesList[index];
      await adminGetUploadDocsSignUrlMutation({
        variables: {
          docType: fileObj.category,
          name: fileObj.file.name,
          contentType: fileObj.file.type,
        },
      })
        .then(async (res) => {
          const docsSignUrl = res.data && res.data.docs_sign_url;
          if (docsSignUrl) {
            const documentId = docsSignUrl.id;
            const isUploaded = await uploadFileToS3(docsSignUrl, fileObj.file);
            if (isUploaded === true) {
              documents.push({ id: documentId, contentType: fileObj.file.type });
            } else {
              errorFiles.push(fileObj.file.name);
            }
          }
        })
        .catch((error) => {
          errorFiles.push(fileObj.file.name);
        });
    }
    handleAddCaseIdToDocuments(documents, errorFiles);
  };

  return (
    <DocumentManagerContext.Provider
      value={{
        documents: getDocuments,
        allowUpload: allowUpload ,
        allowDelete: !READ_ONLY_VERTICALS.includes(productId),
        allowRequestDocument:
          allowRequestDocument &&
          !!DOCUMENT_REQUEST_REASON[productId]?.length,
        categories: documentCategoryData.documentCategories,
        requestDocumentReasons: DOCUMENT_REQUEST_REASON[productId],
        handleDelete,
        requestDocumentHandler,
        updateDocumentHandler,
        updateDocumentOrderHandler,
        uploadFilesHandler,
        downloadZipFile,
      }}
    >
      <DocumentManager />
    </DocumentManagerContext.Provider>
  );
};
export default CaseDocumentList;

export const checkValidPersonData = (person: any) => {
  return (
    person &&
    person["name"] &&
    (person["tariffs"] as string[]).length &&
    (person["tariffs"] as string[])[0] &&
    person["insured_since"] &&
    person["insurance_number"]
  );
};

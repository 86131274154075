import { Intent } from "@blueprintjs/core";
import InfoCard, { IProps as IInfoCardProps } from "components/InfoCard";
import React from "react";
import { useFitMembershipSubmissionCountQuery } from "types/graphql.types";

interface IProps {
  membershipNumber: string;
  vertical: string;
}

const DuplicateSubmissionCard: React.FC<IProps> = ({ membershipNumber, vertical }) => {
  const { data } = useFitMembershipSubmissionCountQuery({
    variables: {
      membershipNumber,
    },
  });

  const currentCount = data?.case?.aggregate?.count;
  const show = currentCount ? currentCount > 1 : false;

  const infoCardProps: IInfoCardProps = {
    message: (
      <>
        There are multiple submissions with this membership number{" "}
        <strong>{membershipNumber}</strong>!
      </>
    ),
    icon: "delete",
    intent: Intent.DANGER,
    buttons: [
      {
        text: "Show Cases",
        link: `/cases/?vertical=${vertical}&fit_claim.membership_number=${membershipNumber}`,
      },
    ],
  };

  return <>{show ? <InfoCard {...infoCardProps} /> : null}</>;
};

export default DuplicateSubmissionCard;

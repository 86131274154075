import {
  Order_By,
  Maybe,
  Ps_Debtor_Order_By,
  Ls_Lawsuit_Court_Order_By,
  Ls_Lawsuit_Lawyer_Order_By,
} from "types/graphql.types";

export function generateOrderByVariableForLawsuits({
  sortColumn,
  sortOrder,
}: {
  sortColumn?: string;
  sortOrder?: Order_By;
  product?: string;
}):
  | { alias_id: Maybe<Order_By> }
  | { vertical: Maybe<Order_By> }
  | { debtor: Maybe<Ps_Debtor_Order_By> }
  | { court: Maybe<Ls_Lawsuit_Court_Order_By> }
  | { lawyer: Maybe<Ls_Lawsuit_Lawyer_Order_By> }
  | { claim_amount: Maybe<Order_By> }
  | { status: Maybe<Order_By> } {
  switch (sortColumn) {
    case "alias_id":
      return { alias_id: sortOrder || Order_By.Desc };
    case "vertical":
      return { vertical: sortOrder || Order_By.Desc };
    case "debtor":
      return { debtor: { name: sortOrder || Order_By.Desc } };
    case "court":
      return { court: { name: sortOrder || Order_By.Desc } };
    case "lawyer":
      return { lawyer: { company_name: sortOrder || Order_By.Desc } };
    case "claim_amount":
      return { claim_amount: sortOrder || Order_By.Desc };
    case "status":
      return { status: sortOrder || Order_By.Desc };
    default:
      return { alias_id: sortOrder || Order_By.Desc };
  }
}

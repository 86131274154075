import { ITreeNode } from "@blueprintjs/core";
import { URL_FINANCE_CUSTOMER_PAYOUTS } from "../../../constants";
import { NodeData } from "./node-data";
import { IS_EXPANDED } from ".";

export const FINANCE_SERVICES_MENU_ID = "finance-services-menu";

export enum PaymentStatus {
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  PAYMENT_APPROVED = "PAYMENT_APPROVED",
  PAYMENT_REJECTED = "PAYMENT_REJECTED",
  TRANSACTION_PENDING = "TRANSACTION_PENDING",
  TRANSACTION_REJECTED = "TRANSACTION_REJECTED",
  TRANSACTION_ACCEPTED = "TRANSACTION_ACCEPTED",
}

export const getFinanceServiceNode = (): ITreeNode<NodeData> => {
  const savedIsExpanded = localStorage.getItem(`${FINANCE_SERVICES_MENU_ID}${IS_EXPANDED}`);
  const isExpanded = savedIsExpanded ? savedIsExpanded === "true" : true;

  return {
    id: FINANCE_SERVICES_MENU_ID,
    isExpanded: isExpanded,
    label: <strong>Finance Service</strong>,
    childNodes: [
      {
        id: `${FINANCE_SERVICES_MENU_ID}__customer-payouts`,
        icon: "dollar",
        label: "Customer Payouts",
        hasCaret: false,
        isExpanded: true,
        childNodes: [
          {
            id: `${FINANCE_SERVICES_MENU_ID}_all`,
            label: "All",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}`,
            },
          },
          {
            id: `${FINANCE_SERVICES_MENU_ID}_waiting_for_approval`,
            label: "Waiting for approval",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}?state=${PaymentStatus.WAITING_FOR_APPROVAL}`,
            },
          },
          {
            id: `${FINANCE_SERVICES_MENU_ID}_payment_approved`,
            label: "Payment Approved",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}?state=${PaymentStatus.PAYMENT_APPROVED}`,
            },
          },
          {
            id: `${FINANCE_SERVICES_MENU_ID}_payment_initiated`,
            label: "Payment Initiated",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}?state=${PaymentStatus.TRANSACTION_PENDING}`,
            },
          },
          {
            id: `${FINANCE_SERVICES_MENU_ID}_paid`,
            label: "Paid",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}?state=${PaymentStatus.TRANSACTION_ACCEPTED}`,
            },
          },
          {
            id: `${FINANCE_SERVICES_MENU_ID}_rejected`,
            label: "Rejected",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}?state=${PaymentStatus.PAYMENT_REJECTED}`,
            },
          },
          {
            id: `${FINANCE_SERVICES_MENU_ID}_failed`,
            label: "Failed",
            hasCaret: false,
            nodeData: {
              link: `${URL_FINANCE_CUSTOMER_PAYOUTS}?state=${PaymentStatus.TRANSACTION_REJECTED}`,
            },
          },
        ],
      },
    ],
  };
};

import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: ["auth", "roles"],
  },
  rootReducer
);

const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeof window === "object" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

// @ts-ignore: There is a type error in react-persist lib
export const persistor = persistStore(store);

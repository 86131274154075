import moment from "moment";

export async function uploadFileToS3(presignedPostData: any, file: File) {
  const formData = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.append(key, presignedPostData.fields[key]);
  });
  formData.append("file", file);

  const response = await fetch(presignedPostData.url, {
    method: "POST",
    body: formData,
  });
  return response.status === 204;
}

export const isAbsoluteUrl = (url: string) => {
  const pat = /^https?:\/\//i;
  if (pat.test(url)) {
    return true;
  }
  return false;
};

export function humanizeDate(date: string) {
  return moment.utc(date).local().fromNow();
}

export const isValidString = (word: unknown): boolean => {
  return typeof word === "string" && word.trim().length > 0;
};

export const isValidDate = (date: string): boolean => {
  return !isNaN(Date.parse(date));
};

export const matchStrings = (first: string, second: string): boolean => {
  return first.toLowerCase().trim() === second.toLowerCase().trim();
};

import { combineReducers } from "redux";
import auth from "./auth";
import roles from "./roles";
import ui from "./ui";

const rootReducer = combineReducers({
  auth,
  roles,
  ui,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

import { Button, ButtonGroup } from "@blueprintjs/core";
import { paginationCalculator } from "pagination-calculator";
import React from "react";

interface Props {
  paginator: ReturnType<typeof paginationCalculator>;
  onPageChange: (page: number) => void;
}

export const Pages: React.FC<Props> = ({ paginator, onPageChange }) => {
  return (
    <div className="page-numbers">
      <ButtonGroup>
        <Button
          disabled={paginator.previous === false}
          rightIcon="chevron-left"
          onClick={() => onPageChange(paginator.previous || 0)}
        />
        {paginator.pages.map((p, idx) => (
          <Button
            key={idx}
            text={p}
            disabled={p === "..."}
            active={p === paginator.current}
            onClick={() => onPageChange(p as number)}
          />
        ))}
        <Button
          disabled={paginator.next === false}
          rightIcon="chevron-right"
          onClick={() => onPageChange(paginator.next || 0)}
        />
      </ButtonGroup>
    </div>
  );
};

import { RouterHistory } from "@sentry/react/dist/reactrouter";
import { successToaster } from "helpers/toaster";

export const handleOnNextCase = (
  oldId: string,
  error: boolean,
  product: string,
  setShowLoader: (value: boolean) => void,
  refetch: () => void,
  history: RouterHistory,
  nextCase?: string
) => {
  setShowLoader(true);

  if (error) {
    refetch();
    setShowLoader(false);
    return;
  }
  if (!nextCase) {
    refetch();
    setShowLoader(false);
    successToaster("Congrats! There are no more pending cases to process. 😎");
    return;
  }
  if (nextCase) {
    const url = "/cases/";
    history.push(`${url}${nextCase}`);
    setShowLoader(false);
    successToaster("Next Case loaded.", {
      text: "View Previous",
      onClick: () => history.push(`${url}/${oldId}`),
    });
  }
};

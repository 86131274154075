import React from "react";
import { Button, FormGroup, HTMLSelect, Intent } from "@blueprintjs/core";

import { TSelectOptions } from "document-manager/types";

import "../styles.scss";

type TProps = {
  file: {
    file: File;
    category: string;
  };
  categories: TSelectOptions;
  changeCategory: (index: number, category: string) => void;
  deleteFile: (index: number) => void;
  fileIndex: number;
};

export const DocumentRow: React.FC<TProps> = ({
  file,
  categories,
  changeCategory,
  fileIndex,
  deleteFile,
}) => {
  return (
    <tr className={"documents-row"}>
      <td className={"document-name"}>{file.file.name}</td>
      <td>
        <FormGroup className={"document-category"}>
          <HTMLSelect
            fill
            options={categories}
            id={`category-${file.file.name}`}
            value={file.category}
            onChange={(e) => {
              changeCategory(fileIndex, e.target.value);
            }}
          />
        </FormGroup>
      </td>

      <td className={"document-delete"}>
        <Button icon="trash" intent={Intent.DANGER} onClick={() => deleteFile(fileIndex)} />
      </td>
    </tr>
  );
};

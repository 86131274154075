import React, { useState, useContext } from "react";
import { Popover2 } from "@blueprintjs/popover2";
import { Menu, Icon } from "@blueprintjs/core";

import { DeleteOption, DeleteConfirmBox } from "./delete";
import DownloadOption from "./DownloadOption";
import { TCommonPopup } from "document-manager/types";
import { EditOption } from "./EditOption";
import { DocumentManagerContext } from "document-manager/DocumentManager";

import "./styles.scss";

type TProps = {
  documentId: string;
  displayFileName: string;
  downloadUrl: string;
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
};

export const Actions: React.FC<TProps> = ({
  documentId,
  displayFileName,
  downloadUrl,
  setDocumentIdAndOpenPopUpBox,
}) => {
  const [isDeleteConfirmBoxOpen, setIsDeleteConfirmBoxOpen] = useState<boolean>(false);
  const { allowDelete } = useContext(DocumentManagerContext);

  return (
    <>
      <Popover2
        content={
          <Menu>
            <EditOption
              documentId={documentId}
              setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
            />
            <DownloadOption url={downloadUrl} />
            {allowDelete && (
              <>
                <hr className="divider" />
                <DeleteOption action={() => setIsDeleteConfirmBoxOpen(true)} />
              </>
            )}
          </Menu>
        }
      >
        <div className="action-icon-wrapper">
          <Icon icon="more" />
        </div>
      </Popover2>
      <DeleteConfirmBox
        documentId={documentId}
        displayFileName={displayFileName}
        isOpen={isDeleteConfirmBoxOpen}
        onHide={() => setIsDeleteConfirmBoxOpen(false)}
      />
    </>
  );
};

import React from "react";
import {
  Button,
  Checkbox,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  Position,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import "./style.scss";

interface IFilterOptionInputType {
  name: string;
  type: string;
  placeholder: string;
}
interface IFilterOptionDropdownType {
  name: string;
  type: string;
  defaultValue: string;
  options: Array<{
    label: string;
    value: string;
  }>;
}

interface IProps {
  options: Array<IFilterOptionInputType | IFilterOptionDropdownType>;
  onFilter: Function;
}

const Filter: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<any>({});
  const [enabledFilters, setEnabledFilters] = React.useState<any>({});

  const handleOnCheckBoxChange = (option: IFilterOptionInputType | IFilterOptionDropdownType) => {
    if (option.type === "dropdown" && !filters[option.name]) {
      const inputOption = option as IFilterOptionDropdownType;
      handleChange({ name: inputOption.name, value: inputOption.defaultValue });
    }
    setEnabledFilters({
      ...enabledFilters,
      [option.name]: !enabledFilters[option.name],
    });
  };

  const handleChange = ({ name, value }: { name: string; value: string }) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleSubmit = () => {
    setIsOpen(false);
    const validFilters: any = {};
    Object.keys(filters).forEach((filterKey) => {
      if (filters[filterKey] && enabledFilters[filterKey]) {
        validFilters[filterKey] = filters[filterKey];
      }
    });
    props.onFilter(validFilters);
  };

  function renderInputType(option: IFilterOptionInputType | IFilterOptionDropdownType) {
    if (option.type === "input") {
      const inputOption = option as IFilterOptionInputType;
      return (
        <InputGroup
          aria-label={`${option.name} input`}
          name={inputOption.name}
          placeholder={inputOption.placeholder}
          value={filters[inputOption.name] ?? ""}
          onChange={(e) => handleChange({ value: e.target.value, name: e.target.name })}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
          disabled={!enabledFilters[inputOption.name]}
        />
      );
    }

    if (option.type === "dropdown") {
      const inputOption = option as IFilterOptionDropdownType;

      return (
        <HTMLSelect
          aria-label={`${option.name} dropdown`}
          value={filters[inputOption.name] ?? inputOption.defaultValue}
          options={inputOption.options}
          onChange={(e) => handleChange({ value: e.target.value, name: inputOption.name })}
          disabled={!enabledFilters[inputOption.name]}
        />
      );
    }
  }

  return (
    <Popover2
      isOpen={isOpen}
      content={
        <Menu>
          {props.options.map((option, index) => {
            return (
              <div key={index} className="menu-item">
                <Checkbox
                  aria-label={`Enable ${option.name} filter`}
                  checked={enabledFilters[option.name] ?? false}
                  onChange={() => handleOnCheckBoxChange(option)}
                />
                {renderInputType(option)}
              </div>
            );
          })}
          <div className="filter-btn">
            <Button text="Filter" onClick={handleSubmit} intent={Intent.SUCCESS} />
          </div>
        </Menu>
      }
      placement={Position.BOTTOM}
    >
      <Button rightIcon="caret-down" text="Filter By" onClick={() => setIsOpen(!isOpen)} />
    </Popover2>
  );
};

export default Filter;

import { Callout, FormGroup, Intent } from "@blueprintjs/core";
import { ValueContext } from "process-ui-builder";
import { resolveValue } from "process-ui-builder/utils";
import React, { useState } from "react";
import { useContext } from "react";
import { Props } from "./types";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { validator } from "process-ui-builder/validator";
import CurrencyInput from "react-currency-input-field";

export const MoneyField: React.FC<Props> = observer(({ config, readOnly }) => {
  const [validationError, setValidationError] = useState(false);

  const valueContext = useContext(ValueContext);
  const value = resolveValue(config.value, valueContext);
  const isReadOnly = resolveValue(config.readOnly, valueContext) || readOnly;
  const handlers = valueContext.handlers;

  const handleMoneyChange = action((value?: string) => {
    runInAction(() => {
      if (!config.value?.path || !value) {
        return;
      }
      _.set(valueContext, config.value.path, value);
      const isInvalid = validator(value, config.validation);
      setValidationError(isInvalid);

      if (!isInvalid && config.autoFills && handlers[config.autoFills.handler]) {
        _.set(
          valueContext,
          config.autoFills.path,
          handlers[config.autoFills.handler](value, config.money?.locale ?? "de-DE")
        );
      }
    });
  });

  return (
    <>
      <FormGroup
        label={config.label}
        labelFor={config.id}
        labelInfo={config.validation?.required ? "(required)" : ""}
      >
        <div className="bp3-input-group bp3-fill">
          <CurrencyInput
            id={config.id}
            name="input-name"
            placeholder="Please enter a number"
            value={value}
            decimalsLimit={2}
            decimalScale={2}
            onValueChange={handleMoneyChange}
            intlConfig={{
              locale: config.money?.locale ?? "de-DE",
              currency: config.money?.currency ?? "EUR",
            }}
            className="bp3-input"
            step={1}
            readOnly={isReadOnly}
          />
        </div>
      </FormGroup>
      {validationError && (
        <Callout intent={Intent.DANGER} icon="error" className="error">
          Value is not valid
        </Callout>
      )}
    </>
  );
});

import { Callout, Intent } from "@blueprintjs/core";
import { ValueContext } from "process-ui-builder";
import { FormGroup } from "process-ui-builder/components/form-group";
import { GroupSchema } from "process-ui-builder/config";
import { isFormGroup } from "process-ui-builder/config/guards";
import { resolveValue } from "process-ui-builder/utils";
import React, { useContext } from "react";

interface Props {
  config: GroupSchema;
  readOnly: boolean;
}

export const Group: React.FC<Props> = ({ config, readOnly }) => {
  const valueContext = useContext(ValueContext);

  if (isFormGroup(config)) {
    const isReadOnly = resolveValue(config.readOnly, valueContext) || readOnly;

    return <FormGroup config={config} readOnly={isReadOnly} />;
  } else {
    const Component = valueContext.customComponents[config.component];

    if (Component === undefined) {
      return (
        <Callout intent={Intent.DANGER}>
          Custom Component {config.component} was not found in the context
        </Callout>
      );
    }

    return Component;
  }
};

import { Button, Intent } from "@blueprintjs/core";
import { graphQlErrorToaster, successToaster } from "helpers/toaster";
import React, { useMemo, useState } from "react";
import {
  GetCaseDataQuery,
  useAdminCaseRevokeRejectionMutation,
  useAdminCreateOfferMutation,
  useAdminRejectCaseMutation,
  Product_Enum,
  useAdminConfirmRejectionMutation,
} from "types/graphql.types";
import { RejectionReasonOptions } from "../constants";
import { OTHER } from "../../../constants";
import { canConfirmRejectCase, canRejectCase, canCreateOffer } from "../utils";
import SelectRejectReasonModal from "components/SelectRejectReasonModal";
import { Claim } from "../types";
import { getLocaleFromFields, getNumberValueFromLocale } from "helpers/moneyFormatter";
import { generateOptions } from "pages/utils/generate-options";
import getUiConfig from "../config";
import get from "lodash/get";
import "./style.scss";

interface IProps {
  onActionSuccess: () => Promise<void>;
  data: GetCaseDataQuery;
  activeOfferAmounts: { offer_amount: number; claim_amount: number };
  claim: Claim;
}

export const StepOneActions: React.FC<IProps> = ({
  onActionSuccess,
  data,
  activeOfferAmounts,
  claim,
}) => {
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [isCreateOfferLoading, setIsCreateOfferLoading] = useState<boolean>(false);
  const [isRejectCaseLoading, setIsRejectCaseLoading] = useState<boolean>(false);
  const [isRevokeRejectLoading, setIsRevokeRejectLoading] = useState<boolean>(false);
  const [isConfirmRejectLoading, setIsConfirmRejectLoading] = useState<boolean>(false);

  const [revokeRejectionMutation] = useAdminCaseRevokeRejectionMutation();

  //auto-vertical-create-offer-mutation
  const [createOfferMutation] = useAdminCreateOfferMutation();
  const [rejectCaseMutation] = useAdminRejectCaseMutation();
  const [confirmRejectionMutation] = useAdminConfirmRejectionMutation();

  const id = useMemo(() => data.baseCase?.id, [data.baseCase?.id]);

  if (!id || !data.baseCase) {
    return <></>;
  }

  const onOfferCreateRequestSuccess = (error: boolean) => {
    if (!error) {
      successToaster("Offer created.");
    }
    onActionSuccess().then(() => {
      setIsCreateOfferLoading(false);
    });
  };

  // auto-vertical-create-offer
  const createOffer = (
    vertical: Product_Enum,
    input: { case: { id: string; offer: { offerAmount: number; claimAmount: number } } }
  ) => {
    createOfferMutation({
      variables: {
        input: {
          configName: `${vertical.toLowerCase()}-claim-purchasing`,
          input,
        },
      },
    }).then((resp) => {
      onOfferCreateRequestSuccess(!!resp.errors);
    });

    return;
  };

  const handleCreateOffer = () => {
    setIsCreateOfferLoading(true);
    const fields = get(getUiConfig(data.baseCase?.product_id!), "steps[0].groups[2].fields");
    const localeOfferAmount = getLocaleFromFields(fields, "offer_amount");
    const localeClaimAmount = getLocaleFromFields(fields, "claim_amount");

    const offerAmount = isNaN(activeOfferAmounts.offer_amount)
      ? getNumberValueFromLocale(activeOfferAmounts.offer_amount, localeOfferAmount)
      : activeOfferAmounts.offer_amount;
    const claimAmount = isNaN(activeOfferAmounts.claim_amount)
      ? getNumberValueFromLocale(activeOfferAmounts.claim_amount, localeClaimAmount)
      : activeOfferAmounts.claim_amount;

    //General check for offer amount and claim amount
    if (claimAmount <= 0 || offerAmount <= 0 || isNaN(offerAmount) || isNaN(claimAmount)) {
      setIsCreateOfferLoading(false);
      graphQlErrorToaster("Please enter valid amounts in Offer Detail form.");
      return;
    }
    if (offerAmount > claimAmount) {
      setIsCreateOfferLoading(false);
      graphQlErrorToaster(
        "Please enter a valid offer amount, it should be less than claim amount."
      );
      return;
    }

    if (data.baseCase) {
      createOffer(data.baseCase.product_id, { case: { id, offer: { offerAmount, claimAmount } } });
    }
  };

  const handleRejectCase = (rejectionObj: {
    rejectionReason: string;
    otherRejectionReason?: string;
  }) => {
    if (!data.baseCase?.product_id) {
      graphQlErrorToaster("Product id is missing, please try again later or contact support.");
      return;
    }
    setIsRejectCaseLoading(true);

    const variables = {
      input: {
        configName: `${data.baseCase.product_id.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            id,
            rejectionReason: rejectionObj.rejectionReason,
            ...(rejectionObj.otherRejectionReason && {
              otherRejectionReason: rejectionObj.otherRejectionReason.trim(),
            }),
          },
        },
      },
    };

    rejectCaseMutation({ variables }).then((resp) => {
      if (resp.errors?.length) {
        setIsRejectCaseLoading(false);
        return;
      }
      successToaster("Case is rejected.");
      setShowRejectModal(false);
      onActionSuccess().then(() => {
        setIsRejectCaseLoading(false);
      });
    });
  };

  const handleConfirmRejection = () => {
    if (!data.baseCase?.product_id) {
      graphQlErrorToaster("Product id is missing, please try again later or contact support.");
      return;
    }
    const variables = {
      input: {
        configName: `${data.baseCase.product_id.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            id,
          },
        },
      },
    };

    setIsConfirmRejectLoading(true);
    confirmRejectionMutation({
      variables,
    }).then((resp) => {
      if (resp.errors?.length) {
        setIsConfirmRejectLoading(false);
        return;
      }
      successToaster("The rejection is confirmed.");
      onActionSuccess().then(() => {
        setIsConfirmRejectLoading(false);
      });
    });
  };

  const handleRevokeRejection = () => {
    setIsRevokeRejectLoading(true);
    revokeRejectionMutation({
      variables: {
        id,
      },
    }).then((resp) => {
      if (resp.errors?.length) {
        setIsRevokeRejectLoading(false);
        return;
      }
      successToaster("The rejection is revoked.");
      onActionSuccess().then(() => {
        setIsRevokeRejectLoading(false);
      });
    });
  };

  const showCreateOfferButton =
    data.baseCase?.product_id === Product_Enum.Fit
      ? false
      : canCreateOffer(data.baseCase, claim, activeOfferAmounts.offer_amount);

  const isRejectionSupported = !!(
    data.baseCase && RejectionReasonOptions[data.baseCase.product_id]
  );

  if (isRejectionSupported && canConfirmRejectCase(data.baseCase)) {
    return (
      <div className="one">
        <Button
          text={"Revoke Rejection"}
          intent={Intent.PRIMARY}
          large
          onClick={handleRevokeRejection}
          loading={isRevokeRejectLoading}
        />
        <Button
          text={"Confirm Rejection"}
          intent={Intent.WARNING}
          large
          onClick={handleConfirmRejection}
          loading={isConfirmRejectLoading}
        />
      </div>
    );
  }
  return (
    <div className="one">
      {isRejectionSupported && canRejectCase(data.baseCase) ? (
        <Button
          text={"Reject"}
          intent={Intent.WARNING}
          large
          onClick={() => {
            setShowRejectModal(true);
          }}
        />
      ) : (
        <div></div>
      )}

      <Button
        text={"Create Offer"}
        intent={Intent.PRIMARY}
        large
        onClick={handleCreateOffer}
        loading={isCreateOfferLoading}
        disabled={!showCreateOfferButton}
      />

      {showRejectModal && isRejectionSupported && (
        <SelectRejectReasonModal
          options={generateOptions(RejectionReasonOptions[data.baseCase?.product_id]).concat({
            label: "Other reason",
            value: OTHER,
          })}
          rejectCase={handleRejectCase}
          setShowRejectModal={setShowRejectModal}
          showRejectModal={showRejectModal}
          isRejectCaseLoading={isRejectCaseLoading}
        />
      )}
    </div>
  );
};

import { useQuery } from "@apollo/client";
import { Button, Position, Spinner, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { ErrorMessage } from "components/shared/ErrorMessage";
import List from "components/shared/List";
import { CpsListHeader } from "components/shared/ListHeader";
import { Paginator } from "components/shared/Paginator";
import moment from "moment";
import { Filter } from "pages/case-list/Filter";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "reducers";
import { Order_By, Product_Enum } from "types/graphql.types";
import { humanizeString } from "../../helpers/humanize-string/humanize-string";
import { useHandleOnSortByColumn } from "../../hooks/use-handle-on-sort-by-column";
import { generateOrderByVariableForCases } from "./order-by";
import { AdminListCasesQuery, AdminListCasesQueryVariables, getListQuery } from "./query";
import "./style.scss";
import { getWhere } from "./where";
import { DELETED, GDPR_DELETED } from "../../constants";
import {
  caseStateTagIntent,
  caseStateTagIsMinimal,
  offerStateTagIntent,
  offerStateTagIsMinimal,
} from "./generateStateTagStyling";
import { pendingCaseStates } from "./state-filters";
import { formatMoneyVerticals } from "helpers/moneyFormatter";

const getRow = (
  item: AdminListCasesQuery["cases"][number],
  vertical: string,
  isCaseAgentSupervisor: boolean
) => {
  return {
    id: item.id,
    url: `/cases/${item.id}`,
    cells: [
      `#${item.alias_id}`,
      item.state === GDPR_DELETED ? DELETED : `${item.first_name} ${item.last_name}`,
      item.state === GDPR_DELETED ? DELETED : item.email,
      <b key="company" className="cl-company-col">
        {item.claim.company.name || "== NO COMPANY DEFINED =="}
      </b>,
      <Tag
        key="case-state"
        intent={caseStateTagIntent(
          item.state,
          pendingCaseStates(vertical, isCaseAgentSupervisor).includes(item.state)
        )}
        minimal={caseStateTagIsMinimal(item.state, !!item.offer_state)}
        round={true}
        large={true}
      >
        {humanizeString(item.state)}
      </Tag>,

      item.offer_state ? (
        <Tag
          key="offer-state"
          intent={offerStateTagIntent(item.offer_state)}
          minimal={offerStateTagIsMinimal(item.offer_state)}
          round={true}
          large={true}
        >
          {humanizeString(item.offer_state)}
        </Tag>
      ) : null,
      item.offer_state ? formatMoneyVerticals(item.claim_amount) : null,
      moment.utc(item.created_at).local().fromNow(),
      moment.utc(item.updated_at).local().fromNow(),
    ],
  };
};

export const ListPage: React.FC = () => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  const isCaseAgentSupervisor = !!useSelector((state: RootState) =>
    state.roles.roles?.includes("case_agent_supervisor")
  );

  const handleOnSortByColumn = useHandleOnSortByColumn();

  const searchParams = new URLSearchParams(location.search);
  const vertical = searchParams.get("vertical") as Product_Enum;
  const sortColumn = searchParams.get("sortColumn");
  searchParams.delete("sortColumn"); // remove sortColumn from searchParams for prevent to further use
  const sortOrder = searchParams.get("sortOrder");
  searchParams.delete("sortOrder"); // remove sortColumn from searchParams for prevent to further use
  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const perPage = parseInt(searchParams.get("perPage") || "25", 10);
  const view = searchParams.get("view");

  const query = getListQuery(vertical);

  const { data, loading, error } = useQuery<AdminListCasesQuery, AdminListCasesQueryVariables>(
    query,
    {
      variables: {
        offset: perPage * (currentPage - 1),
        limit: perPage,
        ...generateOrderByVariableForCases(
          sortColumn,
          sortOrder === "asc" ? Order_By.Asc : Order_By.Desc
        ),
        where: getWhere(searchParams, isCaseAgentSupervisor),
      },
    }
  );

  if (error) {
    return <ErrorMessage error={error.message} />;
  }

  const columns = [
    { label: "ID", id: "id" },
    { label: "Customer", id: "customer" },
    { label: "Email", id: "email" },
    { label: "Company", id: "company" },
    { label: "Case State", id: "case_state" },
    { label: "Offer State", id: "offer_state" },
    { label: "Claim Amount", id: "claim_amount" },
    { label: "Date", id: "date" },
    { label: "Last Update", id: "last_update" },
  ];

  const sortableColumns = [
    "id",
    "customer",
    "email",
    "company",
    "case_state",
    "offer_state",
    "claim_amount",
    "date",
    "last_update",
  ];
  if (vertical === Product_Enum.Fit) {
    // remove company from sortableColumns list due to performance issues
    sortableColumns.splice(sortableColumns.indexOf("company"), 1);
  }

  const cases = data?.cases ?? [];
  const companyOptions = data?.companyOptions ?? [];

  return (
    <div className="list-cont">
      <CpsListHeader
        vertical={vertical}
        view={view}
        listIsFiltered={Array.from(searchParams).length > 1}
        filter={
          <Popover2
            isOpen={isFilterOpen}
            content={
              <Filter companyOptions={companyOptions} onFilter={() => setIsFilterOpen(false)} />
            }
            placement={Position.BOTTOM}
          >
            <Button
              rightIcon="caret-down"
              text="Filter By"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            />
          </Popover2>
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <List
          columns={columns}
          rows={cases.map((r) => getRow(r, vertical, isCaseAgentSupervisor))}
          sort={{
            onSortByColumn: handleOnSortByColumn,
            sortableColumns,
            sortColumn: sortColumn || "last_update",
            sortOrder: sortOrder || Order_By.Desc,
          }}
        />
      )}
      <Paginator
        total={data?.aggregate.aggregate.count || 0}
        perPage={perPage}
        onPerPageChange={(p) => {
          searchParams.set("perPage", p.toString());
          history.push("/cases/?" + searchParams.toString());
        }}
        currentPage={currentPage}
        onPageChange={(p) => {
          searchParams.set("page", p.toString());
          history.push("/cases/?" + searchParams.toString());
        }}
      />
    </div>
  );
};

import { IConfig } from "process-ui-builder/config/types";
import { READ_ONLY_VERTICALS } from "../../../constants";
import { Product_Enum } from "types/graphql.types";

const ocgConfig: IConfig = {
  id: "ocg_case",
  version: "0.0.1",
  checkedSteps: {
    path: "flow.checked_steps",
  },
  currentStep: {
    path: "flow.current_step",
  },
  steps: [
    {
      id: "data_validation",
      label: "Data Validation & Offer Preparation",
      readOnly: {
        path: "flow.read_only_steps.data_validation",
      },
      groups: [
        {
          id: "customer_data",
          type: "form",
          title: "Customer Data",
          size: {
            colSpan: 4,
            rowSpan: 2,
          },
          readOnly: {
            path: "flow.read_only_group.customer_data",
          },
          fields: [
            {
              id: "salutation",
              label: "Salutation",
              type: "select",
              emptyValue: true,
              options: [
                {
                  label: "Frau",
                  value: "FEMININE",
                },
                {
                  label: "Herr",
                  value: "MASCULINE",
                },
              ],
              value: { path: "case.customer.salutation" },
            },
            {
              id: "first_name",
              label: "First Name",
              type: "text",
              validation: {
                required: true,
                maxLength: 100,
                minLength: 2,
              },
              value: { path: "case.customer.first_name" },
            },
            {
              id: "last_name",
              label: "Last Name",
              type: "text",
              validation: {
                required: true,
                maxLength: 100,
                minLength: 2,
              },
              value: { path: "case.customer.last_name" },
            },
            {
              id: "email",
              label: "Email",
              type: "email",
              validation: {
                required: true,
              },
              value: { path: "case.customer.email" },
            },
            {
              id: "newsletter_optin",
              label: "Newsletter OptIn?",
              type: "checkbox",
              value: { path: "case.customer.newsletter_optin" },
            },
          ],
          onSubmit: {
            type: "handler",
            handler: "updateCustomer",
            description: "Change Customer Data",
            disabled: READ_ONLY_VERTICALS.includes(Product_Enum.Ocg),
          },
        },
        {
          id: "claim_data",
          type: "form",
          size: {
            colSpan: 4,
            rowSpan: 2,
          },
          title: "Claim Data",
          readOnly: {
            path: "flow.read_only_group.claim_data",
          },
          fields: [
            {
              id: "company_id",
              label: "Company",
              type: "select",
              emptyValue: true,
              options: { type: "handler", handler: "getCompanies" },
              validation: {
                required: true,
              },
              value: { path: "case.claim.company_id" },
            },
            {
              id: "money_spent",
              label: "Money spent",
              type: "money",
              validation: {
                required: true,
              },
              value: { path: "case.claim.money_spent" },
            },
            {
              id: "is_sports_betting",
              label: "Sports Betting",
              type: "checkbox",
              value: { path: "case.claim.is_sports_betting" },
            },
          ],
          onSubmit: {
            type: "handler",
            handler: "updateOcgClaim",
            description: "Change Claim Data",
            disabled: READ_ONLY_VERTICALS.includes(Product_Enum.Ocg),
          },
        },
        {
          id: "offer_details",
          type: "form",
          title: "Offer Details",
          size: {
            colSpan: 4,
          },
          readOnly: {
            path: "flow.read_only_group.offer_details",
          },
          fields: [
            {
              id: "promo_code",
              label: "Promo Code",
              type: "text",
              validation: {
                maxLength: 50,
                minLength: 4,
              },
              readOnly: true,
              value: { path: "case.promo_code" },
            },
            {
              id: "claim_amount",
              label: "Claim Amount",
              type: "money",
              validation: {
                required: true,
              },
              value: { path: "case.active_offer.claim_amount" },
            },
            {
              id: "offer_amount",
              label: "Offer Amount",
              type: "money",
              value: { path: "case.active_offer.offer_amount" },
              validation: {
                required: true,
                regex: "^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$",
              },
            },
          ],
        },
        {
          id: "docs",
          type: "customComponent",
          component: "CaseDocumentList",
        },
      ],
      stepActions: {
        component: "StepOneActions",
      },
    },
    {
      id: "offer",
      label: "Offer",
      groups: [
        {
          id: "active_offer",
          type: "customComponent",
          component: "ActiveOfferCard",
        },
        {
          id: "offer_history",
          type: "customComponent",
          component: "OfferHistoryCard",
        },
        {
          id: "address",
          type: "form",
          size: {
            colSpan: 4,
          },
          title: "Address",
          readOnly: {
            path: "flow.read_only_group.address_data",
          },
          fields: [
            {
              id: "street",
              label: "Street",
              type: "text",
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.street" },
            },
            {
              id: "postal_code",
              label: "Postal Code",
              type: "text",
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.postal_code" },
            },
            {
              id: "city",
              label: "City",
              type: "text",
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.city" },
            },
            {
              id: "country_code",
              label: "Country",
              type: "select",
              emptyValue: true,
              options: [
                {
                  label: "Germany",
                  value: "DE",
                },
                {
                  label: "Austria",
                  value: "AT",
                },
              ],
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.country_code" },
            },
          ],
          onSubmit: {
            type: "handler",
            handler: "changeOfferAddress",
            description: "Change offer address",
            disabled: READ_ONLY_VERTICALS.includes(Product_Enum.Ocg),
          },
        },
        {
          id: "bank_account",
          type: "form",
          title: "Bank Account",
          readOnly: true,
          size: {
            colSpan: 4,
          },
          fields: [
            {
              id: "iban",
              label: "IBAN",
              type: "text",
              validation: {
                required: true,
                iban: true,
              },
              value: { path: "case.offer.bank_account.iban" },
            },
          ],
        },
      ],
      stepActions: {
        component: "StepTwoActions",
      },
    },
    {
      id: "assignment",
      label: "Assignment",
      groups: [
        {
          id: "active_assignment",
          type: "customComponent",
          component: "ActiveAssignmentDetails",
        },
        {
          id: "assignment_history",
          type: "customComponent",
          component: "AssignmentHistory",
        },
        {
          id: "assignment_previews",
          type: "customComponent",
          component: "AssignmentPreviews",
        },
      ],
      stepActions: {
        component: "StepThreeActions",
      },
    },
    {
      id: "payment",
      label: "Payment",
      groups: [
        {
          id: "active_payment",
          type: "customComponent",
          component: "ActivePaymentDetails",
        },
        {
          id: "payment_history",
          type: "customComponent",
          component: "PaymentHistory",
        },
        {
          id: "bank_transaction",
          type: "form",
          title: "Bank Transaction",
          size: {
            colSpan: 4,
          },
          readOnly: true,
          fields: [
            {
              id: "amount",
              label: "Amount",
              type: "money",
              readOnly: true,
              value: { path: "case.bank_transaction.amount" },
            },
            {
              id: "date",
              label: "Date",
              type: "date",
              readOnly: true,
              value: { path: "case.bank_transaction.date" },
            },
            {
              id: "receiver_name",
              label: "Receiver Name",
              type: "text",
              readOnly: true,
              value: { path: "case.bank_transaction.receiver_name" },
            },
            {
              id: "receiver_iban",
              label: "Receiver IBAN",
              type: "text",
              readOnly: true,
              value: { path: "case.bank_transaction.receiver_iban" },
            },
            {
              id: "sender_name",
              label: "Sender Name",
              type: "text",
              readOnly: true,
              value: { path: "case.bank_transaction.sender_name" },
            },
            {
              id: "sender_iban",
              label: "Sender IBAN",
              type: "text",
              readOnly: true,
              value: { path: "case.bank_transaction.sender_iban" },
            },
            {
              id: "remote_id",
              label: "Remote ID",
              type: "text",
              readOnly: true,
              value: { path: "case.bank_transaction.remote_id" },
            },
          ],
        },
      ],
    },
  ],
};

export default ocgConfig;

import React, { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthToken, setAuthToken } from "../actions/auth";
import { RootState } from "../reducers";
import { removeRoles, setRoles } from "../actions/roles";

interface IAuthContext {
  isLoggedIn: boolean;
  token?: string;
  login: (token: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  isLoggedIn: false,
  login: (token: string) => {},
  logout: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => !!state.auth.token);

  const login = (token: string): void => {
    dispatch(setAuthToken(token));
    dispatch(setRoles(token));
  };
  const logout = (): void => {
    dispatch(removeAuthToken());
    dispatch(removeRoles());
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AuthContext.Provider>
  );
};

import { Tag, FormGroup, Label, Intent } from "@blueprintjs/core";
import { ValueContext } from "process-ui-builder";
import { StaticOption } from "process-ui-builder/config/types";
import { resolveValue } from "process-ui-builder/utils";
import React, { useContext } from "react";
import { Props } from "./types";
import "./style.scss";

export const TagList: React.FC<Props> = ({ config, readOnly }) => {
  const valueContext = useContext(ValueContext);
  const value = config.value ? resolveValue<unknown[]>(config.value, valueContext) : [];
  const tagsArray = (config.options as unknown) as StaticOption[];

  const checkForContent = (checkValue: string[], arr: StaticOption[]): boolean => {
    return !!arr
      .map((element) => {
        return checkValue.includes(element.value as string);
      })
      .filter(Boolean).length;
  };

  const hasContent = checkForContent((value as unknown) as string[], tagsArray);

  return (
    <>
      {hasContent && (
        <FormGroup labelFor={config.id}>
          <Label>{config.label}</Label>

          {tagsArray.map(
            (item, index) =>
              value?.includes(item.value) && (
                <>
                  <Tag className="tag-list-item" key={index} round={true} intent={Intent.PRIMARY}>
                    {item.label}
                  </Tag>
                </>
              )
          )}
        </FormGroup>
      )}
    </>
  );
};

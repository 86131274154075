import Fuse from "fuse.js";
import { SelectSearchOption } from "react-select-search";

export default function filterOption(options: SelectSearchOption[]) {
  const fuse = new Fuse(options, {
    keys: ["name", "groupName", "items.name"],
    threshold: 0.3,
  });

  return (value: string) => {
    if (!value.length) {
      return options;
    }

    return fuse.search(value);
  };
}

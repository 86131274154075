import { useLazyQuery, gql } from "@apollo/client";

export function useGetCompanyOptionsLazyQuery(vertical: string) {
  const query = gql(/* GraphQL */ `
   query GetCompanyOptions {
         companies: ${vertical}_company(order_by: { name: asc }) {
           value: id
           label: name
         }
       }
 `);

  return useLazyQuery(query);
}

import React from "react";
import { Callout, Intent } from "@blueprintjs/core";

import "./style.scss";

export const NoLawsuitDataAvailable: React.FC = () => {
  return (
    <div className="info-card">
      <Callout intent={Intent.WARNING} icon={"warning-sign"} className="sub-card-content">
        <div className="sub-card-text">
          <span>No Lawsuit Data Available</span>
        </div>
      </Callout>
    </div>
  );
};

import React from "react";
import { Card, Elevation, H5 } from "@blueprintjs/core";

import { TextField } from "process-ui-builder";

export const LawsuitData: React.FunctionComponent = () => {
  return (
    <Card elevation={Elevation.ONE} className="base-card gff-claim-card">
      <H5>Lawsuit Data</H5>

      <TextField
        config={{
          label: "Lawsuit-ID",
          id: "lawsuit_id",
          type: "text",
          value: { path: "case.lawsuitData.id" },
        }}
        readOnly={true}
      />
      <TextField
        config={{
          label: "Court",
          id: "court",
          type: "text",
          value: { path: "case.lawsuitData.court.name" },
        }}
        readOnly={true}
      />
      <TextField
        config={{
          label: "Lawyer",
          id: "lawyer",
          type: "text",
          value: { path: "case.lawsuitData.lawyer.name" },
        }}
        readOnly={true}
      />
    </Card>
  );
};

import { FormGroup, InputGroup } from "@blueprintjs/core";
import get from "lodash.get";
import { ValueContext } from "process-ui-builder";
import { useContext } from "react";

interface IProps {
  fieldOption: {
    label: string;
    id?: string;
    readOnly?: boolean;
    value: { path: string };
  };
}

const TextBox: React.FunctionComponent<IProps> = ({ fieldOption }) => {
  const valueContext = useContext(ValueContext);
  const value = get(valueContext, fieldOption.value.path);

  return (
    <>
      <FormGroup label={fieldOption.label} labelFor={fieldOption.id}>
        <InputGroup
          fill
          id={fieldOption.id}
          value={value}
          type={"text"}
          readOnly={fieldOption.readOnly}
        />
      </FormGroup>
    </>
  );
};
export default TextBox;

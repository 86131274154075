import { Button, Card, Elevation, H5, H6, Intent } from "@blueprintjs/core";
import get from "lodash/get";
import { SelectField, TextField, MoneyField, ValueContext } from "process-ui-builder";
import React, { useContext } from "react";
import { IS_ESTIMATED_CLAIM_AMOUNT_OPTIONS, FLIGHT_TYPE_OPTIONS } from "./const";
import Flights from "./Flights";
import Persons from "./Persons";

import "./style.scss";

interface IProps {
  caseState: string;
}
const GffClaimData: React.FunctionComponent<IProps> = ({ caseState }) => {
  const valueContext = useContext(ValueContext);

  const handlers = valueContext.handlers;
  const companies = handlers["getCompanies"]();
  const updateClaim = () => handlers["updateGffClaim"]();

  const readOnly = get(valueContext, "flow.read_only_group.claim_data");
  return (
    <Card elevation={Elevation.ONE} className="base-card gff-claim-card">
      <H5>Claim Data</H5>

      {companies && (
        <SelectField
          config={{
            id: "company_id",
            label: "Company",
            type: "select",
            options: companies,
            validation: {
              required: true,
            },
            value: { path: "case.claim.company_id" },
            emptyValue: true,
          }}
          readOnly={readOnly}
        />
      )}

      <SelectField
        config={{
          id: "flight_type_option",
          label: "Flight Type",
          type: "select",
          options: FLIGHT_TYPE_OPTIONS,
          validation: {
            required: true,
          },
          value: { path: "case.claim.flight_type" },
          emptyValue: true,
        }}
        readOnly={true}
      />

      <SelectField
        config={{
          id: "claim_amount_option",
          label: "Claim Amount",
          type: "select",
          options: IS_ESTIMATED_CLAIM_AMOUNT_OPTIONS,
          validation: {
            required: true,
          },
          value: { path: "case.claim.is_estimated_claim_amount" },
          emptyValue: true,
          isBoolean: true,
        }}
        readOnly={readOnly}
      />

      <MoneyField
        config={{
          label: "Ticket Amount",
          id: "ticketAmount",
          type: "money",
          value: { path: "case.claim.ticket_amount" },
          validation: { required: true },
        }}
        readOnly={readOnly}
      />
      <TextField
        config={{
          label: "Booking Reference",
          id: "bookingReference",
          type: "text",
          value: { path: "case.claim.booking_reference" },
          validation: { required: true },
        }}
        readOnly={readOnly}
      />

      {caseState !== "GDPR_DELETED" && (
        <>
          <H6>Flights Information</H6>
          <Flights />
          <H6 className="margin-top-20">Persons Information</H6>
          <Persons />
          <br />
          <Button
            onClick={updateClaim}
            intent={Intent.PRIMARY}
            text="Update Claim"
            className="margin-top-20"
            disabled={readOnly}
          />
        </>
      )}
    </Card>
  );
};
export default GffClaimData;

import { humanizeString } from "helpers/humanize-string";
import { Product_Enum } from "../../types/graphql.types";

export const DOCUMENT_REQUEST_REASON_COMMON = "OTHER";

const FIT_DOCUMENT_REASONS = [
  "NO_RECEIPT",
  "FTS_ISSUE",
  "FTS_NOT_SUPPORTED",
  "FILE_FORMAT",
  "ILLEGIBLE_DOCUMENT",
  "DIRECT_DEBIT_FEES",
  "WRONG_MONTHS",
  "NO_SUSPENSION_PERIOD",
  "ADDITIONAL_DOCUMENTS",
  "REPEATED_FTS_SUBMISSION",
  "NO_MEMBERSHIP_NUMBER",
  DOCUMENT_REQUEST_REASON_COMMON,
];

const GFF_DOCUMENT_REASONS = [
  "COMPLETE_BOOKING_CONFIRMATION",
  "ILLEGIBLE_DOCUMENT",
  "PACKAGE_TOURS",
  "PAYMENT_PROOF",
  DOCUMENT_REQUEST_REASON_COMMON,
];

const NFLX_DOCUMENT_REASONS = [
  "ACCOUNT_DETAILS",
  "BILLING_HISTORY",
  "MESSAGES",
  "INCOMPLETE_ZIP",
  "WRONG_ZIP",
  DOCUMENT_REQUEST_REASON_COMMON,
];

export const DOCUMENT_REQUEST_REASON = {
  [Product_Enum.Fit as string]: FIT_DOCUMENT_REASONS.map((value) => {
    return { label: humanizeString(value).replace("Fts", "FTS"), value };
  }),
  [Product_Enum.Gff as string]: GFF_DOCUMENT_REASONS.map((value) => {
    return { label: humanizeString(value), value };
  }),
  [Product_Enum.Nflx as string]: NFLX_DOCUMENT_REASONS.map((value) => {
    return { label: humanizeString(value), value };
  }),
};

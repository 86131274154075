import { FormGroup, HTMLSelect } from "@blueprintjs/core";
import get from "lodash.get";
import { ValueContext } from "process-ui-builder";
import { StaticOption } from "process-ui-builder/config/types";
import React, { useContext } from "react";

interface IProps {
  fieldOption: {
    label: string;
    id: string;
    readOnly?: boolean;
    optionsData: {
      value: string;
      label: string;
    }[];
    value: { path: string };
    emptyValue?: boolean;
  };
}

const SelectBox: React.FunctionComponent<IProps> = ({ fieldOption }) => {
  const optionsArray: StaticOption[] = [];
  const valueContext = useContext(ValueContext);
  const value = get(valueContext, fieldOption.value.path);

  if (fieldOption.emptyValue) {
    optionsArray.push({
      label: "--- Select a value ---",
      value: "",
    });
  }
  if (fieldOption.optionsData) {
    optionsArray.push(...fieldOption.optionsData);
  }

  return (
    <>
      <FormGroup label={fieldOption.label} labelFor={fieldOption.id}>
        <HTMLSelect
          fill
          options={optionsArray}
          id={fieldOption.id}
          value={value}
          disabled={fieldOption.readOnly}
        />
      </FormGroup>
    </>
  );
};
export default SelectBox;

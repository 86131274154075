import React, { useContext, useMemo } from "react";
import {
  Dialog,
  HTMLSelect,
  FormGroup,
  TextArea,
  Button,
  Intent,
  Callout,
} from "@blueprintjs/core";

import { TSelectOptions } from "document-manager/types";
import { DocumentManagerContext } from "document-manager/DocumentManager";
import { getOptionsWithEmptyOption } from "document-manager/utils";

import "./styles.scss";

type TProps = {
  isOpen: boolean;
  categories: TSelectOptions;
  onClose: () => void;
};

export const Modal: React.FC<TProps> = ({ isOpen, categories, onClose }) => {
  const { requestDocumentReasons, requestDocumentHandler } = useContext(DocumentManagerContext);
  const [category, setCategory] = React.useState<string>("");
  const [reason, setReason] = React.useState<string>("");

  const [isShowOtherReason, setIsShowOtherReason] = React.useState(false);
  const [otherReason, setOtherReason] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);

  const getRequestReasonOptionsWithEmptyOptions = useMemo(
    () => getOptionsWithEmptyOption(requestDocumentReasons, "Select a reason"),
    [requestDocumentReasons]
  );

  const handleClose = () => {
    setShowError(false);
    onClose();
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
    setShowError(false);
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReason(e.target.value);
    setShowError(false);
    setIsShowOtherReason(e.target.value === "OTHER");
  };

  const handleOtherReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
    setShowError(false);
  };

  const handleRequestDocument = () => {
    if (!category || !reason || (reason === "OTHER" && !otherReason)) {
      setShowError(true);
      return;
    } else {
      requestDocumentHandler &&
        requestDocumentHandler([category], reason, otherReason).then(() => {
          handleClose();
        });
    }
  };

  return (
    <Dialog
      title="Request a New Document"
      icon="notifications"
      isOpen={isOpen}
      onClose={handleClose}
      className="request-document-modal"
    >
      <div className="request-document-modal--body">
        <FormGroup
          label="Category"
          labelFor="document-category"
          labelInfo="(required)"
          intent={showError && !category ? Intent.DANGER : undefined}
        >
          <HTMLSelect
            fill
            options={categories}
            id="document-category"
            value={category}
            onChange={handleCategoryChange}
          />
        </FormGroup>
        {showError && !reason && (
          <Callout intent={Intent.DANGER} icon="error" className="error">
            Value is not valid
          </Callout>
        )}
        <FormGroup
          label="Request Reason"
          labelFor="request-reason"
          labelInfo="(required)"
          intent={showError && !reason ? Intent.DANGER : undefined}
        >
          <HTMLSelect
            fill
            options={getRequestReasonOptionsWithEmptyOptions}
            id="request-reason"
            value={reason}
            onChange={handleReasonChange}
          />
        </FormGroup>
        {showError && !reason && (
          <Callout intent={Intent.DANGER} icon="error" className="error">
            Value is not valid
          </Callout>
        )}
        {isShowOtherReason && (
          <>
            <FormGroup
              label="Other Request Reason"
              labelFor="other-request-reason"
              labelInfo="(required)"
              intent={showError && !otherReason ? Intent.DANGER : undefined}
            >
              <TextArea
                fill
                id="other-request-reason"
                value={otherReason}
                onChange={handleOtherReasonChange}
              />
            </FormGroup>
            {showError && !otherReason && (
              <Callout intent={Intent.DANGER} icon="error" className="error">
                Value is not valid
              </Callout>
            )}
          </>
        )}
      </div>
      <div className="request-document-modal--footer">
        <Button text="Cancel" onClick={handleClose} />
        <Button text="Request Document" onClick={handleRequestDocument} intent={Intent.PRIMARY} />
      </div>
    </Dialog>
  );
};

import { IConfig } from "process-ui-builder/config";
import { Product_Enum } from "types/graphql.types";
import fitConfig from "./fit";
import gffConfig from "./gff";
import nrgConfig from "./nrg";
import ocgConfig from "./ocg";
import pkvConfig from "./pkv";
import nflxConfig from "./nflx";
import gdprConfig from "./gdpr";

const getUiConfig = (product: Product_Enum): IConfig | undefined => {
  if (product === Product_Enum.Pkv) {
    return pkvConfig;
  }
  if (product === Product_Enum.Ocg) {
    return ocgConfig;
  }
  if (product === Product_Enum.Gff) {
    return gffConfig;
  }
  if (product === Product_Enum.Fit) {
    return fitConfig;
  }
  if (product === Product_Enum.Nrg) {
    return nrgConfig;
  }
  if (product === Product_Enum.Nflx) {
    return nflxConfig;
  }
  if (product === Product_Enum.Gdpr) {
    return gdprConfig;
  }
};

export default getUiConfig;

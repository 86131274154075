import { Button, HTMLSelect, InputGroup, Intent, Menu } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  DEFAULT_PAYMENT_ON_HOLD_REASONS,
  VERTICAL_PAYMENT_ON_HOLD_REASONS,
} from "../../../constants";
import { humanizeString } from "../../../helpers/humanize-string";
import { caseStates, offerStates } from "../state-filters";

import "./style.scss";

type Option = { label: string; value: string };

const paymentOnHoldReasons = (product: string) => {
  const filters: Option[] = [];

  DEFAULT_PAYMENT_ON_HOLD_REASONS.forEach((reason) => {
    filters.push({
      label: humanizeString(reason),
      value: reason,
    });
  });

  if (VERTICAL_PAYMENT_ON_HOLD_REASONS[product]) {
    VERTICAL_PAYMENT_ON_HOLD_REASONS[product].forEach((reason) => {
      if (!filters.find((filter) => filter.value === reason)) {
        filters.push({
          label: humanizeString(reason),
          value: reason,
        });
      }
    });
  }

  return filters;
};

const filterInputs: { label: string; name: string }[] = [
  { label: "Alias ID", name: "alias_id" },
  { label: "Email", name: "email" },
  { label: "First Name", name: "first_name" },
  { label: "Last Name", name: "last_name" },
  { label: "IBAN", name: "iban" },
];

export const Filter: React.FC<{
  companyOptions: { label: string; value: string }[];
  onFilter: () => void;
}> = ({ companyOptions, onFilter }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const claimTable = queryParams.get("vertical")?.toLowerCase() + "_claim";

  const [filters, setFilters] = useState<{
    [key: string]: string;
  }>({
    alias_id: queryParams.get("alias_id") || "",
    email: queryParams.get("email") || "",
    first_name: queryParams.get("first_name") || "",
    last_name: queryParams.get("last_name") || "",
    iban: queryParams.get("iban") || "",
    state: queryParams.get("state") || "",
    offer_state: queryParams.get("offer_state") || "",
    // eg: "ocg_claim.company.name"
    [`${claimTable}.company_id`]: queryParams.get(`${claimTable}.company_id`) || "",
    "offer.payment_on_hold_reason": queryParams.get("offer.payment_on_hold_reason") || "",
    vertical: queryParams.get("vertical") || "",
  });

  useEffect(() => {
    return () => {
      onFilter();
    };
  }, [onFilter]);

  const handleSubmit = () => {
    history.push({
      pathname: "/cases/",
      // convert filters to search params
      search: Object.keys(filters)
        // filter out empty values
        .filter((key) => filters[key])
        .map((key) => `${key}=${filters[key]}`)
        .join("&"),
    });

    onFilter();
  };

  const filterSelects: {
    label: string;
    name: string;
    options: Option[];
  }[] = [
    {
      label: "Case State",
      name: "state",
      options: [...caseStates].map((item) => ({ label: item, value: item })),
    },
    {
      label: "Offer State",
      name: "offer_state",
      options: [...offerStates].map((item) => ({ label: item, value: item })),
    },
    { label: "Company", name: `${claimTable}.company_id`, options: companyOptions },
    {
      label: "Payment on hold reason",
      name: "offer.payment_on_hold_reason",
      options: paymentOnHoldReasons(filters.vertical),
    },
  ];

  return (
    <Menu className="filter-menu">
      {filterInputs.map((item) => (
        <div key={item.name} className="menu-item">
          <InputGroup
            name={item.name}
            placeholder={item.label}
            value={filters[item.name] || ""}
            onChange={(e) =>
              setFilters({
                ...filters,
                [item.name]: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            fill={true}
          />
        </div>
      ))}
      {filterSelects.map((item) => (
        <div className="menu-item" key={item.name}>
          <HTMLSelect
            value={filters[item.name]}
            options={[{ label: `-- ${item.label} --`, value: "" }, ...item.options]}
            onChange={(e) =>
              setFilters({
                ...filters,
                [item.name]: e.currentTarget.value,
              })
            }
            fill={true}
          />
        </div>
      ))}

      <div className="filter-btn">
        <Button text="Filter" onClick={handleSubmit} intent={Intent.SUCCESS} />
      </div>
    </Menu>
  );
};

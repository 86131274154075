import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { DateField, TextField, MoneyField, ValueContext } from "process-ui-builder";
import get from "lodash/get";

import { Card, Elevation, H6, H5, HTMLTable } from "@blueprintjs/core";

import "./style.scss";
import { NflxCalculation } from "pages/case-view/types";
import { formatMoneyVerticals } from "helpers/moneyFormatter";

const NflxClaimData: React.FunctionComponent = () => {
  const valueContext = useContext(ValueContext);
  const calculation: NflxCalculation = get(valueContext, "case.claim.calculation");

  return (
    <Card elevation={Elevation.ONE} className="base-card nflx-claim-card">
      <H5>Claim Data</H5>
      <br />
      <H6>Account Details</H6>

      <TextField
        config={{
          label: "Netflix Account E-mail",
          id: "nflx_account_email",
          type: "text",
          value: { path: "case.claim.account_email" },
          validation: { required: true },
        }}
        readOnly={true}
      />

      <TextField
        config={{
          label: "Account Owner First Name",
          id: "nflx_first_name",
          type: "text",
          value: { path: "case.claim.first_name" },
          validation: { required: true },
        }}
        readOnly={true}
      />

      <TextField
        config={{
          label: "Account Owner Last Name",
          id: "nflx_last_name",
          type: "text",
          value: { path: "case.claim.last_name" },
          validation: { required: true },
        }}
        readOnly={true}
      />

      <DateField
        config={{
          label: "Start Of Membership",
          id: "start_of_membership",
          type: "date",
          validation: { required: true },
          value: { path: "case.claim.start_of_membership" },
        }}
        readOnly={true}
      />
      <br />
      <H6>Claim Calculation{calculation?.version ? ` (Version: v${calculation.version})` : ""}</H6>
      <HTMLTable className="nflx-claim-table">
        <thead>
          <tr className="heading">
            <td colSpan={2}>Time Frame</td>
            <td>Tariff</td>
            <td>Price / month</td>
            <td>Claim / month</td>
            <td>Months</td>
            <td>Claim / time</td>
          </tr>
        </thead>
        <tbody>
          {calculation?.timeFrames ? (
            calculation.timeFrames.map((timeFrame, index) => (
              <tr key={index}>
                <td colSpan={2}>
                  {timeFrame.start} - {timeFrame.end}
                </td>
                <td>{timeFrame.tariff}</td>
                <td>{formatMoneyVerticals(timeFrame.price)}</td>
                <td>{formatMoneyVerticals(timeFrame.monthlyClaim)}</td>
                <td>{timeFrame.months}</td>
                <td>{formatMoneyVerticals(timeFrame.totalClaim)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No time frames found</td>
            </tr>
          )}
        </tbody>
      </HTMLTable>

      <MoneyField
        config={{
          label: "Total Claim Amount",
          id: "total_claim_amount",
          type: "money",
          value: { path: "case.claim.calculation.claimAmount" },
          validation: { required: true },
        }}
        readOnly={true}
      />
    </Card>
  );
};
export default observer(NflxClaimData);

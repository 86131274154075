import React, { useState } from "react";
import { Button } from "@blueprintjs/core";
import CommentDisplay from "./CommentDisplay";
import CommentEditor from "./CommentEditor";
import { comment } from "./types";
import "./style.scss";
import jwt from "jsonwebtoken";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { warningToaster } from "helpers/toaster";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface IProps {
  comment: comment;
  onRefetch: () => void;
}

const CommentListItem: React.FC<IProps> = ({ comment, onRefetch }) => {
  const [editMode, setEditMode] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const tokenData: any = token ? jwt.decode(token) : "";
  const isCommentAuthor = tokenData.email === comment.user.email;

  const clickHandler = () => {
    if (dayjs.utc().diff(comment.created_at, "minutes", true) > 10) {
      setEditMode(true);
    } else {
      warningToaster("This comment is no longer editable");
    }
  };

  return (
    <div className="comment-separator">
      <div className="comment-flex comment-padding">
        <div>
          <strong>{comment.user.email.split("@")[0]}:</strong>
        </div>

        {dayjs.utc().diff(dayjs.utc(comment.created_at), "minutes", true) <= 10 &&
          !editMode &&
          isCommentAuthor && (
            <Button icon={"edit"} small={true} minimal={true} onClick={clickHandler} />
          )}
      </div>
      {editMode ? (
        <div className="add-comment-form-padding">
          <CommentEditor
            commentId={comment.id}
            comment={comment.comment}
            isCommentAuthor={isCommentAuthor}
            onRefetch={onRefetch}
            onCloseEditor={() => setEditMode(false)}
          />
        </div>
      ): <CommentDisplay comment={comment} />}
    </div>
  );
};

export default CommentListItem;

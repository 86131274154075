export const DOC_TYPES_ALLOWED = [
  "application/pdf",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/vnd.ms-excel", //.xlx files
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //.xlsx files
  "video/quicktime", //.mov videos
  "video/avi", //.avi videos
  "video/mp4",
  "text/csv",
];

export const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;

export const PREVIEW_AVAILABLE = ["jpeg", "jpg", "png", "gif", "pdf"];

export const SUPPORTS_ADD_TO_LAWSUIT = ["jpeg", "jpg", "png", "gif", "pdf"];

import React, { useState } from "react";
import { Button, HTMLSelect, Classes, Dialog, Callout, Intent, TextArea } from "@blueprintjs/core";

import "./selectRejectReasonModal.scss";

interface IProps {
  options?: Array<{ label: string; value: string }>;
  rejectCase: (data: { rejectionReason: string; otherRejectionReason?: string }) => void;
  setShowRejectModal: (bool: boolean) => void;
  showRejectModal: boolean;
  isRejectCaseLoading: boolean;
}

const SelectRejectReasonModal: React.FunctionComponent<IProps> = ({
  options,
  rejectCase,
  showRejectModal,
  setShowRejectModal,
  isRejectCaseLoading,
}) => {
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [otherRejectionReason, setOtherRejectionReason] = useState<string>("");
  const [showOtherRejectionReason, setShowOtherRejectionReason] = useState<boolean>(false);

  const [invalidRejectionReason, setInvalidRejectionReason] = useState<boolean>(false);
  const [invalidOtherRejectionReason, setInvalidOtherRejectionReason] = useState<boolean>(false);
  const cancelHandler = () => {
    setShowRejectModal(false);
  };

  const rejectHandler = () => {
    if (
      rejectionReason &&
      (rejectionReason !== "OTHER" || (showOtherRejectionReason && otherRejectionReason))
    ) {
      rejectCase({ rejectionReason, otherRejectionReason });
    } else {
      if (rejectionReason === "") setInvalidRejectionReason(true);
      if (otherRejectionReason === "") setInvalidOtherRejectionReason(true);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      setInvalidRejectionReason(true);
    } else {
      setInvalidRejectionReason(false);
    }
    setRejectionReason(event.target.value);
    setInvalidOtherRejectionReason(false);
    if (event.target.value === "OTHER") {
      setShowOtherRejectionReason(true);
    } else {
      setShowOtherRejectionReason(false);

      setOtherRejectionReason("");
    }
  };

  const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherRejectionReason(event.target.value);
    if (event.target.value === "") {
      setInvalidOtherRejectionReason(true);
    } else {
      setInvalidOtherRejectionReason(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        className="rejection-modal"
        onClose={cancelHandler}
        title="Reject Case"
        isOpen={showRejectModal}
      >
        <div className={Classes.DIALOG_BODY}>
          <HTMLSelect value={rejectionReason} onChange={onChangeHandler} fill>
            <option value="">Select a reason</option>
            {options?.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </HTMLSelect>
          {invalidRejectionReason && (
            <Callout intent={Intent.DANGER} icon="error" className="error">
              Value is not valid
            </Callout>
          )}
          {showOtherRejectionReason && (
            <div>
              <TextArea
                growVertically={true}
                large={true}
                intent={invalidOtherRejectionReason ? Intent.DANGER : Intent.PRIMARY}
                onChange={onTextAreaChange}
                value={otherRejectionReason}
                placeholder="Type your rejection reason here..."
                className="other-rejection-reason"
              />
              {invalidOtherRejectionReason && (
                <Callout intent={Intent.DANGER} icon="error" className="error">
                  Value is not valid
                </Callout>
              )}
            </div>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={cancelHandler} text="Cancel" minimal={true} />
            <Button
              onClick={rejectHandler}
              text="Reject"
              intent={"danger"}
              loading={isRejectCaseLoading}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default SelectRejectReasonModal;

// ==== TODO link to endpoint and add spinner ==== import { Spinner } from "@blueprintjs/core";
// ==== TODO add sort and filters ==== import { useHandleOnSortByColumn } from "hooks/use-handle-on-sort-by-column";
import React from "react";
// ==== TODO ==== import { useLocation } from "react-router-dom";
import { GenericListHeader } from "components/shared/ListHeader";
import List from "components/shared/List";
import data from "./mockData.json";
import { URL_FINANCE_TRANSACTION_VIEW } from "../../../../../constants";
import moment from "moment";
import { formatMoneyVerticals } from "helpers/moneyFormatter";

export const BankTransactionList: React.FC<{}> = () => {
  const columns = [
    { label: "Bank account", id: "iban" },
    { label: "Partner", id: "partner" },
    { label: "Description", id: "email" },
    { label: "Date", id: "state" },
    { label: "Amount", id: "date" },
    { label: "Last changed", id: "last_update" },
    { label: "Status", id: "status" },
    { label: "Mapping", id: "mapping" },
  ];

  const rows = data?.payments.map((item) => ({
    id: item.id,
    url: `${URL_FINANCE_TRANSACTION_VIEW}/${item.id}`,
    cells: [
      `${item.receiver.iban}`,
      `${item.sender.name}`,
      `${item.paymentReference}`,
      moment.utc(item.date).format("D MMM YYYY"),
      `${formatMoneyVerticals(item.amount, item.currency)}`,
      `${item.lastChangedBy}`,
      "Mapping Status Tag",
      "Mapped Case Tags",
    ],
  }));

  return (
    <div className="list-cont">
      <GenericListHeader
        headerLabel={<>Received payments - search functions will go here</>}
        // ==== TODO add filter
      />
      <List columns={columns} rows={rows || []}/>
    </div>
  );
};

import { Card, Elevation, H5 } from "@blueprintjs/core";
import React from "react";
import "./style.scss";

export const GenericListHeader: React.FC<{ headerLabel?: React.ReactNode; filter?: React.ReactNode }> = ({
  headerLabel,
  filter,
}) => {
  return (
    <Card elevation={Elevation.ZERO} className={"header"}>
      <H5>{headerLabel}</H5>
      {filter}
    </Card>
  );
};

import { Intent, Alert } from "@blueprintjs/core";
import { successToaster, warningToaster } from "helpers/toaster";
import React from "react";
import {
  Product_Enum,
  useAdminCancelOfferMutation,
  useAdminApproveOfferMutation,
} from "types/graphql.types";

export interface IProps {
  productId: Product_Enum;
  caseId: string;
  onMutationSuccess: () => void;
  onMutationError: () => void;
  onHide: () => void;
  isOpen: boolean;
  mode?: "cancel" | "approve";
}

const ActionModalApproveOrCancelOffer: React.FC<IProps> = ({
  productId,
  caseId,
  onMutationSuccess,
  onMutationError,
  onHide,
  isOpen,
  mode,
}) => {
  const [approveOfferMutation] = useAdminApproveOfferMutation();
  const [cancelOfferMutation] = useAdminCancelOfferMutation();
  const variables = {
    input: {
      configName: `${productId.toLowerCase()}-claim-purchasing`,
      input: {
        case: {
          id: caseId,
        },
      },
    },
  };

  const handleConfirmAction = () => {
    if (mode === "cancel") {
      cancelOfferMutation({ variables }).then((resp: any) => {
        if (resp.errors?.length) {
          onMutationError();
          warningToaster("ERROR: The offer could not be cancelled.");
        } else {
          onMutationSuccess();
          successToaster("The offer is cancelled.");
        }
      });
    } else {
      approveOfferMutation({ variables }).then((resp: any) => {
        if (resp.errors?.length) {
          onMutationError();
          warningToaster("ERROR: The offer could not be approved.");
        } else {
          onMutationSuccess();
          successToaster("The offer has been approved.");
        }
      });
    }
  };

  return (
    <>
      <Alert
        cancelButtonText="No"
        confirmButtonText="Yes"
        icon="remove"
        intent={mode === "cancel" ? Intent.DANGER : Intent.SUCCESS}
        isOpen={isOpen}
        onCancel={() => onHide()}
        onConfirm={handleConfirmAction}
      >
        <p>
          Are you sure you want to <b>{mode}</b> the offer?
        </p>
      </Alert>
    </>
  );
};

export default ActionModalApproveOrCancelOffer;

import React, { useContext, useState } from "react";
import { Button } from "@blueprintjs/core";

import { DocumentManagerContext } from "document-manager/DocumentManager";
import { TFileWithCategory } from "document-manager/types";

type TProps = {
  filesList: TFileWithCategory[];
  onClose: () => void;
  disabled: boolean;
  setCategoryWarningIsVisible: (isVisible: boolean) => void;
};

export const UploadButton: React.FC<TProps> = ({
  filesList,
  disabled,
  onClose,
  setCategoryWarningIsVisible,
}) => {
  const { uploadFilesHandler } = useContext(DocumentManagerContext);
  const [isUploadingDocuments, setIsUploadingDocuments] = useState<boolean>(false);

  const uploadFiles = async () => {
    setIsUploadingDocuments(true);
    if (!filesList.length) {
      setIsUploadingDocuments(false);
      return;
    }
    const checkEmptyCategory = filesList.some((file) => file.category === "");
    if (checkEmptyCategory) {
      setIsUploadingDocuments(false);
      setCategoryWarningIsVisible(true);
      return;
    }
    uploadFilesHandler &&
      uploadFilesHandler(filesList)
        .then(() => {
          setIsUploadingDocuments(false);
        })
        .catch(() => {
          setIsUploadingDocuments(false);
        });
    onClose();
  };

  return (
    <Button
      disabled={disabled}
      onClick={uploadFiles}
      text="Upload Documents"
      intent={"primary"}
      large
      loading={isUploadingDocuments}
    />
  );
};

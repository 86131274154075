import { round } from "helpers/round";
import { GetCaseDataQuery, Product_Enum } from "types/graphql.types";
import { Claim, FitClaim } from "../types";
import {
  getActiveOffer,
  getClaimAmount,
  getFitClaimAmount,
  getFitOfferAmount,
  getNflxClaimAmount,
  getNflxOfferAmount,
  getNrgDateOfSuspension,
  getOfferAmount,
} from "../utils";

export const getClaimAndAmounts = (
  data: GetCaseDataQuery,
  activeOffer: {
    claim_amount: number;
    offer_amount: number;
  }
) => {
  if (!data.baseCase?.product_id) {
    throw new Error("No product id found");
  }

  const productId = data.baseCase.product_id;
  let claim = {};
  let claimAmount = getClaimAmount(data.baseCase) ?? activeOffer.claim_amount;
  let offerAmount = getOfferAmount(data.baseCase) ?? activeOffer.offer_amount;

  if (productId === Product_Enum.Gdpr) {
    claim = { ...data.baseCase.gdpr_claim, email: data.baseCase.customer.email };
  } else if (productId === Product_Enum.Ocg) {
    claim = { ...data.baseCase.ocg_claim };
  } else if (productId === Product_Enum.Pkv) {
    claim = { ...data.baseCase.pkv_claim };
  } else if (productId === Product_Enum.Gff) {
    claim = { ...data.baseCase.gff_claim };
  } else if (productId === Product_Enum.Nrg) {
    const date_of_suspension = data.baseCase.nrg_claim?.date_of_suspension
      ? data.baseCase.nrg_claim.date_of_suspension
      : getNrgDateOfSuspension(data.baseCase.nrg_claim?.company_id);
    claim = { ...data.baseCase.nrg_claim, date_of_suspension };
  } else if (productId === Product_Enum.Nflx) {
    claim = { ...data.baseCase.nflx_claim };
    claimAmount = getNflxClaimAmount(data.baseCase) ?? activeOffer.claim_amount;
    offerAmount = getNflxOfferAmount(data.baseCase) ?? activeOffer.offer_amount;
  } else if (productId === Product_Enum.Fit) {
    const activeOffer = getActiveOffer(data.baseCase);
    const tmpClaim = { ...data.baseCase.fit_claims[0] } as FitClaim;
    if (!tmpClaim.lockdown_months) {
      tmpClaim.lockdown_months = {
        "1": [],
        "2": [],
        "3": [],
      };
    }
    claim = { ...tmpClaim };
    claimAmount = round(activeOffer?.claim_amount ?? getFitClaimAmount(tmpClaim));
    offerAmount = round(
      activeOffer?.offer_amount ?? getFitOfferAmount(getFitClaimAmount(tmpClaim))
    );
  }

  return { claim: claim as Claim, claimAmount, offerAmount };
};

import {
  AnchorButton,
  ButtonGroup,
  Card,
  Classes,
  Divider,
  Elevation,
  H5,
  H6,
  Intent,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import "./style.scss";
import { humanizeDate } from "helpers/utils";
import { humanizeString } from "helpers/humanize-string";
import { URL_LEGAL_CASES } from "../../../../constants";

interface IProps {
  page: string;
  aliasId: string;
  state: string;
  createdAt: string;
  caseId?: string;
  updatedAt?: string;
  nextCase?: string;
  prevCase?: string;
}

const Subject: React.FC<{ text: string }> = ({ text }) => (
  <H6 className={Classes.UI_TEXT}>{text}</H6>
);

export const Header: React.FunctionComponent<IProps> = ({
  page,
  state,
  createdAt,
  aliasId,
  updatedAt,
  nextCase,
  prevCase,
}) => {
  return (
    <Card elevation={Elevation.ONE} className="legal-header">
      <div className="container">
        <div className="item">
          <H5>
            {page} / #{aliasId}
          </H5>
        </div>
        <Divider />
        <div className="item">
          <Subject text="Status" />
          <div>
            <Tag intent={Intent.SUCCESS}>{humanizeString(state)}</Tag>
          </div>
        </div>
        <Divider />
        <div className="item">
          <Subject text="Started At" />
          <div>
            <b>{humanizeDate(createdAt)}</b>
          </div>
        </div>

        {updatedAt && (
          <>
            <Divider />
            <div className="item">
              <Subject text="Last Updated" />
              <div>
                <b>{humanizeDate(updatedAt)}</b>
              </div>
            </div>
          </>
        )}
        <div className="item align-right">
          <ButtonGroup>
            <AnchorButton
              icon="chevron-left"
              intent={Intent.SUCCESS}
              href={`${URL_LEGAL_CASES}/${nextCase}`}
              disabled={!nextCase}
              large={true}
            />
            <AnchorButton
              rightIcon="chevron-right"
              href={`${URL_LEGAL_CASES}/${prevCase}`}
              disabled={!prevCase}
              intent={Intent.SUCCESS}
              large={true}
            />
          </ButtonGroup>
        </div>
      </div>
    </Card>
  );
};

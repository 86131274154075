import { ITreeNode } from "@blueprintjs/core";
import { URL_LEGAL_CASES, URL_LEGAL_LAWSUITS } from "../../../constants";
import { NodeData } from "./node-data";
import { IS_EXPANDED } from ".";

export const LEGAL_SERVICES_MENU_ID = "legal-services-menu";

export const getLegalServiceNode = (): ITreeNode<NodeData> => {
  let isExpanded;
  const savedIsExpanded = localStorage.getItem(`${LEGAL_SERVICES_MENU_ID}${IS_EXPANDED}`);
  if (savedIsExpanded) {
    isExpanded = savedIsExpanded === "true";
  } else {
    isExpanded = true;
  }

  return {
    id: LEGAL_SERVICES_MENU_ID,
    isExpanded: isExpanded,
    label: <strong>Legal Service</strong>,
    childNodes: [
      {
        id: `${LEGAL_SERVICES_MENU_ID}__cases`,
        icon: "person",
        label: "Cases",
        hasCaret: false,
        isExpanded: true,
        nodeData: {
          link: URL_LEGAL_CASES,
        },
      },
      {
        id: `${LEGAL_SERVICES_MENU_ID}__lawsuits`,
        icon: "take-action",
        label: "Lawsuits",
        hasCaret: false,
        isExpanded: true,
        nodeData: {
          link: URL_LEGAL_LAWSUITS,
        },
      },
    ],
  };
};

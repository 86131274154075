import {
  Button,
  Checkbox,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  Position,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import qs from "query-string";
import React, { FormEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LAWSUIT_STATUSES, VERTICALS } from "services/legal-service/pages/constants";
import "./style.scss";

interface IFilterItem {
  name: string;
  value: string;
  source: string[];
}

interface IFiltersState {
  aliasId: boolean;
  vertical: boolean;
  debtor: boolean;
  court: boolean;
  lawyer: boolean;
  status: boolean;
  [key: string]: boolean;
}

interface IValuesState {
  aliasId: string;
  vertical: string;
  debtor: string;
  court: string;
  lawyer: string;
  status: string;
  [key: string]: string;
}

interface IProps {
  viewUrl: string;
  defaultStatus: string;
}

const filterItems: IFilterItem[] = [
  { name: "Alias ID", value: "aliasId", source: [] },
  { name: "Vertical", value: "vertical", source: VERTICALS },
  { name: "Debtor", value: "debtor", source: [] },
  { name: "Court", value: "court", source: [] },
  { name: "Lawyer", value: "lawyer", source: [] },
  { name: "Status", value: "status", source: LAWSUIT_STATUSES },
];

const FilterLabel: React.FC<{ text: string }> = ({ text }) => {
  return <InputGroup name={text} placeholder={text} disabled={true} fill={true} />;
};

export const LawsuitFilter: React.FC<IProps> = ({ viewUrl, defaultStatus }) => {
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = qs.parse(search);

  const calledOnce = React.useRef(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFiltersState>({
    aliasId: false,
    vertical: false,
    debtor: false,
    court: false,
    lawyer: false,
    status: false,
  });
  const [filtersValue, setFiltersValue] = useState<IValuesState>({
    aliasId: "",
    vertical: "",
    debtor: "",
    court: "",
    lawyer: "",
    status: "",
  });

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }
    const f = {
      ...filters,
    };
    const v = { ...filtersValue };

    Object.keys(urlParams).forEach((key: string) => {
      if (filters[key] !== undefined && urlParams[key]) {
        f[key] = true;
        v[key] = urlParams[key] as string;
      }
    });

    // If there are more than one state filter value coming from query params -
    // disable state filter by default.
    const stateFilterHasMoreThanOneValue = v.status && v.status.split(",").length > 1;
    if (stateFilterHasMoreThanOneValue) {
      v.status = "";
      f.status = false;
    }

    setFilters(f);
    setFiltersValue(v);
    calledOnce.current = true;
  }, [filters, filtersValue, urlParams]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, name: string) => {
    setFiltersValue((prevState) => ({
      ...prevState,
      [name]: e.currentTarget.value,
    }));
  };

  const handleChange = (value: string, name: string) => {
    setFiltersValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    let params: { [key: string]: string } = {};
    Object.keys(filters).forEach((filter: string) => {
      if (filters[filter] === true && filtersValue[filter].length > 0) {
        params[filter] = filtersValue[filter];
      }
    });

    history.push({
      pathname: viewUrl,
      search: qs.stringify(params),
    });
    setIsOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleCheckboxChange = (e: FormEvent<HTMLInputElement>, item: IFilterItem) => {
    setFiltersValue({ ...filtersValue, [item.value]: item.source[0] });
    setFilters({ ...filters, [item.value]: e.currentTarget.checked });
  };

  return (
    <Popover2
      isOpen={isOpen}
      content={
        <Menu>
          {filterItems.map((item) =>
            item.source.length ? (
              <div key={item.value} className="menu-item">
                <Checkbox
                  checked={filters[item.value]}
                  onChange={(e) => handleCheckboxChange(e, item)}
                />
                {filters[item.value] ? (
                  <HTMLSelect
                    value={filtersValue[item.value]}
                    disabled={!filters[item.value]}
                    options={item.source}
                    onChange={(e) => handleSelectChange(e, item.value)}
                    fill={true}
                  />
                ) : (
                  <FilterLabel text={item.name} />
                )}
              </div>
            ) : (
              <div key={item.value} className="menu-item">
                <Checkbox
                  checked={filters[item.value]}
                  onChange={(e) => {
                    setFilters({ ...filters, [item.value]: e.currentTarget.checked });
                  }}
                />
                {filters[item.value] ? (
                  <InputGroup
                    name={item.value}
                    value={filtersValue[item.value]}
                    onChange={(e) => handleChange(e.currentTarget.value, e.currentTarget.name)}
                    disabled={!filters[item.value]}
                    placeholder={item.name}
                    fill={true}
                    onKeyDown={handleKeyDown}
                  />
                ) : (
                  <FilterLabel text={item.name} />
                )}
              </div>
            )
          )}
          <div className="filter-btn">
            <Button text="Filter" onClick={handleSubmit} intent={Intent.SUCCESS} />
          </div>
        </Menu>
      }
      placement={Position.BOTTOM}
    >
      <Button rightIcon="caret-down" text="Filter By" onClick={() => setIsOpen(!isOpen)} />
    </Popover2>
  );
};

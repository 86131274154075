export const IS_ESTIMATED_CLAIM_AMOUNT_OPTIONS = [
  { value: "false", label: "Actual Claim Amount" },
  { value: "true", label: "Estimated Claim Amount" },
];

export const FLIGHT_TYPE_OPTIONS = [
  { value: "ROUND_TRIP", label: "Round trip" },
  { value: "INBOUND_TRIP", label: "Inbound trip " },
  { value: "OUTBOUND_TRIP", label: "Outbound trip" },
];

export const PLACEHOLDER_FLIGHT = {
  flight_number: "",
  flight_date: "",
  departure_airport: "",
  arrival_airport: "",
};

export const PLACEHOLDER_PERSON = {
  name: "",
  ticket_number: "",
};

export const MAX_FLIGHT_COUNT = 6;
export const MAX_PERSONS_COUNT = 5;

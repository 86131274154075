import React from "react";
import { Card, Elevation, H5, HTMLTable, Intent, Spinner, Tag } from "@blueprintjs/core";

import { dateFormatter } from "helpers/dateFormatter";
import { Document } from "services/legal-service/types";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { useAdminGetSignedUrlsQuery } from "types/graphql.types";
import ViewAndDownloadLink from "../ViewAndDownloadLink";
import "./style.scss";

interface IProps {
  documents: Document[];
}

export const DocumentsList: React.FunctionComponent<IProps> = ({ documents }) => {
  const input = {
    download: true,
    documents: documents.map((doc) => {
      return { key: doc.file.name, type: doc.type, displayFileName: doc.file.name };
    }),
  };

  const { data, loading, error } = useAdminGetSignedUrlsQuery({
    variables: {
      input,
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) return <ErrorMessage error={error?.message} />;

  return (
    <Card elevation={Elevation.ONE} className="documents-list base-card">
      <H5>Documents</H5>
      <HTMLTable className="bp3-interactive">
        <thead>
          <tr>
            <th>Document Type</th>
            <th>Uploaded At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.admin_get_signed_urls?.documents.length > 0 &&
            documents.map((doc, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Tag intent={Intent.SUCCESS}>{doc.type}</Tag>
                  </td>
                  <td>{dateFormatter(doc.createdAt)}</td>
                  <td>
                    <ViewAndDownloadLink
                      signedDocument={data?.admin_get_signed_urls?.documents.find(
                        (d: { key: string; type: string }) =>
                          d.key === doc.file.name && d.type === doc.type
                      )}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

import React from "react";
import { MenuItem } from "@blueprintjs/core";

type TProps = {
  url: string;
};

const DownloadOption: React.FC<TProps> = ({ url }) => {
  const handleDownload = () => {
    window.open(url, "_blank");
  };

  return <MenuItem icon="download" text="Download" onClick={handleDownload} />;
};

export default DownloadOption;

import React from "react";
import { AnchorButton } from "@blueprintjs/core";

import { TCommonPopup } from "document-manager/types";
import { FILE_EXTENSION_REGEX } from "document-manager/constants";

type TProps = {
  documentId: string;
  displayName: string;
  previewUrl: string;
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
};

export const Preview: React.FC<TProps> = ({
  documentId,
  displayName,
  previewUrl,
  setDocumentIdAndOpenPopUpBox,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setDocumentIdAndOpenPopUpBox(documentId, "preview");
  };

  return (
    <AnchorButton minimal={true} onClick={handleClick} href={previewUrl}>
      {displayName.replace(FILE_EXTENSION_REGEX, "")}
    </AnchorButton>
  );
};

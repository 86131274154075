import { IActionProps, Intent, Position, Toaster, IconName } from "@blueprintjs/core";

const ERROR_TIMEOUT = 1 * 1000 * 60; // 60 seconds

export const AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.BOTTOM_LEFT,
});

export const successToaster = (message: string, action?: IActionProps, key?: string) => {
  AppToaster.show({ message, intent: Intent.SUCCESS, icon: "tick", action }, key);
};

export const graphQlErrorToaster = (message: string, key?: string) => {
  AppToaster.show(
    { message, intent: Intent.DANGER, icon: "warning-sign", timeout: ERROR_TIMEOUT },
    key
  );
};
export const warningToaster = (
  message: string,
  icon: IconName = "warning-sign",
  action?: IActionProps,
  key?: string
) => {
  AppToaster.show({ message, intent: Intent.WARNING, icon, action }, key);
};

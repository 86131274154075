import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: "https://0671b32b07954571851233b6078533ca@o723518.ingest.sentry.io/5781980",
    environment: process.env.SENTRY_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

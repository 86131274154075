import { Order_By } from "../../types/graphql.types";

const fieldMap: Record<string, string> = {
  id: "orderByAliasId",
  customer: "orderByLastName",
  email: "orderByEmail",
  case_state: "orderByCaseState",
  offer_state: "orderByOfferState",
  claim_amount: "orderByClaimAmount",
  date: "orderByCreatedAt",
  last_update: "orderByUpdatedAt",
};

export const generateOrderByVariableForCases = (sortColumn: string | null, sortOrder: Order_By) => {
  if (sortColumn && fieldMap[sortColumn]) {
    return {
      [fieldMap[sortColumn]]: sortOrder,
    };
  }

  if (sortColumn === "company") {
    return {
      orderByClaim: {
        company: {
          name: sortOrder,
        },
      },
    };
  }

  // default sort
  return { orderByUpdatedAt: sortOrder };
};

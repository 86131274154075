import { HTMLSelect } from "@blueprintjs/core";
import React, { useCallback } from "react";

const perPageSelectOptions = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

export const PerPage: React.FC<{ perPage: number; onChange: (v: number) => void }> = ({
  perPage,
  onChange,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(parseInt(e.currentTarget.value));
    },
    [onChange]
  );

  return (
    <div className="per-page">
      <HTMLSelect value={perPage} options={perPageSelectOptions} onChange={handleChange} />
    </div>
  );
};

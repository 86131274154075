import {
  GroupSchema,
  ValuePointer,
  OptionHandler,
  FormGroupSchema,
  HandlerSchema,
} from "process-ui-builder/config/types";

export function isValuePointer(value: any): value is ValuePointer {
  return value && typeof value.path === "string";
}

export function isCustomComponentGroup(group: GroupSchema) {
  return group.type === "customComponent";
}

export function isOptionHandler(options: any): options is OptionHandler {
  return options.type === "handler";
}

export function isFormGroup(groupSchema: GroupSchema): groupSchema is FormGroupSchema {
  return groupSchema.type === "form";
}

export function isHandler(schema: any): schema is HandlerSchema {
  return schema && schema.type === "handler" && typeof schema.handler === "string";
}

import React, { useContext, useState } from "react";
import { Button, Intent } from "@blueprintjs/core";

import { DocumentManagerContext } from "document-manager";
import { DOC_TYPES_ALLOWED } from "document-manager/constants";
import { TSelectOptions } from "document-manager/types";

import { UploadModal } from "./modals";

import "./styles.scss";

type TProps = {
  categories: TSelectOptions;
};

export const SelectFilesButton: React.FC<TProps> = ({ categories }) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const { allowUpload } = useContext(DocumentManagerContext);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [preUploadedDocuments, setPreUploadedDocuments] = useState<File[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const handleOnChange = ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
      setPreUploadedDocuments(Array.from(files));
      setIsModalOpen(true);
      setIsUploading(false);
    }
  };

  const handleOnClose = () => {
    setIsModalOpen(false);
    setPreUploadedDocuments([]);
    resetInput();
  };

  const resetInput = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  return (
    <>
      <input
        className="upload-input"
        type="file"
        name="upload-documents"
        accept={DOC_TYPES_ALLOWED.join(",")}
        ref={inputFile}
        onChange={handleOnChange}
        multiple
      />
      <Button
        text="Upload Documents"
        intent={Intent.PRIMARY}
        large
        onClick={onButtonClick}
        loading={isUploading}
        disabled={!allowUpload}
      />
      <UploadModal
        files={preUploadedDocuments}
        onClose={handleOnClose}
        isOpen={isModalOpen}
        categories={categories}
      />
    </>
  );
};

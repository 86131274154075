import React from "react";
import { NonIdealState } from "@blueprintjs/core";

import { getFileExtension } from "document-manager/utils";
import { PREVIEW_AVAILABLE } from "document-manager/constants";

export type TProps = {
  url: string;
  fileName: string;
};

export const PreviewBox: React.FC<TProps> = ({ url, fileName }) => {
  const fileExtension = getFileExtension(fileName).toLowerCase();

  if (url === "" || !PREVIEW_AVAILABLE.includes(fileExtension)) {
    return (
      <NonIdealState
        title="Preview not available"
        description={`Preview is not available for this document ${fileName}. Please download it in order to view it.`}
        className="preview-box"
      />
    );
  }
  return fileExtension === "pdf" ? (
    <iframe src={url} title={fileName} className="preview-box" />
  ) : (
    <div className="preview-box">
      <img src={url} alt={fileName} />
    </div>
  );
};

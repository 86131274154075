import { H5 } from "@blueprintjs/core";
import React from "react";
import { DownloadButton } from "./DownloadButton";

export const Header: React.FC = () => {
  return (
    <div className="card-header">
      <H5>Documents Manager</H5>
      <DownloadButton />
    </div>
  );
};

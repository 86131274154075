import React, { useMemo } from "react";

import Document from "assets/icons/document.svg";
import Image from "assets/icons/image.svg";
import Video from "assets/icons/video.svg";
import Pdf from "assets/icons/pdf.svg";
import Zip from "assets/icons/zip.svg";
import Excel from "assets/icons/excel.svg";

type TGetIcon = {
  extension: string;
};

export const GetIcon: React.FC<TGetIcon> = ({ extension }) => {
  const iconSrc = useMemo(() => {
    const ext = extension.toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
      return Image;
    } else if (["avi", "mov", "mp4"].includes(ext)) {
      return Video;
    } else if (["pdf"].includes(ext)) {
      return Pdf;
    } else if (["xls", "xlxs", "csv"].includes(ext)) {
      return Excel;
    } else if (["zip"].includes(ext)) {
      return Zip;
    } else {
      return Document;
    }
  }, [extension]);

  return <img src={iconSrc} alt={extension} title={extension} />;
};

import React, { useEffect, useState } from "react";
import { Button, Dialog } from "@blueprintjs/core";
import { findIndex } from "lodash-es";

import { PreviewBox } from "./PreviewBox";
import { TDocument } from "document-manager/types";

import "./styles.scss";

export type TProps = {
  documentId: string;
  documentsList: TDocument[];
  onHide: () => void;
  isOpen: boolean;
};

export const PreviewModal: React.FC<TProps> = ({ onHide, isOpen, documentId, documentsList }) => {
  const [index, setIndex] = useState<number>(-1);

  useEffect(() => {
    if (index < 0) {
      setIndex(findIndex(documentsList, ["id", documentId]));
      return;
    }
  }, [index, documentsList, documentId]);

  if (!documentId || index === -1) {
    return <></>;
  }

  const handleNext = () => {
    if (index < documentsList.length - 1) {
      setIndex(index + 1);
    }
  };
  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <Dialog
      title={documentsList[index].displayFileName}
      icon="document"
      onClose={onHide}
      isOpen={isOpen}
      className={"preview-modal"}
    >
      <div className="preview-modal--body">
        <Button
          disabled={index === 0}
          icon="chevron-left"
          onClick={handlePrevious}
          className="left-arrow"
          large={true}
        />
        {documentsList.length && (
          <PreviewBox
            url={documentsList[index].previewUrl}
            fileName={documentsList[index].displayFileName}
          />
        )}
        <Button
          disabled={index === documentsList.length - 1}
          onClick={handleNext}
          icon="chevron-right"
          className="right-arrow"
          large={true}
        />
      </div>
    </Dialog>
  );
};

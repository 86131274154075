import { Product_Enum } from "types/graphql.types";

export const URL_LEGAL = "/legal";
export const URL_LEGAL_CASE_VIEW = `${URL_LEGAL}/cases/:id`;
export const URL_LEGAL_CASES = `${URL_LEGAL}/cases`;
export const URL_LEGAL_LAWSUITS = `${URL_LEGAL}/lawsuits`;
export const URL_LEGAL_LAWSUIT_VIEW = `${URL_LEGAL}/lawsuits/:id`;

export const URL_FINANCE = "/finance";
export const URL_FINANCE_TRANSACTIONS = `${URL_FINANCE}/bank-transactions`;
export const URL_FINANCE_TRANSACTIONS_IMPORT = `${URL_FINANCE_TRANSACTIONS}/import`;
export const URL_FINANCE_TRANSACTION_VIEW = `${URL_FINANCE_TRANSACTIONS}/:id`;
export const URL_FINANCE_CUSTOMER_PAYOUTS = `${URL_FINANCE}/customer-payouts`;

export const DOC_TYPES_ALLOWED = [
  "application/pdf",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/vnd.ms-excel", //.xlx files
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //.xlsx files
  "video/quicktime", //.mov videos
  "video/avi", //.avi videos
  "video/mp4",
  "text/csv",
];

export enum ApolloContextName {
  LegalService = "legal-service-apollo-context",
  PlatformService = "platform-service-apollo-context",
  FinanceService = "finance-service-apollo-context",
  ClaimPurchasingService = "claim-purchasing-service-apollo-context",
}

export enum DeploymentEnvironment {
  Production = "production",
  QA1 = "qa1",
  QA2 = "qa2",
  Development = "development",
}

export const OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY = "overridden-backend-server";

export const BACKEND_SERVER_URLS: Record<
  DeploymentEnvironment,
  Record<ApolloContextName, string>
> = {
  [DeploymentEnvironment.Production]: {
    [ApolloContextName.LegalService]: "https://graphql.rightnow.de/legal-service/graphql",
    [ApolloContextName.PlatformService]: "https://graphql.rightnow.de/platform-service/graphql",
    [ApolloContextName.FinanceService]: "https://graphql.rightnow.de/finance-service/graphql",
    [ApolloContextName.ClaimPurchasingService]: "https://graphql.rightnow.de/v1/graphql",
  },
  [DeploymentEnvironment.QA1]: {
    [ApolloContextName.LegalService]: "https://qa1.graphql.r-n.io/legal-service/graphql",
    [ApolloContextName.PlatformService]: "https://qa1.graphql.r-n.io/platform-service/graphql",
    [ApolloContextName.FinanceService]: "https://qa1.graphql.r-n.io/finance-service/graphql",
    [ApolloContextName.ClaimPurchasingService]: "https://qa1.graphql.r-n.io/v1/graphql",
  },
  [DeploymentEnvironment.QA2]: {
    [ApolloContextName.LegalService]: "https://qa2.graphql.r-n.io/legal-service/graphql",
    [ApolloContextName.PlatformService]: "https://qa2.graphql.r-n.io/platform-service/graphql",
    [ApolloContextName.FinanceService]: "https://qa2.graphql.r-n.io/finance-service/graphql",
    [ApolloContextName.ClaimPurchasingService]: "https://qa2.graphql.r-n.io/v1/graphql",
  },
  [DeploymentEnvironment.Development]: {
    [ApolloContextName.LegalService]: "http://localhost:3300/graphql",
    [ApolloContextName.PlatformService]: "http://localhost:3100/graphql",
    [ApolloContextName.FinanceService]: "http://localhost:3200/graphql",
    [ApolloContextName.ClaimPurchasingService]: "http://localhost:8080/v1/graphql",
  },
};

export enum PAYMENT_ON_HOLD_REASON {
  MANUAL_IBAN_CHECK = "MANUAL_IBAN_CHECK",
  FRAUD_CHECK = "FRAUD_CHECK",
  DUPLICATE_CUSTOMER_ADDRESS = "DUPLICATE_CUSTOMER_ADDRESS",
  DUPLICATE_NETFLIX_ACCOUNT_EMAIL = "DUPLICATE_NETFLIX_ACCOUNT_EMAIL",
}

export const DEFAULT_PAYMENT_ON_HOLD_REASONS = [
  PAYMENT_ON_HOLD_REASON.MANUAL_IBAN_CHECK,
  PAYMENT_ON_HOLD_REASON.FRAUD_CHECK,
];

export const VERTICAL_PAYMENT_ON_HOLD_REASONS = {
  [Product_Enum.Gff as string]: [PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS],
  [Product_Enum.Nflx as string]: [
    PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS,
    PAYMENT_ON_HOLD_REASON.DUPLICATE_NETFLIX_ACCOUNT_EMAIL,
  ],
  [Product_Enum.Nrg as string]: [PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS],
  [Product_Enum.Ocg as string]: [PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS],
  [Product_Enum.Pkv as string]: [PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS],
  [Product_Enum.Gdpr as string]: [
    PAYMENT_ON_HOLD_REASON.MANUAL_IBAN_CHECK,
    PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS,
  ],
};

export const OTHER = "OTHER";

// State constants
// Should be consistent with `packages/verticals-common/src/constants.ts`
export const ACCEPTED = "ACCEPTED";
export const ASSIGNMENT_EXPIRED = "ASSIGNMENT_EXPIRED";
export const ASSIGNMENT_RECEIVED = "ASSIGNMENT_RECEIVED";
export const ASSIGNMENT_SENT = "ASSIGNMENT_SENT";
export const BLOCK_LISTED = "BLOCK_LISTED";
export const CANCELLED = "CANCELLED";
export const CREATED = "CREATED";
export const DELETED = "DELETED";
export const DUPLICATE = "DUPLICATE";
export const EXPIRED = "EXPIRED";
export const FRAUD = "FRAUD";
export const GDPR_DELETED = "GDPR_DELETED";
export const INITIALIZED = "INITIALIZED";
export const NEW = "NEW";
export const PAYMENT_INITIATED = "PAYMENT_INITIATED";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const PAYMENT_PENDING = "PAYMENT_PENDING";
export const PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL";
export const REFUSED = "REFUSED";
export const REJECTED = "REJECTED";
export const REJECTION_CONFIRMED = "REJECTION_CONFIRMED";
export const REVOKED = "REVOKED";
export const SENT = "SENT";
export const WAITING_DOCUMENTS = "WAITING_DOCUMENTS";
export const WAITING_FTS_DOCUMENTS = "WAITING_FTS_DOCUMENTS";

export const READ_ONLY_VERTICALS = [
  Product_Enum.Fit,
  Product_Enum.Gff,
  Product_Enum.Nflx,
  Product_Enum.Nrg,
  Product_Enum.Pkv,
];

import { INSURANCE_INCREASED_DATE } from "./const";

export const getIsEstimateClaimAmountValue: (
  value: boolean | null | undefined
) => string | undefined = function (value: boolean | null | undefined) {
  if (value === undefined) {
    return undefined;
  }
  return value === true ? ("yes" as string) : ("no" as string);
};

export const getInsuranceIncreaseDate: (value: string) => Date | undefined = function (
  value: string
) {
  return INSURANCE_INCREASED_DATE[value] ? new Date(INSURANCE_INCREASED_DATE[value]) : undefined;
};

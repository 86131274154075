import { Card, Collapse, H5, Icon, Intent, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ValueContext } from "process-ui-builder";
import { DateField } from "process-ui-builder/components/form-fields/date";
import { TextField } from "process-ui-builder/components/form-fields/text";
import { useContext, useEffect, useState } from "react";
import { InsuredPerson } from "types/custom.types";
import "./style.scss";

interface IProps {
  person?: InsuredPerson;
  count: number;
  isOpen: boolean;
  disableCollapse?: boolean;
  onDeletePerson?: (count: number) => void;
  readOnly?: boolean;
}

const Person: React.FunctionComponent<IProps> = ({
  person,
  count,
  isOpen,
  disableCollapse,
  onDeletePerson,
  readOnly = false,
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(isOpen);
  const valueContext = useContext(ValueContext);
  const [tariffs, setTariffs] = useState(
    _.get(valueContext, `case.claim.insured_persons[${count}].tariffs`) || []
  );

  const toggleCollapsible = () => {
    if (!disableCollapse) {
      setIsCollapsibleOpen(!isCollapsibleOpen);
    }
  };

  useEffect(() => {
    setIsCollapsibleOpen(isOpen);
  }, [isOpen]);

  const setNewTariffValue = action((tariffsArray: string[]) => {
    runInAction(() => {
      _.set(valueContext, `case.claim.insured_persons[${count}].tariffs`, tariffsArray);
      setTariffs(tariffsArray);
    });
  });

  useEffect(() => {
    setTariffs(_.get(valueContext, `case.claim.insured_persons[${count}].tariffs`) || []);
  }, [valueContext, count]);

  // below code is to make tariff Array if length 5 so that mobx can set value without any error or warning
  if (tariffs.length < 5) {
    const emptyStringArray: string[] = [];
    for (let index = 5; index > tariffs.length; index--) {
      emptyStringArray.push("");
    }
    const _tariffs = [...tariffs];
    const newTariffArray = _tariffs.concat(emptyStringArray);
    setNewTariffValue(newTariffArray);
    return <Spinner />;
  }

  const addEmptyValueToTariff = action(() => {
    runInAction(() => {
      const _tariffs = [...tariffs];
      _tariffs.push("");
      _.set(valueContext, `case.claim.insured_persons[${count}].tariffs`, _tariffs);
    });
  });

  const getTariffs = () => {
    const tariffsArrayComponent: React.ReactNode[] = [];
    const tariffsFiltered = tariffs.filter((tariff: string) => tariff !== "");
    for (let index = 0; index <= tariffsFiltered.length && index < 5; index++) {
      if (tariffs[index] === undefined) {
        addEmptyValueToTariff();
        break;
      }
      tariffsArrayComponent.push(
        <TextField
          key={index}
          config={{
            id: `tariff-${count}-${index}`,
            label: `Tariff ${index + 1}`,
            type: "text",
            validation: { required: index === 0 },
            value: { path: `case.claim.insured_persons[${count}].tariffs[${index}]` },
          }}
          readOnly={readOnly}
        />
      );
    }
    return tariffsArrayComponent;
  };

  return (
    <Card interactive={true} elevation={1} className="person">
      {person && onDeletePerson && !readOnly && (
        <Icon
          icon="cross"
          intent={Intent.DANGER}
          iconSize={25}
          className={"person--delete-icon"}
          onClick={() => onDeletePerson(count)}
        />
      )}

      <H5 onClick={toggleCollapsible}>
        <Icon icon={!isCollapsibleOpen ? "caret-right" : "caret-down"} iconSize={18} />
        {count === 0 ? "Main Person" : `Person ${count + 1}`}
      </H5>
      {person && (
        <Collapse isOpen={!isCollapsibleOpen}>
          <pre onClick={toggleCollapsible}>
            Name: {person.name}
            <br />
            Insurance Number: {person.insurance_number}
          </pre>
        </Collapse>
      )}
      <Collapse isOpen={isCollapsibleOpen}>
        <TextField
          config={{
            id: `person_name_${count}`,
            label: "Name",
            type: "text",
            validation: { required: true },
            value: { path: `case.claim.insured_persons[${count}].name` },
          }}
          readOnly={readOnly}
        />
        {getTariffs()}

        <DateField
          config={{
            id: `insured_since_${count}`,
            label: "Insured Since",
            type: "date",
            validation: { required: true },
            value: { path: `case.claim.insured_persons[${count}].insured_since` },
          }}
          readOnly={readOnly}
        />
        <TextField
          config={{
            id: `insurance_number_${count}`,
            label: "Insurance Number",
            type: "text",
            validation: { required: true },
            value: { path: `case.claim.insured_persons[${count}].insurance_number` },
          }}
          readOnly={readOnly}
        />
      </Collapse>
    </Card>
  );
};
export default observer(Person);

import { humanizeString } from "helpers/humanize-string";
import { LegalCaseStatus } from "../../constants";

export const filterStatusOptions = Object.values(LegalCaseStatus).map((value) => {
  return {
    label: humanizeString(value),
    value,
  };
});

export const getStatusFromUrl = (status: string) => status.split(",");

import Debug from "app-debug";

export const SET_AUTH_TOKEN = "AUTH/SET_AUTH_TOKEN";
export const REMOVE_AUTH_TOKEN = "AUTH/REMOVE_AUTH_TOKEN";

export interface SetAuthTokenAction {
  type: typeof SET_AUTH_TOKEN;
  payload: {
    token: string;
  };
}

export interface RemoveAuthTokenAction {
  type: typeof REMOVE_AUTH_TOKEN;
}

export type AuthActionTypes = SetAuthTokenAction | RemoveAuthTokenAction;

export function setAuthToken(token: string) {
  return {
    type: SET_AUTH_TOKEN,
    payload: { token },
  };
}

export function removeAuthToken() {
  Debug.debug(`${SET_AUTH_TOKEN}`);

  return {
    type: REMOVE_AUTH_TOKEN,
  };
}

import React from "react";
import { HTMLTable } from "@blueprintjs/core";

import { Head } from "./head";
import { Body } from "./body";

import "./styles.scss";

export const DocumentTable: React.FC = () => {
  return (
    <HTMLTable className="document-table">
      <Head />
      <Body />
    </HTMLTable>
  );
};

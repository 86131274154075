import React from "react";
import { Button } from "@blueprintjs/core";

type TProps = {
  reorderDocuments: (oldIndex: number, newIndex: number) => void;
  currentIndex: number;
  noOfDocuments: number;
};

export const SortArrows: React.FC<TProps> = ({ reorderDocuments, currentIndex, noOfDocuments }) => {
  const moveUp = () => {
    reorderDocuments(currentIndex, currentIndex - 1);
  };
  const moveDown = () => {
    reorderDocuments(currentIndex, currentIndex + 1);
  };

  return (
    <div className="drag-arrow-wrapper">
      <Button minimal={true} icon="arrow-up" disabled={currentIndex === 0} onClick={moveUp} />
      <Button
        minimal={true}
        icon="arrow-down"
        disabled={currentIndex === noOfDocuments - 1}
        onClick={moveDown}
      />
    </div>
  );
};

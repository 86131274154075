import { FormGroup } from "process-ui-builder/components/form-group";
import React from "react";
import { getClaimConfig } from "services/legal-service/pages/case-view/config";
import PkvClaim from "./PkvClaim";
import { Product_Enum } from "types/graphql.types";

interface IProps {
  vertical: string;
}

export const ClaimData: React.FC<IProps> = ({ vertical }) => {
  return (
    <React.Fragment>
      {vertical === Product_Enum.Pkv ? (
        <PkvClaim />
      ) : (
        <FormGroup config={getClaimConfig(vertical)} readOnly={true} />
      )}
    </React.Fragment>
  );
};

import { Callout, Intent, Spinner } from "@blueprintjs/core";
import { CREATED, READ_ONLY_VERTICALS, SENT } from "../../constants";
import ActiveAssignmentDetails from "components/ActiveAssignmentDetails";
import AssignmentHistoryCard from "components/AssignmentHistoryCard";
import AssignmentPreviews from "components/AssignmentPreviews";
import CaseDocumentList from "components/CaseDocumentList";
import GffClaimData from "components/GffClaimData";
import NflxClaimData from "components/NflxClaimData";
import PkvClaimData from "components/PkvClaimData";
import OfferHistoryCard from "components/OfferHistoryCard";
import PaymentCard from "components/PaymentCard";
import PaymentHistoryCard from "components/PaymentHistoryCard";
import { ActiveOfferDetails, Header } from "components/gdpr";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { graphQlErrorToaster, successToaster, warningToaster } from "helpers/toaster";
import dayjs from "dayjs";
import {
  convertValueToNumber,
  getLocaleFromFields,
  getNumberValueFromLocale,
} from "helpers/moneyFormatter";
import get from "lodash/get";
import { observable } from "mobx";
import { handleOnNextCase } from "pages/utils";
import UIBuilder, { ValueContext } from "process-ui-builder";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Product_Enum,
  useAdminOfferAddressChangeMutation,
  useAdminUpdateClaimMutation,
  useAdminUpdateCustomerMutation,
  useGetCaseDataQuery,
  useGetNextBaseCaseLazyQuery,
} from "types/graphql.types";
import { DELETED, GDPR_DELETED, NEW, WAITING_DOCUMENTS } from "../../constants";
import { RootState } from "../../reducers";
import getUiConfig from "./config";
import { EMPTY_ADDRESS, EMPTY_BANK_ACCOUNT, EMPTY_BANK_TRANSACTION } from "./constants";
import { getClaimAndAmounts, useGetCompanyOptionsLazyQuery } from "./helpers";
import InfoCards from "./info-cards";
import { StepOneActions, StepThreeActions, StepTwoActions } from "./step-actions";
import { Address, BankAccount, BankTransaction, Claim, ClaimUpdate, Customer } from "./types";
import {
  canChangeOfferAddress,
  canCreateOffer,
  canUpdateClaim,
  canUpdateCustomer,
  getActiveAssignment,
  getActiveOffer,
  getCurrentStep,
  getLastPayment,
  getNextCaseStates,
  isFitClaim,
  isGffClaim,
  isNrgClaim,
  isOcgClaim,
  isPkvClaim,
  isPlaceholderFlight,
  isPlaceholderPerson,
  sanitizeGffFlights,
  sanitizeGffPerson,
  sanitizeInsuredPersons,
  validatePkvClaim,
} from "./utils";

interface RouteParams {
  id: string;
}

const BaseCaseProcessor: React.FC = () => {
  const isCaseAgentSupervisor =
    useSelector((state: RootState) => state.roles.roles?.includes("case_agent_supervisor")) ||
    false;

  const { id } = useParams<RouteParams>();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const history = useHistory();
  const [getNextCase, { data: nextCaseData, error: nextCaseError }] = useGetNextBaseCaseLazyQuery();

  const { data, loading, error, refetch } = useGetCaseDataQuery({
    variables: {
      id,
    },
  });

  const [getCompany, { data: companyList }] = useGetCompanyOptionsLazyQuery(
    data?.baseCase?.product_id.toLowerCase()!
  );

  const [updateCustomerMutation] = useAdminUpdateCustomerMutation();
  const [offerAddressChangeMutation] = useAdminOfferAddressChangeMutation();
  const [updateClaimMutation] = useAdminUpdateClaimMutation();

  const [$customer, setCustomer] = useState<Customer>({
    email: "",
    first_name: "",
    last_name: "",
    newsletter_optin: false,
  });

  const [$claim, setClaim] = useState<Claim>({
    id: "",
    company_id: "",
    has_paid: false,
    other_company: "",
  });

  const [$bankTransaction, setBankTransaction] = useState<BankTransaction>(EMPTY_BANK_TRANSACTION);

  const [$address, setAddress] = useState<Address>(EMPTY_ADDRESS);
  const [$bankAccount, setBankAccount] = useState<BankAccount>(EMPTY_BANK_ACCOUNT);
  const [$activeOffer, setActiveOffer] = useState<{ claim_amount: number; offer_amount: number }>({
    claim_amount: 0,
    offer_amount: 0,
  });
  const [productId, setProductId] = useState<Product_Enum>();
  const isProductReadOnly =
    data?.baseCase?.product_id && READ_ONLY_VERTICALS.includes(data.baseCase.product_id);

  useEffect(() => {
    if (data?.baseCase) {
      setCustomer(observable(data.baseCase.customer));
      setProductId(data.baseCase.product_id);
      const activeOffer = getActiveOffer(data.baseCase);

      const { claim, claimAmount, offerAmount } = getClaimAndAmounts(data, {
        offer_amount: $activeOffer.offer_amount,
        claim_amount: $activeOffer.claim_amount,
      });

      setClaim(observable({ ...claim }));

      const activeOfferAmount = {
        claim_amount: claimAmount,
        offer_amount: offerAmount,
      };

      setActiveOffer(observable(activeOfferAmount));
      setAddress(observable(activeOffer?.address || EMPTY_ADDRESS));
      setBankAccount(activeOffer?.bank_account || EMPTY_BANK_ACCOUNT);

      setBankTransaction(
        observable((data.fs_bank_transaction_by_pk as BankTransaction) || EMPTY_BANK_TRANSACTION)
      );
    }
  }, [data, $activeOffer.offer_amount, $activeOffer.claim_amount]);

  const nextStates = useMemo(() => getNextCaseStates(data?.baseCase, isCaseAgentSupervisor), [
    isCaseAgentSupervisor,
    data?.baseCase,
  ]);

  useEffect(() => {
    if (productId) {
      getNextCase({
        variables: {
          states: nextStates,
          id,
          productId,
        },
      });
    }
  }, [nextStates, getNextCase, id, productId]);

  const uiConfig = useMemo(() => {
    if (productId) {
      return getUiConfig(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (data?.baseCase?.product_id) {
      getCompany();
    }
  }, [getCompany, data?.baseCase?.product_id]);

  if (loading) return <Spinner />;
  if (error) return <ErrorMessage error={error.message} />;
  if (!data?.baseCase) return <ErrorMessage error={"Case Not Found"} />;
  if (!uiConfig) return <ErrorMessage error={"Product not found"} />;

  const activeOffer = getActiveOffer(data.baseCase);
  const lastPayment = getLastPayment(data.baseCase);
  const activeAssignment = getActiveAssignment(data.baseCase);
  const assignmentHistory = activeOffer?.assignments.map((a) => ({
    createdAt: a.created_at,
    id: a.id,
    state: a.state || "",
    sentFile: a.sent_file || null,
    receivedFile: a.received_file || null,
  }));

  const cannotUpdate = (updateDisallowed: boolean): boolean => {
    if (isProductReadOnly) {
      warningToaster("Cannot perform: Product is read only");
      return true;
    } else if (updateDisallowed) {
      alert("Cannot Perform: Case either has an active offer or not in the right state.");
      return true;
    }
    return false;
  };

  const handlers = {
    updateCustomer: () => {
      if (cannotUpdate(!canUpdateCustomer(data.baseCase))) return;
      setShowLoader(true);

      const variables = {
        input: {
          configName: `${data?.baseCase?.product_id.toLowerCase()}-claim-purchasing`,
          input: {
            case: {
              id: data?.baseCase?.id,
              customer: {
                ...($customer.salutation && { salutation: $customer.salutation }),
                ...($customer.first_name && { firstName: $customer.first_name }),
                ...($customer.last_name && { lastName: $customer.last_name }),
                ...($customer.email && { email: $customer.email }),
                newsletterOptin: $customer.newsletter_optin,
              },
            },
          },
        },
      };

      updateCustomerMutation({
        variables,
      }).then((resp) => {
        if (resp.errors?.length) {
          setShowLoader(false);
          return;
        }
        successToaster("The customer is updated.");
        refetch();
        setShowLoader(false);
      });
    },
    updateClaim: () => {
      if (cannotUpdate(!canUpdateClaim(data.baseCase))) return;
      setShowLoader(true);

      if (isFitClaim($claim)) {
        const { id, company_id, lockdown_months, monthly_amount, has_paid, membership_number } = {
          ...$claim,
        };
        // check and convert monthly amount to number

        let monthlyAmount = monthly_amount as number;
        if (isNaN(monthlyAmount)) {
          const fields = get(uiConfig, "steps[0].groups[1].fields");
          const locale = getLocaleFromFields(fields, "monthly_amount");
          monthlyAmount = getNumberValueFromLocale(monthly_amount, locale);
        }
        if (isNaN(monthlyAmount)) {
          setShowLoader(false);
          graphQlErrorToaster("Please enter a valid monthly amount");
        } else {
          const variables = {
            id,
            lockdownMonths: lockdown_months ? lockdown_months : {},
            hasPaid: has_paid,
            companyId: typeof company_id === "string" ? company_id : "",
            monthlyAmount: monthlyAmount,
            membershipNumber: membership_number,
          };

          //TODO need to test if when FIT product is active again
          handleClaimUpdate(variables);
          setShowLoader(false);
        }
      }
    },
    getCompanies: () => {
      return companyList ? companyList.companies : [];
    },
    updateOcgClaim: () => {
      if (cannotUpdate(!canUpdateClaim(data.baseCase))) return;

      if (isOcgClaim($claim)) {
        setShowLoader(true);
        const { id, company_id, money_spent, is_sports_betting } = {
          ...$claim,
        };

        const moneySpent = convertValueToNumber(money_spent);
        if (isNaN(moneySpent)) {
          setShowLoader(false);
          graphQlErrorToaster("Please enter a valid money spent");
        } else {
          const claim = {
            id,
            companyId: typeof company_id === "string" ? company_id : "",
            moneySpent,
            isSportsBetting: is_sports_betting,
          };
          handleClaimUpdate(claim);
        }
      }
    },
    changeOfferAddress: () => {
      if (cannotUpdate(!canChangeOfferAddress(data.baseCase, isCaseAgentSupervisor))) {
        return;
      } else if (activeOffer) {
        if (!activeAssignment?.state || ![CREATED, SENT].includes(activeAssignment?.state)) {
          graphQlErrorToaster("Assignment has not been created or sent yet");
          return;
        }

        setShowLoader(true);
        offerAddressChangeMutation({
          variables: {
            id: activeOffer.id,
            street: $address.street,
            postalCode: $address.postal_code,
            city: $address.city,
            countryCode: $address.country_code,
            vertical: data.baseCase?.product_id,
          },
        }).then((resp) => {
          if (resp.errors?.length) {
            setShowLoader(false);
            return;
          }
          successToaster("Address is updated and a new assignment is created.");
          refetch();
          setShowLoader(false);
        });
      }
    },
    updatePkvClaim: () => {
      if (cannotUpdate(!canChangeOfferAddress(data.baseCase, isCaseAgentSupervisor))) return;

      if (isPkvClaim($claim)) {
        setShowLoader(true);
        const claim = { ...$claim };
        claim.insured_persons = sanitizeInsuredPersons(claim.insured_persons);
        if (validatePkvClaim(claim)) {
          const variable = {
            id: claim.id,
            companyId: claim.company_id!,
            ...(claim.other_company && { otherCompany: claim.other_company }),
            ...(claim.case_type && { caseType: claim.case_type }),
            ...(claim.source_of_estimation && { sourceOfEstimation: claim.source_of_estimation }),
            ...(typeof claim.is_estimated_claim_amount === "boolean" && {
              isEstimatedClaimAmount: claim.is_estimated_claim_amount,
            }),
            insuredpersons: claim.insured_persons,
            insuranceIncreaseDate: claim.insurance_increase_date
              ? dayjs(claim.insurance_increase_date).format("YYYY-MM-DD")
              : "",
          };

          //TODO need to test if when PKV product is active again
          handleClaimUpdate(variable);
        }
        setShowLoader(false);
      }
    },
    updateGffClaim: () => {
      if (READ_ONLY_VERTICALS.includes(data.baseCase?.product_id!)) {
        alert("Cannot Perform: vertical is read only");
        return;
      }

      if (!canUpdateClaim(data.baseCase)) {
        alert("Cannot Perform: Case either has an active offer or not in the right state.");
        return;
      }
      if (isGffClaim($claim)) {
        const claim = { ...$claim };
        setShowLoader(true);

        const sanitizedFlights = claim.flights && sanitizeGffFlights(claim.flights);
        const sanitizedPersons = claim.persons && sanitizeGffPerson(claim.persons);

        if (!sanitizedFlights && claim.flights && !isPlaceholderFlight(claim.flights[0])) {
          setShowLoader(false);
          alert("Please enter valid Flights information");
          return;
        }
        if (!sanitizedPersons && claim.persons && !isPlaceholderPerson(claim.persons[0])) {
          setShowLoader(false);
          alert("Please enter valid Persons information");
          return;
        }
        claim.flights = sanitizedFlights ? sanitizedFlights : undefined;
        claim.persons = sanitizedPersons ? sanitizedPersons : undefined;

        const variables = {
          id: claim.id,
          companyId: claim.company_id,
          flights: claim.flights,
          persons: claim.persons,
          flightType: claim.flight_type,
          ...(typeof claim.is_estimated_claim_amount === "boolean" && {
            isEstimatedClaimAmount: claim.is_estimated_claim_amount,
          }),
          ...(claim.booking_reference && {
            bookingReference: claim.booking_reference,
          }),
          ...(claim.ticket_amount && {
            ticketAmount: isNaN(claim.ticket_amount)
              ? getNumberValueFromLocale(claim.ticket_amount)
              : claim.ticket_amount,
          }),
        };

        // TODO: need to be tested when we make it active again
        handleClaimUpdate(variables);
        setShowLoader(false);
      }
    },
    updateNrgClaim: () => {
      if (cannotUpdate(!canUpdateClaim(data.baseCase))) {
        return;
      }
      if (isNrgClaim($claim)) {
        warningToaster("Claim could not be updated - product is read only");
      }
    },
  };

  //Handle claim update can be used for all the verticals once mutation is ready to handle.
  const handleClaimUpdate = (claim: ClaimUpdate) => {
    if (isProductReadOnly) {
      warningToaster("Claim could not be updated - product is read only");
      return;
    }

    const variables = {
      input: {
        configName: `${data?.baseCase?.product_id.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            id: data.baseCase?.id,
            claim,
          },
        },
      },
    };

    updateClaimMutation({ variables }).then((resp) => {
      if (resp.errors?.length) {
        setShowLoader(false);
        return;
      }
      successToaster("The claim is updated.");
      refetch();
      setShowLoader(false);
    });
  };

  const onRefetch = async () => {
    refetch &&
      refetch().then(() => {
        setShowLoader(false);
      });
  };

  const customComponents = {
    CaseDocumentList:
      data.baseCase?.state !== GDPR_DELETED && productId ? (
        <CaseDocumentList
          allowRequestDocument={data.baseCase?.state === NEW && !isProductReadOnly}
          allowUpload={!([Product_Enum.Nflx].includes(productId) || !!activeOffer)}
          refetchCase={onRefetch}
          caseId={id}
          productId={productId}
          isCaseRefetchEnabled={data.baseCase?.state === WAITING_DOCUMENTS}
          aliasId={data.baseCase?.alias_id!}
        />
      ) : null,
    ActiveOfferCard: (() => {
      return activeOffer ? (
        <ActiveOfferDetails
          claimAmount={activeOffer.claim_amount}
          offerAmount={activeOffer.offer_amount}
          company={$claim.company_id!}
        />
      ) : null;
    })(),
    OfferHistoryCard: data.baseCase?.offers.length ? (
      <OfferHistoryCard
        offers={data.baseCase.offers.map((o) => ({
          state: o.state,
          amount: o.offer_amount,
          sentAt: o.created_at,
        }))}
      />
    ) : null,
    StepOneActions:
      productId && !isProductReadOnly ? (
        <StepOneActions
          onActionSuccess={onRefetch}
          data={data}
          activeOfferAmounts={$activeOffer}
          claim={$claim}
        />
      ) : (
        <></>
      ),
    StepTwoActions:
      productId && !isProductReadOnly ? (
        <StepTwoActions
          onActionSuccess={onRefetch}
          data={data}
          isCaseAgentSupervisor={isCaseAgentSupervisor}
        />
      ) : (
        <></>
      ),
    StepThreeActions:
      productId && !isProductReadOnly ? (
        <StepThreeActions onActionSuccess={onRefetch} data={data} />
      ) : (
        <></>
      ),
    ActiveAssignmentDetails: (() => {
      return activeAssignment?.sent_file || activeAssignment?.received_file ? (
        <ActiveAssignmentDetails
          assignment={{
            createdAt: activeAssignment.created_at,
            sentAt: activeAssignment.sent_at,
            receivedAt: activeAssignment.received_at,
          }}
        />
      ) : null;
    })(),
    AssignmentHistory: assignmentHistory?.some((ah) => ah.sentFile || ah.receivedFile) ? (
      <AssignmentHistoryCard assignments={assignmentHistory} />
    ) : null,
    AssignmentPreviews: (() => {
      return activeAssignment?.sent_file || activeAssignment?.received_file ? (
        <AssignmentPreviews
          assignment={{
            createdAt: activeAssignment.created_at,
            id: activeAssignment.id,
            sentFile: activeAssignment.sent_file || null,
            receivedFile: activeAssignment.received_file || null,
          }}
        />
      ) : null;
    })(),
    ActivePaymentDetails: lastPayment ? <PaymentCard state={lastPayment.state} /> : null,
    PaymentHistory: activeOffer?.payments.length ? (
      <PaymentHistoryCard payments={activeOffer.payments} />
    ) : null,
    PkvClaimData:
      data.baseCase?.pkv_claim && data.baseCase.pkv_claim !== null ? (
        <PkvClaimData />
      ) : (
        <Callout intent={Intent.DANGER}>ERROR: No claim data available</Callout>
      ),
    NflxClaimData:
      data.baseCase?.nflx_claim && data.baseCase.nflx_claim !== null ? (
        <NflxClaimData />
      ) : (
        <Callout intent={Intent.DANGER}>ERROR: No claim data available</Callout>
      ),
    GffClaimData:
      data.baseCase?.gff_claim && data.baseCase.gff_claim !== null ? (
        <GffClaimData caseState={data.baseCase.state} />
      ) : (
        <Callout intent={Intent.DANGER}>ERROR: No claim data available</Callout>
      ),
  };

  const onNextCase = () => {
    handleOnNextCase(
      id,
      !!nextCaseError,
      data.baseCase?.product_id || "",
      setShowLoader,
      onRefetch,
      history,
      nextCaseData?.base_case[0]?.id
    );
  };

  return (
    <>
      <Header
        page="Case"
        fullName={
          data.baseCase?.state === GDPR_DELETED
            ? DELETED
            : $customer.first_name + " " + $customer.last_name
        }
        caseState={data.baseCase?.state!}
        offerState={activeOffer?.state}
        createdAt={data.baseCase?.created_at!}
        aliasId={data.baseCase?.alias_id!}
        caseId={data.baseCase?.id!}
        refetch={onRefetch}
        hasActiveOffer={!!activeOffer}
        onNextCase={onNextCase}
        isCaseAgentSupervisor={isCaseAgentSupervisor}
        commentCount={data.case_comment_aggregate.aggregate?.count || 0}
        acceptedAt={activeOffer?.accepted_at}
        productId={data.baseCase?.product_id!}
      />

      <InfoCards
        data={data}
        setShowLoader={setShowLoader}
        onRefetch={onRefetch}
        claimAmount={$activeOffer.claim_amount}
      />

      <div className="builder-wrapper">
        <ValueContext.Provider
          value={{
            flow: {
              current_step: getCurrentStep(data.baseCase),
              read_only_group: {
                customer_data: isProductReadOnly || !canUpdateCustomer(data.baseCase),
                claim_data: isProductReadOnly || !canUpdateClaim(data.baseCase),
                address_data:
                  isProductReadOnly || !canChangeOfferAddress(data.baseCase, isCaseAgentSupervisor),
                offer_details:
                  isProductReadOnly ||
                  !canCreateOffer(data.baseCase, $claim, $activeOffer.offer_amount),
              },
            },
            case: {
              promo_code: data.baseCase?.promo_code,
              customer: $customer,
              claim: $claim,
              active_offer: $activeOffer,
              offer: {
                address: $address,
                bank_account: $bankAccount,
              },
              bank_transaction: $bankTransaction,
            },
            handlers,
            customComponents,
          }}
        >
          {showLoader && (
            <div className="spinner-wrapper">
              <Spinner intent={Intent.PRIMARY} size={100} />
            </div>
          )}
          <UIBuilder config={uiConfig} />
        </ValueContext.Provider>
      </div>
    </>
  );
};

export default BaseCaseProcessor;

import React from "react";
import { useLocation } from "react-router-dom";

const LawsuitView = () => {
  const location = useLocation();
  const lawsuitId = location.pathname.split("/").pop();
  return (
    <div>
      <h2>Lawsuit View</h2>
      <p>
        {" "}
        View for lawsuit <b>{lawsuitId}</b> coming soon!
      </p>
    </div>
  );
};

export default LawsuitView;

import { Callout, FormGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { ValueContext } from "process-ui-builder";
import { resolveValue } from "process-ui-builder/utils";
import React, { useState } from "react";
import { useContext } from "react";
import { Props } from "./types";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { validator } from "process-ui-builder/validator";
import dayjs from "dayjs";

export const DateField: React.FC<Props> = observer(({ config, readOnly }) => {
  const [validationError, setValidationError] = useState(false);

  const valueContext = useContext(ValueContext);
  const date = resolveValue(config.value, valueContext);
  const value = date ? new Date(date) : null;

  const isReadOnly = resolveValue(config.readOnly, valueContext) || readOnly;

  const handleChange = action((date: Date) => {
    runInAction(() => {
      if (!config.value?.path) {
        return;
      }
      const newDateValue = date ? dayjs(date).format("YYYY-MM-DD") : "";
      _.set(valueContext, config.value.path, newDateValue);
      setValidationError(validator(newDateValue, config.validation));
    });
  });

  const parseDate = (date: string) => {
    //date is DD.MM.YYYY
    const initial = date.split(".");
    const formattedDate = [initial[2], initial[1], initial[0]].join("-");
    // formattedDate is in YYYY-MM-DD format so we can use it in Date for firefox, safari and chrome
    return new Date(formattedDate);
  };

  return (
    <>
      <FormGroup
        label={config.label}
        labelFor={config.id}
        labelInfo={config.validation?.required ? "(required)" : ""}
      >
        <DateInput
          formatDate={(date) => dayjs(date).format("DD.MM.YYYY")}
          onChange={handleChange}
          parseDate={parseDate}
          placeholder={"DD.MM.YYYY"}
          value={value}
          disabled={isReadOnly}
          minDate={
            config.dateRange?.min
              ? new Date(config.dateRange?.min)
              : new Date("1900-01-01") /*by default minimum date is 01/01/2002 */
          }
          maxDate={
            config.dateRange?.max
              ? new Date(config.dateRange?.max)
              : new Date("2100-12-31") /*by default maximum date is last day of current year */
          }
        />
      </FormGroup>
      {validationError && (
        <Callout intent={Intent.DANGER} icon="error" className="error">
          Value is not valid
        </Callout>
      )}
    </>
  );
});

import React from "react";
import { MenuItem, Intent } from "@blueprintjs/core";

type TProps = {
  action: () => void;
};

export const DeleteOption: React.FC<TProps> = ({ action }) => {
  return <MenuItem icon="delete" text="Delete" intent={Intent.DANGER} onClick={action} />;
};

import React, { useContext } from "react";
import { Menu, MenuItem, Button } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";

import { DocumentManagerContext } from "document-manager/DocumentManager";

export const DownloadButton: React.FC = () => {
  const { downloadZipFile } = useContext(DocumentManagerContext);

  return (
    <div>
      <Popover2
        placement="bottom"
        content={
          <Menu>
            <MenuItem
              icon="duplicate"
              text="All Files"
              onClick={() => {
                downloadZipFile && downloadZipFile(false);
              }}
            />
            <MenuItem
              icon="highlight"
              text="All Lawsuit Relevant Files"
              onClick={() => {
                downloadZipFile && downloadZipFile(true);
              }}
            />
          </Menu>
        }
      >
        <Button icon="download">Download</Button>
      </Popover2>
    </div>
  );
};

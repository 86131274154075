import { Card, Elevation, H5, HTMLTable } from "@blueprintjs/core";
import { dateFormatter } from "helpers/dateFormatter";
import React from "react";
import "./index.scss";

interface IProps {
  assignment: {
    createdAt: string;
    sentAt: string;
    receivedAt: string;
  };
}

const ActiveAssignmentDetails: React.FC<IProps> = ({ assignment }) => {
  return (
    <>
      <Card elevation={Elevation.ONE} className="base-card">
        <H5>Assignment</H5>
        <HTMLTable interactive>
          <table>
            <tbody>
              <tr>
                <td className="title">Created:</td>
                <td>{dateFormatter(assignment.createdAt)}</td>
              </tr>
              <tr>
                <td className="title">Sent:</td>
                <td>{dateFormatter(assignment.sentAt)}</td>
              </tr>
              <tr>
                <td className="title">Received:</td>
                <td>{dateFormatter(assignment.receivedAt)}</td>
              </tr>
            </tbody>
          </table>
        </HTMLTable>
      </Card>
    </>
  );
};

export default ActiveAssignmentDetails;

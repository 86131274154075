import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, Classes, HTMLTable, Callout, Intent, Button } from "@blueprintjs/core";

import { TFileWithCategory, TSelectOptions } from "document-manager/types";
import { DOC_TYPES_ALLOWED } from "document-manager/constants";

import { DocumentRow } from "./DocumentRow";
import { UploadButton } from "./UploadButton";

import "./styles.scss";

type TProps = {
  files?: File[];
  isOpen: boolean;
  categories: TSelectOptions;
  onClose: () => void;
};

export const UploadModal: React.FC<TProps> = ({ files, isOpen, categories, onClose }) => {
  const inputFile = React.useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const [filesList, setFilesList] = useState<TFileWithCategory[] | undefined>();
  const [categoryWarningIsVisible, setCategoryWarningIsVisible] = useState(false);
  useEffect(() => {
    setFilesList(
      files?.map((file) => {
        return {
          file: file,
          category: "",
        };
      })
    );
  }, [files]);

  const handleOnChange = ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
      const newFiles = Array.from(files)?.map((file) => {
        return {
          file: file,
          category: "",
        };
      });

      setFilesList((prevFiles) => {
        return prevFiles ? [...prevFiles, ...newFiles] : newFiles;
      });
    }
  };

  const changeCategory = useCallback(
    (index: number, category: string) => {
      if (filesList) {
        const newFileListRows = [...filesList];
        newFileListRows[index].category = category;
        setFilesList(newFileListRows);
      }
    },
    [filesList]
  );

  const deleteFile = useCallback(
    (index: number) => {
      if (filesList) {
        if (filesList?.length <= 1) {
          // Close modal if it's a last file to delete, user should select files again
          setFilesList(undefined);
          onClose();
          return;
        }
        const newFileListRows = [...filesList];
        newFileListRows.splice(index, 1);
        setFilesList(newFileListRows);
      }
    },
    [filesList, onClose]
  );

  const documentRows = useMemo(() => {
    const documentRowsComponent: JSX.Element[] = [];
    if (!filesList?.length) {
      return documentRowsComponent;
    }
    for (let index = 0; index < filesList.length; index++) {
      documentRowsComponent.push(
        <DocumentRow
          file={filesList[index]}
          categories={categories}
          changeCategory={changeCategory}
          fileIndex={index}
          deleteFile={deleteFile}
        />
      );
    }
    return documentRowsComponent;
  }, [filesList, categories, changeCategory, deleteFile]);

  return (
    <Dialog
      title="Select Categories"
      icon="document"
      className={"upload-documents-modal"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <HTMLTable>{documentRows}</HTMLTable>
        <input
          className="upload-input"
          type="file"
          name="upload-documents"
          accept={DOC_TYPES_ALLOWED.join(",")}
          ref={inputFile}
          onChange={handleOnChange}
          multiple
        />
        <Button
          text="Add more documents"
          icon="add"
          large
          outlined={true}
          intent={"primary"}
          className="add-more-document-button"
          onClick={onButtonClick}
        />
        {categoryWarningIsVisible && (
          <Callout intent={Intent.WARNING} icon={"error"}>
            Please select a category for every file
          </Callout>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <UploadButton
            disabled={!filesList}
            filesList={filesList!!}
            onClose={onClose}
            setCategoryWarningIsVisible={setCategoryWarningIsVisible}
          />
        </div>
      </div>
    </Dialog>
  );
};

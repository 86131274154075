import { Intent, Spinner, Tag, NonIdealState } from "@blueprintjs/core";
import List from "components/shared/List";
import { GenericListHeader } from "components/shared/ListHeader";
import { Paginator } from "components/shared/Paginator";
import React, { useState } from "react";
import { LawsuitFilter } from "../../components";
import { useLocation } from "react-router-dom";
import { Ls_Lawsuit_Bool_Exp, Order_By, useGetLawsuitListQuery } from "types/graphql.types";
import { ErrorMessage } from "components/shared/ErrorMessage";
import { humanizeString } from "helpers/humanize-string";
import { URL_LEGAL_LAWSUITS } from "../../../../constants";
import { useHandleOnSortByColumn } from "hooks/use-handle-on-sort-by-column";
import { generateOrderByVariableForLawsuits } from "services/legal-service/components/LawsuitFilter/utils";

const LawsuitListView = () => {
  const location = useLocation();
  const handleOnSortByColumn = useHandleOnSortByColumn();
  const searchParams = new URLSearchParams(location.search);
  const aliasIdFilter = searchParams.get("aliasId");
  const verticalFilter = searchParams.get("vertical");
  const debtorFilter = searchParams.get("debtor");
  const courtFilter = searchParams.get("court");
  const lawyerFilter = searchParams.get("lawyer");
  const statusFilter = searchParams.get("status");
  let condition: Ls_Lawsuit_Bool_Exp = {};

  if (aliasIdFilter) {
    condition.alias_id = {
      _ilike: `%${aliasIdFilter}%`,
    };
  }
  if (verticalFilter) {
    condition.vertical = {
      _eq: verticalFilter,
    };
  }
  if (debtorFilter) {
    condition.debtor = {
      name: {
        _ilike: `%${debtorFilter}%`,
      },
    };
  }
  if (courtFilter) {
    condition.court = {
      name: {
        _ilike: `%${courtFilter}%`,
      },
    };
  }
  if (lawyerFilter) {
    condition.lawyer = {
      company_name: {
        _ilike: `%${lawyerFilter}%`,
      },
    };
  }
  if (statusFilter) {
    condition.status = {
      _eq: statusFilter,
    };
  }

  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const sortColumn = searchParams.get("sortColumn");
  const sortOrder = searchParams.get("sortOrder");

  const orderByVariable = generateOrderByVariableForLawsuits({
    sortColumn: sortColumn ?? undefined,
    sortOrder: sortOrder && sortOrder === "asc" ? Order_By.Asc : Order_By.Desc,
  });

  const { data, loading, error } = useGetLawsuitListQuery({
    variables: {
      ...orderByVariable,
      offset: perPage * (currentPage - 1),
      limit: perPage,
      condition,
    },
    context: {
      roleType: "legal",
    },
  });

  if (error) return <ErrorMessage error={error.message} />;

  const columns = [
    { label: "Lawsuit ID", id: "alias_id" },
    { label: "Vertical", id: "vertical" },
    { label: "Debtor", id: "debtor" },
    { label: "Court", id: "court" },
    { label: "Lawyer", id: "lawyer" },
    { label: "Claim Amount", id: "claim_amount" },
    { label: "Status", id: "status" },
  ];

  const rows = data?.ls_lawsuit.map((item) => {
    return {
      id: item.id,
      url: `${URL_LEGAL_LAWSUITS}/${item.id}`,
      cells: [
        `#${item.alias_id}`,
        item.vertical,
        <Tag key={item.id} intent={Intent.PRIMARY} round={true} large={true}>
          {humanizeString(item.debtor?.name || "")}
        </Tag>,
        item.court?.name || "",
        item.lawyer?.company_name,
        item.claim_amount.toLocaleString("de-EN", { style: "currency", currency: "EUR" }),
        <Tag key={item.id} intent={Intent.SUCCESS} round={true} large={true}>
          {humanizeString(item.status)}
        </Tag>,
      ],
    };
  });
  return (
    <div className="list-cont">
      <GenericListHeader
        headerLabel={<>Lawsuits</>}
        filter={<LawsuitFilter defaultStatus={"FILED"} viewUrl={URL_LEGAL_LAWSUITS} />}
      />
      {loading && <Spinner />}
      {rows?.length ? (
        <>
          <List
            columns={columns}
            rows={rows || []}
            sort={{
              onSortByColumn: handleOnSortByColumn,
              sortableColumns: [
                "alias_id",
                "vertical",
                "debtor",
                "court",
                "lawyer",
                "claim_amount",
                "status",
              ],
              sortColumn: sortColumn || "alias_id",
              sortOrder: sortOrder || Order_By.Desc,
            }}
          />

          <Paginator
            total={data?.ls_lawsuit_aggregate.aggregate?.count || 0}
            perPage={perPage}
            onPerPageChange={setPerPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </>
      ) : (
        <NonIdealState
          title="No results found"
          description={"Please check your filters and try again"}
          icon="search"
        />
      )}
    </div>
  );
};

export default LawsuitListView;

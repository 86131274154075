import { Card, Elevation, H5, HTMLTable } from "@blueprintjs/core";
import { dateFormatter } from "helpers/dateFormatter";
import React from "react";
import "./style.scss";
import { formatMoneyVerticals } from "helpers/moneyFormatter";

interface Offer {
  state: string;
  amount: number;
  sentAt: string;
}

interface IProps {
  offers: Offer[];
}

const OfferHistoryCard: React.FunctionComponent<IProps> = ({ offers }) => {
  return (
    <Card elevation={Elevation.ONE} className="offer-history base-card">
      <H5>Offer History</H5>
      <HTMLTable className="history-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Amount</th>
            <th>Creation Date</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer, index) => (
            <tr key={index}>
              <td className={"word-break"}>{offer.state}</td>
              <td>{formatMoneyVerticals(offer.amount)}</td>
              <td>{dateFormatter(offer.sentAt)}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

export default OfferHistoryCard;

export type CustomerPayoutItem = {
  amount: number;
  caseId: string;
  createdAt: string;
  currency: string;
  customerIban: string;
  customerName: string;
  id: string;
  paymentReference: string;
  status: string;
  updatedAt: string;
  aliasId: string;
  vertical: string;
};

export enum PaymentStatus {
  PAYMENT_APPROVED = "PAYMENT_APPROVED",
  PAYMENT_REJECTED = "PAYMENT_REJECTED",
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
}

export interface GetCustomerPayoutsQueryResponse {
  fs_customer_payout: {
    id: string;
    alias_id: string;
    amount: number;
    case_id: string;
    created_at: string;
    currency: string;
    customer_iban: string;
    customer_name: string;
    payment_reference: string;
    status: string;
    updated_at: string;
    vertical: string;
  }[];
  aggregate: {
    aggregate: {
      count: number;
    };
  };
}

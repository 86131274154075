import { FocusStyleManager } from "@blueprintjs/core";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/admin.scss";
import ApolloProvider from "./context/apollo";
import { AuthProvider } from "./context/auth";
import history from "./helpers/history";
import RootRouter from "./routes/RootRouter";
import { persistor, store } from "./store/store";

FocusStyleManager.onlyShowFocusOnTabs();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider>
          <AuthProvider>
            <Router history={history}>
              <RootRouter />
            </Router>
          </AuthProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

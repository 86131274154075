import React, { useState } from "react";
import { Tab, Tabs } from "@blueprintjs/core";

import PreviewFile from "./previewFile";
import { useAdminGetSignedUrlsQuery } from "types/graphql.types";
import "./style.scss";

interface IProps {
  assignment: {
    createdAt: string;
    id: string;
    sentFile: string | null;
    receivedFile: string | null;
  };
}

const sentFile = 0;
const receivedFile = 1;
type TabId = typeof sentFile | typeof receivedFile;

const AssignmentPreviews: React.FC<IProps> = ({ assignment }) => {
  const [activeTab, setActiveTab] = useState<TabId>(sentFile);
  const [sentUrl, setSentUrl] = useState<string>("");
  const [receivedUrl, setReceivedUrl] = useState<string>("");

  const totalAssignments = [];

  if (assignment.sentFile) {
    totalAssignments.push({
      key: assignment.sentFile,
      type: "SentAssignments",
      displayFileName: assignment.sentFile,
    });
  }

  if (assignment.receivedFile) {
    totalAssignments.push({
      key: assignment.receivedFile,
      type: "ReceivedAssignments",
      displayFileName: assignment.receivedFile,
    });
  }

  const { refetch } = useAdminGetSignedUrlsQuery({
    skip: true,
  });

  if (totalAssignments.length) {
    const input = {
      download: false,
      documents: totalAssignments,
    };

    refetch({ input }).then(({ data }) => {
      const getDocumentPreviewUrl = (fileKey: string | null, type: string | null) => {
        return (
          data.admin_get_signed_urls?.documents.find(
            (doc: { key: string; type: string }) => doc.key === fileKey && doc.type === type
          )?.previewUrl || ""
        );
      };
      setSentUrl(getDocumentPreviewUrl(assignment.sentFile, "SentAssignments"));
      setReceivedUrl(getDocumentPreviewUrl(assignment.receivedFile, "ReceivedAssignments"));
    });
  }

  return (
    <div className="preview-section">
      <Tabs
        large
        id="tabs"
        onChange={(newTabId: TabId) => setActiveTab(newTabId)}
        selectedTabId={activeTab}
      >
        {sentUrl && <Tab id={sentFile} title="Sent File" panel={<PreviewFile url={sentUrl} />} />}
        {receivedUrl && (
          <Tab id={receivedFile} title="Received File" panel={<PreviewFile url={receivedUrl} />} />
        )}
      </Tabs>
    </div>
  );
};

export default AssignmentPreviews;

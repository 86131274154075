import jwt from "jsonwebtoken";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthToken } from "../../../actions/auth";
import { removeRoles } from "../../../actions/roles";
import { RootState } from "../../../reducers";
import { Component } from "./Component";

const Topbar: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);

  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(removeAuthToken());
    dispatch(removeRoles());
  }, [dispatch]);

  if (!token) {
    return null;
  }
  const tokenData: any = jwt.decode(token);
  // TODO: fullname
  const username = tokenData.email ? tokenData.email : "";

  return <Component username={username} onLogout={handleLogout} />;
};

export default Topbar;

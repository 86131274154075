import {
  ACCEPTED,
  ASSIGNMENT_EXPIRED,
  ASSIGNMENT_RECEIVED,
  ASSIGNMENT_SENT,
  CREATED,
  DELETED,
  DUPLICATE,
  FRAUD,
  GDPR_DELETED,
  NEW,
  PAYMENT_FAILED,
  PAYMENT_INITIATED,
  PAYMENT_PENDING,
  PAYMENT_SUCCESSFUL,
  REJECTED,
  REJECTION_CONFIRMED,
  SENT,
  WAITING_DOCUMENTS,
  WAITING_FTS_DOCUMENTS,
} from "../../constants";

export const caseStates = [
  NEW,
  WAITING_DOCUMENTS,
  WAITING_FTS_DOCUMENTS,
  ACCEPTED,
  REJECTED,
  REJECTION_CONFIRMED,
  DELETED,
  GDPR_DELETED,
  DUPLICATE,
  FRAUD,
] as const;

export const offerStates = [
  CREATED,
  SENT,
  ACCEPTED,
  ASSIGNMENT_SENT,
  ASSIGNMENT_RECEIVED,
  ASSIGNMENT_EXPIRED,
  PAYMENT_PENDING,
  PAYMENT_INITIATED,
  PAYMENT_SUCCESSFUL,
  PAYMENT_FAILED,
] as const;

export const pendingCaseStates = (vertical: string, isCaseAgentSupervisor: boolean): string[] => {
  const pendingStates: typeof caseStates[number][] = [NEW];

  if (vertical !== "GDPR") {
    pendingStates.push(WAITING_DOCUMENTS);
    pendingStates.push(WAITING_FTS_DOCUMENTS);
  }

  if (isCaseAgentSupervisor) {
    pendingStates.push(REJECTED);
  }

  return pendingStates;
};

// Unless it's specifically requested we don't show DELETED cases
const defaultCaseStates = () => {
  return caseStates.filter(
    (caseState) =>
      ![
        "DELETED",
        "GDPR_DELETED",
        "DUPLICATE",
        "FRAUD",
        "REVOKED",
        "REJECTION_CONFIRMATION",
      ].includes(caseState)
  );
};

export const getCaseStates = (vertical: string, isCaseAgentSupervisor: boolean, view: string) => {
  if (view === "pending") {
    return pendingCaseStates(vertical, isCaseAgentSupervisor);
  }

  return defaultCaseStates();
};

import { HTMLSelect, IOptionProps } from "@blueprintjs/core";
import React from "react";
import {
  DeploymentEnvironment,
  OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY,
} from "../../../constants";

const options: IOptionProps[] = [
  { label: "Local Development", value: DeploymentEnvironment.Development },
  { label: "QA-1", value: DeploymentEnvironment.QA1 },
  { label: "QA-2", value: DeploymentEnvironment.QA2 },
];

export const BackendDropdown = () => {
  const [backend, setBackend] = React.useState<string | undefined>(
    localStorage.getItem(OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY) || undefined
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBackend(event.target.value);
    localStorage.setItem(OVERRIDDEN_BACKEND_SERVER_LOCAL_STORAGE_KEY, event.target.value);
    window.location.reload();
  };

  console.log(
    `BackendDropdown: backend=${backend}, env=${process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT}`
  );

  // No change allowed on production
  if (process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === DeploymentEnvironment.Production) {
    return null;
  }

  return (
    <div>
      <HTMLSelect
        options={options}
        onChange={handleChange}
        // If no stored value use the current environment
        value={backend || process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT}
      />
    </div>
  );
};

import DebugLib from "debug";
const Debug = DebugLib;

export const NETWORK_ERROR = "Network Error";
export const SERVICE_WORKER = "ServiceWorker";
export const SERVICE_WORKER_ERROR = "ServiceWorker Error";

if (process.env.NODE_ENV !== "production") {
  localStorage.debug = "*";
} else {
  localStorage.debug = [NETWORK_ERROR, SERVICE_WORKER_ERROR];
}

Debug.log = console.info.bind(console);

export default Debug;

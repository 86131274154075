import { Callout, FormGroup, Intent } from "@blueprintjs/core";
import get from "lodash.get";
import set from "lodash/set";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ValueContext } from "process-ui-builder";
import { validator } from "process-ui-builder/validator";
import React, { useContext, useState } from "react";
import SelectSearch, { SelectedOptionValue, SelectSearchOption } from "react-select-search";
import filterOption from "./filter";
import "./style.scss";

interface IProps {
  fieldOption: {
    label: string;
    id: string;
    readOnly?: boolean;
    validation: { required?: boolean };
    optionsData: SelectSearchOption[];
    value: { path: string };
    emptyValue?: boolean;
    isBooleanValues?: boolean;
  };
}

const SearchSelectBox: React.FunctionComponent<IProps> = ({ fieldOption }) => {
  const optionsArray: SelectSearchOption[] = [];
  const valueContext = useContext(ValueContext);
  const value = get(valueContext, fieldOption.value.path);
  const [validationError, setValidationError] = useState(false);

  if (fieldOption.emptyValue) {
    optionsArray.push({
      name: "--- Select a value ---",
      value: "",
    });
  }

  if (fieldOption.optionsData) {
    optionsArray.push(...fieldOption.optionsData);
  }

  const handleChange = action((selectedValue: SelectedOptionValue | SelectedOptionValue[]) => {
    runInAction(() => {
      set(valueContext, fieldOption.value.path, selectedValue);
      setValidationError(validator(JSON.stringify(selectedValue), fieldOption.validation));
    });
  });

  return (
    <>
      <FormGroup
        label={fieldOption.label}
        labelFor={fieldOption.id}
        labelInfo={fieldOption.validation.required ? "(required)" : ""}
      >
        <SelectSearch
          options={optionsArray}
          value={value}
          id={fieldOption.id}
          onChange={handleChange}
          filterOptions={filterOption}
          search={true}
          disabled={fieldOption.readOnly}
        />
      </FormGroup>
      {validationError && (
        <Callout intent={Intent.DANGER}>
          <p>{validationError}</p>
        </Callout>
      )}
    </>
  );
};

export default observer(SearchSelectBox);

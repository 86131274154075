export interface AclRules {
  [key: string]: Array<String>;
}

const ROLE_CASE_AGENT = "case_agent";
const ROLE_CASE_AGENT_SUPERVISOR = "case_agent_supervisor";

export const PERMISSION_GDPR_CASE_REJECT_CONFIRM = "gdpr-case:reject-confirm";
export const PERMISSION_GDPR_OFFER_SEND = "gdpr-offer:send";

export const PERMISSION_FIT_CASE_REJECT_CONFIRM = "fit-case:reject-confirm";
export const PERMISSION_FIT_OFFER_SEND = "fit-offer:send";
export const PERMISSION_OFFER_APPROVE = "offer:approve";
export const PERMISSION_ASSIGNMENT_RESEND = "assignment:resend";

export const Rules: AclRules = {
  [ROLE_CASE_AGENT]: [],
  [ROLE_CASE_AGENT_SUPERVISOR]: [
    PERMISSION_GDPR_CASE_REJECT_CONFIRM,
    PERMISSION_GDPR_OFFER_SEND,
    PERMISSION_FIT_CASE_REJECT_CONFIRM,
    PERMISSION_FIT_OFFER_SEND,
    PERMISSION_OFFER_APPROVE,
    PERMISSION_ASSIGNMENT_RESEND,
  ],
};

// @ts-ignore
const supportedRoles = [...new Set([].concat(...Object.values(Rules)))] as const;

export type PermissionsType = typeof supportedRoles[number];

import { Callout, Intent, Tag } from "@blueprintjs/core";
import React from "react";
import { useHistory } from "react-router-dom";

import { humanizeString } from "helpers/humanize-string";
import { isAbsoluteUrl } from "helpers/utils";

import { Product_Enum } from "types/graphql.types";
import { PAYMENT_ON_HOLD_REASON } from "../../constants";

import "./style.scss";

interface IProps {
  productId: Product_Enum;
  reasons: string[];
  activeOfferId: string;
  canApproveOrCancelOffer: boolean;
  setShowLoader?: (bool: boolean) => void;
  onApproveSuccess?: () => void;
  onApproveError?: () => void;
  iban?: string;
  nflxAccountEmail?: string;
  addressHash?: string;
  setConfirmAction?: React.Dispatch<React.SetStateAction<"cancel" | "approve" | undefined>>;
  setIsConfirmModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
const PaymentOnHoldCard: React.FC<IProps> = ({
  productId,
  reasons,
  canApproveOrCancelOffer,
  iban,
  addressHash,
  nflxAccountEmail,
  setConfirmAction,
  setIsConfirmModalOpen,
}) => {
  const history = useHistory();

  const handleClick = (link: string) => {
    if (isAbsoluteUrl(link)) {
      window.open(link, "_blank");
    } else {
      history.push(link);
    }
  };

  const getShowCasesLink = (reason: PAYMENT_ON_HOLD_REASON) => {
    const base = `/cases/?vertical=${productId}`;

    if (reason === PAYMENT_ON_HOLD_REASON.MANUAL_IBAN_CHECK) {
      return `${base}&iban=${iban}`;
    }
    if (reason === PAYMENT_ON_HOLD_REASON.DUPLICATE_CUSTOMER_ADDRESS) {
      return `${base}&address_hash=${addressHash}`;
    }
    if (reason === PAYMENT_ON_HOLD_REASON.DUPLICATE_NETFLIX_ACCOUNT_EMAIL) {
      return `${base}&nflx_claim.account_email=${nflxAccountEmail}`;
    }
  };

  const getReasons = () => {
    return reasons.map((reason) => {
      const link = getShowCasesLink(reason as PAYMENT_ON_HOLD_REASON);
      return (
        <div key={reason} className="reason">
          {humanizeString(reason)}
          {link && (
            <Tag
              className="infocard-button"
              onClick={() => handleClick(link)}
              interactive={true}
              intent={Intent.DANGER}
              minimal={true}
              large={true}
            >
              Show Cases
            </Tag>
          )}
        </div>
      );
    });
  };

  return (
    <div className="info-card payment-on-hold-card ">
      <Callout intent={Intent.DANGER} icon={"delete"} className="sub-card-content">
        <div className="sub-card-text">
          <div>
            <div className="bp3-heading">Payment is on hold for the following reasons</div>
            <div className="reasons">{getReasons()}</div>
            {canApproveOrCancelOffer && (
              <div className="button-wrapper">
                <Tag
                  className="infocard-button"
                  onClick={() => {
                    setIsConfirmModalOpen && setIsConfirmModalOpen(true);
                    setConfirmAction && setConfirmAction("cancel");
                  }}
                  intent={Intent.DANGER}
                  interactive={true}
                  large={true}
                >
                  Cancel Offer
                </Tag>
                <Tag
                  className="infocard-button"
                  onClick={() => {
                    setIsConfirmModalOpen && setIsConfirmModalOpen(true);
                    setConfirmAction && setConfirmAction("approve");
                  }}
                  intent={Intent.SUCCESS}
                  interactive={true}
                  large={true}
                >
                  Approve Offer
                </Tag>
              </div>
            )}
          </div>
        </div>
      </Callout>
    </div>
  );
};

export default PaymentOnHoldCard;

import get from "lodash.get";
import { ValueContext } from "process-ui-builder";
import React, { useContext } from "react";
import Person, { InsuredPerson } from "./Person";

interface IProps {
  readOnly: boolean;
}

const Persons: React.FunctionComponent<IProps> = ({ readOnly }) => {
  const valueContext = useContext(ValueContext);

  const persons: InsuredPerson[] = get(valueContext, "case.claim.insuredPersons");

  return (
    <>
      {persons.map((person, index) => {
        return (
          <Person person={person} count={index} readOnly={readOnly} isOpen={false} key={index} />
        );
      })}
    </>
  );
};
export default Persons;

import { AnchorButton, Dialog } from "@blueprintjs/core";
import React from "react";
import { useState } from "react";
import "./style.scss";

const DocumentPreview: React.FunctionComponent<{
  url: string;
}> = ({ url }) => {
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const handleClick = () => {
    setIsPreview(true);
  };

  return (
    <>
      <AnchorButton onClick={handleClick} icon="eye-open" minimal />
      {isPreview && (
        <Dialog
          title="Document"
          onClose={() => setIsPreview(false)}
          isOpen={isPreview}
          className={"document-preview"}
        >
          <div>
            <iframe src={url} title="document" className="document-preview-iframe" />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default DocumentPreview;

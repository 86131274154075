import { Intent, Tag } from "@blueprintjs/core";
import React from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { isAbsoluteUrl } from "../../helpers/utils";

interface IProps {
  text: string;
  link: string;
  intent: Intent;
  isMainAction?: boolean;
}

const InfocardLinkButton: React.FC<IProps> = ({ text, link, intent, isMainAction }) => {
  const history = useHistory();

  const handleClick = (link: string) => {
    if (isAbsoluteUrl(link)) {
      window.open(link, "_blank");
    } else {
      history.push(link);
    }
  };

  return (
    <Tag
      className="infocard-button"
      onClick={() => handleClick(link)}
      intent={intent}
      minimal={!isMainAction}
      interactive={true}
      large={true}
    >
      {text}
    </Tag>
  );
};

export default InfocardLinkButton;

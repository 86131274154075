import {
  ACCEPTED,
  ASSIGNMENT_RECEIVED,
  ASSIGNMENT_SENT,
  CREATED,
  DELETED,
  GDPR_DELETED,
  PAYMENT_FAILED,
  PAYMENT_INITIATED,
  PAYMENT_PENDING,
  PAYMENT_SUCCESSFUL,
} from "../../constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const offerStatesPreventGDPRDataDeletion = [
  ASSIGNMENT_RECEIVED,
  PAYMENT_PENDING,
  PAYMENT_INITIATED,
  PAYMENT_FAILED,
  PAYMENT_SUCCESSFUL,
];

dayjs.extend(utc);

const offerStatesOpenRevocation = [CREATED, ACCEPTED, ASSIGNMENT_SENT];

const daysSinceOfferAcceptance = (acceptedAt?: string): number => {
  if (!acceptedAt) return 0;

  const currentTime = dayjs().utc();
  const acceptedAtUtc = dayjs.utc(acceptedAt);
  return Math.floor(currentTime.diff(acceptedAtUtc, "days"));
};

export const disableCaseDeleteButton = (caseState: string, hasActiveOffer?: boolean): boolean => {
  return [DELETED, GDPR_DELETED].includes(caseState) || !!hasActiveOffer;
};

export const disableGdprDeleteButton = (offerState?: string): boolean => {
  return offerStatesPreventGDPRDataDeletion.includes(offerState!);
};

export const disableRevokeCaseButton = (offerState?: string, acceptedAt?: string) => {
  if (
    offerState &&
    (offerStatesOpenRevocation.includes(offerState) ||
      (offerStatesPreventGDPRDataDeletion.includes(offerState) &&
        daysSinceOfferAcceptance(acceptedAt) <= 14))
  ) {
    return false;
  }
  return true;
};

import { Card, Elevation, H5, HTMLTable, Spinner } from "@blueprintjs/core";
import { ErrorMessage } from "components/shared/ErrorMessage";
import React from "react";
import { useAdminGetSignedUrlsQuery } from "types/graphql.types";
import AssignmentLinksRow from "./assignmentLinksRow";

interface IProps {
  assignments: {
    createdAt: string;
    id: string;
    state: string;
    sentFile: string | null;
    receivedFile: string | null;
  }[];
}

const AssignmentHistoryCard: React.FC<IProps> = ({ assignments }) => {
  const totalAssignments = assignments
    .map((assignment) => {
      const mappedArray = [];

      if (assignment.sentFile) {
        mappedArray.push({
          key: assignment.sentFile,
          type: "SentAssignments",
          displayFileName: assignment.sentFile,
        });
      }

      if (assignment.receivedFile) {
        mappedArray.push({
          key: assignment.receivedFile,
          type: "ReceivedAssignments",
          displayFileName: assignment.receivedFile,
        });
      }

      return mappedArray;
    })
    .flat();

  const input = {
    download: false,
    documents: totalAssignments,
  };

  const { data, loading, error } = useAdminGetSignedUrlsQuery({
    variables: {
      input,
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <ErrorMessage error={error.message} />;
  }
  if (!data) {
    return <ErrorMessage error={"No signed URLs response could be queried"} />;
  }

  const getDocumentPreviewUrl = (fileKey: string | null, type: string | null) => {
    return (
      data.admin_get_signed_urls?.documents.find(
        (doc: { key: string; type: string }) => doc.key === fileKey && doc.type === type
      )?.previewUrl || ""
    );
  };

  return (
    <>
      <Card elevation={Elevation.ONE} className="base-card">
        <H5>Assignment History</H5>
        <HTMLTable interactive>
          <thead>
            <tr>
              <th>Creation Date</th>
              <th>Sent File</th>
              <th>Received File</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((item) => (
              <AssignmentLinksRow
                key={item.id}
                createdAt={item.createdAt}
                sentFileUrl={getDocumentPreviewUrl(item.sentFile, "SentAssignments")}
                receivedFileUrl={getDocumentPreviewUrl(item.receivedFile, "ReceivedAssignments")}
                state={item.state}
              />
            ))}
          </tbody>
        </HTMLTable>
      </Card>
    </>
  );
};

export default AssignmentHistoryCard;

import { Callout, Intent } from "@blueprintjs/core";
import { ValueContext } from "process-ui-builder";
import { Groups } from "process-ui-builder/components/groups";
import { StepSchema } from "process-ui-builder/config";
import { resolveValue } from "process-ui-builder/utils";
import React, { useContext } from "react";

interface Props {
  config: StepSchema;
}

export const Step: React.FC<Props> = ({ config }) => {
  const valueContext = useContext(ValueContext);
  let stepActionsComp;

  if (config.stepActions) {
    stepActionsComp = valueContext.customComponents[config.stepActions.component];
    if (!stepActionsComp) {
      stepActionsComp = (
        <Callout intent={Intent.DANGER}>
          {config.stepActions.component} is not found in the context
        </Callout>
      );
    }
  }

  const isReadOnly = resolveValue(config.readOnly, valueContext) || false;

  return (
    <>
      <Groups config={config.groups} readOnly={isReadOnly} />
      {stepActionsComp && <div className={"step-actions"}>{stepActionsComp}</div>}
    </>
  );
};

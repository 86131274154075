import { GroupSchema } from "process-ui-builder/config";
import React from "react";
import { Group } from "./group";
import "./style.scss";

interface Props {
  config: GroupSchema[];
  readOnly: boolean;
}

export const Groups: React.FC<Props> = ({ config, readOnly }) => {
  return (
    <>
      <div className="group-wrapper">
        {config.map((g) => (
          <Group key={g.id} config={g} readOnly={readOnly} />
        ))}
      </div>
    </>
  );
};

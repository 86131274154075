import React from "react";
import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";

import { BackendDropdown } from "../BackendDropdown";

import "./style.scss";
interface Props {
  username: string;
  onLogout: () => void;
}

export const Component: React.FC<Props> = ({ username, onLogout }) => {
  return (
    <div className="topbar">
      <div className="left-side">
        <BackendDropdown />
      </div>
      <div className="right-side">
        <Popover2
          placement="bottom"
          content={
            <>
              <Menu>
                <MenuItem icon={"log-out"} text="Logout" onClick={onLogout} />
              </Menu>
            </>
          }
        >
          <Button minimal icon="user" text={username} rightIcon="caret-down" />
        </Popover2>
      </div>
    </div>
  );
};

import React, { useContext, useMemo } from "react";
import { Switch, Intent } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

import { DocumentManagerContext } from "document-manager";
import { TDocument } from "document-manager/types";
import { SUPPORTS_ADD_TO_LAWSUIT } from "document-manager/constants";

type TAddToLawsuit = {
  document: TDocument;
  fileExtension: string;
};

export const AddToLawsuit: React.FC<TAddToLawsuit> = ({ document, fileExtension }) => {
  const { updateDocumentHandler } = useContext(DocumentManagerContext);

  const handleToggle = () => {
    updateDocumentHandler &&
      updateDocumentHandler({
        id: document.id,
        addToLawsuit: !document.addToLawsuit,
        displayFileName: document.displayFileName,
        documentType: document.documentType,
      });
  };

  const isDisabled = useMemo(() => {
    return !SUPPORTS_ADD_TO_LAWSUIT.includes(fileExtension);
  }, [fileExtension]);

  return (
    <div>
      <Tooltip2
        content={isDisabled ? "File type is not supported for the lawsuit." : ""}
        intent={Intent.WARNING}
      >
        <Switch
          checked={document.addToLawsuit}
          large={true}
          onChange={handleToggle}
          disabled={isDisabled}
        />
      </Tooltip2>
    </div>
  );
};

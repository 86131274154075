import { UiActionTypes, SET_ACTIVE_SIDEBAR_LINK } from "../actions/ui";

interface Ui {
  sidebarActiveLinkId?: string | number;
}

type UiReducerTypes = Ui;

const initialState: UiReducerTypes = {};

export default function reducer(state = initialState, action: UiActionTypes): UiReducerTypes {
  switch (action.type) {
    case SET_ACTIVE_SIDEBAR_LINK: {
      return {
        ...state,
        sidebarActiveLinkId: action.payload.id,
      };
    }
    default:
      break;
  }
  return state;
}

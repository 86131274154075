export function humanizeString(value: string): string {
  const words = value.match(/[a-zäöüß]+/gi) || [];

  return words
    .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`)
    .join(" ")
    .replace("Rightnow", "RightNow")
    .replace("Time Barred", "Time-barred")
    .replace("Gdpr", "GDPR")
    .replace("Iban", "IBAN")
    .replace("Nflx", "NFLX")
    .replace("Fit", "FIT")
    .replace("Gff", "GFF")
    .replace("Nrg", "NRG")
    .replace("Pkv", "PKV")
    .replace("Ocg", "OCG")
    .replace("Evn", "EVN")
    .replace("Out Of ", "Out-Of-")
    .trim();
}

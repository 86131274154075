import React, { useContext } from "react";
import { Button, Intent } from "@blueprintjs/core";

import { TSelectOptions } from "document-manager/types";
import { Modal } from "./Modal";
import { DocumentManagerContext } from "document-manager/DocumentManager";

type TProps = {
  categories: TSelectOptions;
};

export const RequestDocumentsButton: React.FC<TProps> = ({ categories }) => {
  const { allowRequestDocument } = useContext(DocumentManagerContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <>
      <Button
        text="Request a New Document"
        intent={Intent.WARNING}
        large
        onClick={() => setIsModalOpen(true)}
        disabled={!allowRequestDocument}
      />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} categories={categories} />
    </>
  );
};

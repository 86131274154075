import { ITreeNode, Tree } from "@blueprintjs/core";
import { setActiveSidebarLink } from "actions/ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "reducers";
import logoWhite from "./logo_white.svg";
import {
  getClaimPurchasingNode,
  getLegalServiceNode,
  getFinanceServiceNode,
  IS_EXPANDED,
} from "./nodes";
import { NodeData } from "./nodes/node-data";
import { cloneDeep } from "lodash-es";
import santasHat from "./Santa_hat.svg";
import rose from "./Rose.svg";
import { Product_Enum } from "types/graphql.types";

type NodePath = number[];

const Left: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedLink = useSelector((state: RootState) => state.ui.sidebarActiveLinkId);

  const todaysDate: Date = new Date();
  const todaysMonth: number = todaysDate.getMonth() + 1;
  const todaysDay: number = todaysDate.getDate();

  const searchParams = new URLSearchParams(history.location.search);

  const [treeItems, setTreeItems] = useState([
    getClaimPurchasingNode(searchParams.get("vertical")?.toUpperCase() as Product_Enum),
    getLegalServiceNode(),
    getFinanceServiceNode(),
  ]);

  walkTree(treeItems, (n) => {
    if (selectedLink !== undefined) {
      if (selectedLink === n.nodeData?.link) {
        n.isSelected = true;
      }
    } else {
      if (history.location.pathname.startsWith("/cases/")) {
        const possibleNodeId =
          searchParams.get("vertical")?.toLowerCase() +
          "-" +
          (searchParams.get("view") === "pending" ? "pending" : "all");

        if (n.id === "case-list-" + possibleNodeId) {
          n.isSelected = true;
        } else if (history.location.pathname === n.nodeData?.link) {
          n.isSelected = true;
        }
      }
    }
  });

  const navigate = (node: ITreeNode<NodeData>) => {
    if (node.nodeData?.link) {
      dispatch(setActiveSidebarLink(node.id));
      history.push(node.nodeData.link);

      const originallySelected = node.isSelected;
      walkTree(treeItems, (n) => (n.isSelected = false));
      node.isSelected = originallySelected == null ? true : !originallySelected;
      setTreeItems(treeItems);
    }
  };

  const forNodeAtPath = (
    nodes: ITreeNode<NodeData>[],
    path: NodePath,
    callback: (node: ITreeNode<NodeData>) => void
  ) => {
    callback(Tree.nodeFromPath(path, nodes));
  };

  const handleNodeCollapseAndExpand = (_node: ITreeNode<NodeData>, nodePath: NodePath) => {
    const newState = cloneDeep(treeItems);
    forNodeAtPath(newState, nodePath, (node) => (node.isExpanded = !node.isExpanded));
    setTreeItems(newState);
    localStorage.setItem(`${_node.id}${IS_EXPANDED}`, String(!_node.isExpanded));
  };

  return (
    <div className="left">
      <div className="logo-cont">
        <img src={logoWhite} alt="logo" />
        {todaysMonth === 2 && todaysDay > 7 && todaysDay <= 14 && (
          <img className="valentines-ornament" src={rose} alt="Happy Valentine's Day!" />
        )}
        {todaysMonth === 12 && (
          <img className="christmas-ornament" src={santasHat} alt="Santa's Hat" />
        )}
      </div>
      <div className="tree-cont">
        <Tree
          contents={treeItems}
          onNodeClick={navigate}
          onNodeCollapse={handleNodeCollapseAndExpand}
          onNodeExpand={handleNodeCollapseAndExpand}
        />
      </div>
    </div>
  );
};

function walkTree(
  nodes: ITreeNode<NodeData>[] | undefined,
  callback: (node: ITreeNode<NodeData>) => void
) {
  if (nodes === undefined) {
    return;
  }

  for (const node of nodes) {
    callback(node);
    walkTree(node.childNodes, callback);
  }
}

export default Left;

import { FormField } from "process-ui-builder/config";
import React from "react";
import { CheckboxField } from "./checkbox";
import { SelectField } from "./select";
import { TextField } from "./text";
import { CheckboxGroupField } from "./checkboxGroup";
import { TagList } from "./tagList";
import { DateField } from "./date";
import { MoneyField } from "./money";

interface Props {
  config: FormField;
  readOnly: boolean;
}

export const Field: React.FC<Props> = ({ config, readOnly }) => {
  if (["email", "text"].includes(config.type)) {
    return <TextField config={config} readOnly={readOnly} />;
  }
  if (config.type === "money") {
    return <MoneyField config={config} readOnly={readOnly} />;
  }

  if (config.type === "select") {
    return <SelectField config={config} readOnly={readOnly} />;
  }

  if (config.type === "checkbox") {
    return <CheckboxField config={config} readOnly={readOnly} />;
  }

  if (config.type === "checkboxGroup") {
    return <CheckboxGroupField config={config} readOnly={readOnly} />;
  }

  if (config.type === "tagList") {
    return <TagList config={config} readOnly={readOnly} />;
  }

  if (config.type === "date") {
    return <DateField config={config} readOnly={readOnly} />;
  }

  throw new Error(`Unknown field type in the configuration ${config.type}`);
};

export {
  TextField,
  CheckboxField,
  SelectField,
  CheckboxGroupField,
  TagList,
  DateField,
  MoneyField,
};

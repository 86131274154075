import { IconName } from "@blueprintjs/core";
import { Product_Enum } from "types/graphql.types";

export interface NodeData {
  link: string;
}

export const cpsMenuConfig: { id: Product_Enum; icon: IconName; label: string }[] = [
  { id: Product_Enum.Nflx, icon: "video", label: "NFLX" },
  { id: Product_Enum.Ocg, icon: "symbol-diamond", label: "OCG" },
  { id: Product_Enum.Nrg, icon: "power", label: "NRG" },
  { id: Product_Enum.Gff, icon: "airplane", label: "GFF" },
  { id: Product_Enum.Gdpr, icon: "euro", label: "GDPR" },
  { id: Product_Enum.Fit, icon: "walk", label: "FIT" },
  { id: Product_Enum.Pkv, icon: "pulse", label: "PKV" },
];

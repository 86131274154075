import { FormGroupSchema } from "process-ui-builder/config/types";

export const GdprClaim: FormGroupSchema = {
  id: "claim_data",
  type: "form",
  size: {
    colSpan: 4,
    rowSpan: 1,
  },
  title: "Claim Data",
  readOnly: true,
  fields: [
    {
      id: "vertical",
      label: "Vertical",
      type: "text",
      value: { path: "case.vertical" },
    },
    {
      id: "debtor",
      label: "Debtor",
      type: "text",
      value: { path: "case.debtorName" },
    },
    {
      id: "email",
      label: "Email",
      type: "email",

      value: { path: "case.claim.email" },
    },
    {
      id: "company_id",
      label: "Company",
      type: "text",
      value: { path: "case.companyId" },
    },
  ],
};

import React, { useEffect, useContext } from "react";
import { arrayMoveImmutable } from "array-move";
import { SortEnd } from "react-sortable-hoc";

import { TCommonPopup, TDocument } from "document-manager/types";
import { DocumentManagerContext } from "document-manager";

import { SortableList } from "./SortableList";

type TProps = {
  documents: TDocument[];
  category: string;
  setDocumentIdAndOpenPopUpBox: (id: string, popUpName: TCommonPopup) => void;
};

export const SortableWrapper: React.FC<TProps> = ({
  documents,
  category,
  setDocumentIdAndOpenPopUpBox,
}) => {
  const [documentsList, setDocumentsList] = React.useState<TDocument[]>(documents);

  const { updateDocumentOrderHandler } = useContext(DocumentManagerContext);

  useEffect(() => {
    setDocumentsList(documents);
  }, [documents]);

  const handleDragEnd = (sort: SortEnd) => {
    const { oldIndex, newIndex } = sort;
    reorderDocuments(oldIndex, newIndex);
  };

  const reorderDocuments = (oldIndex: number, newIndex: number) => {    
    if (oldIndex === newIndex) {
      return;
    }
    const rearrangedDocuments = arrayMoveImmutable(documentsList, oldIndex, newIndex);
    setDocumentsList(rearrangedDocuments);

    updateDocumentOrderHandler &&
      updateDocumentOrderHandler({
        categoryId: category,
        sortOrder: rearrangedDocuments.map((document) => document.id),
      });
  };

  return (
    <SortableList
      items={documentsList}
      pressDelay={200}
      onSortEnd={handleDragEnd}
      setDocumentIdAndOpenPopUpBox={setDocumentIdAndOpenPopUpBox}
      reorderDocuments={reorderDocuments}
    />
  );
};

import { FitClaim } from "./fit-claim";
import { OcgClaim } from "./ocg-claim";
import { GdprClaim } from "./gdpr-claim";

export { LegalJson } from "./legal";

export function getClaimConfig(vertical: string) {
  if (vertical === "FIT") {
    return FitClaim;
  } else if (vertical === "OCG") {
    return OcgClaim;
  } else {
    return GdprClaim;
  }
}

import { Intent } from "@blueprintjs/core";
import InfoCard, { IProps as IInfoCardProps } from "components/InfoCard";
import React from "react";
import { Product_Enum, useCaseSubmissionCountQuery } from "types/graphql.types";

interface IProps {
  productId: Product_Enum;
  email: string;
}

const DuplicateSubmissionCard: React.FC<IProps> = ({ productId, email }) => {
  const { data } = useCaseSubmissionCountQuery({
    variables: {
      productId,
      email,
    },
  });

  const currentCount = data?.case?.aggregate?.count;
  const show = currentCount ? currentCount > 1 : false;

  const infoCardProps: IInfoCardProps = {
    message: "There are multiple submissions from this email address.",
    icon: "delete",
    intent: Intent.DANGER,
    buttons: [
      {
        text: "Show Cases",
        link: `/cases/?vertical=${productId}&email=${email}`,
      },
    ],
  };

  return <>{show ? <InfoCard {...infoCardProps} /> : null}</>;
};

export default DuplicateSubmissionCard;

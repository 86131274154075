import { HTMLTable } from "@blueprintjs/core";
import React from "react";
import "./style.scss";
import ColumnHeader, { ISort } from "./ColumnHeader";

interface IColumn {
  id: string;
  label: string;
  columnLabel?: string;
}

interface IRow {
  id: string;
  url: string;
  cells: Array<string | number | React.ReactNode>;
}

interface Props {
  columns: IColumn[];
  rows: IRow[];
  sort?: ISort;
}

const List: React.FC<Props> = ({
  columns,
  rows,
  sort,
}) => {

  return (
    <HTMLTable interactive className={"list-table"} aria-label="List">
      <thead>
        <tr>
          {columns.map((column) => (
            <ColumnHeader key={column.id} {...column} sort={sort}></ColumnHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((item) => (
          <tr
            key={item.id}
            className={"list-row"}
          >
            {item.cells.map((cell, index) => {
              return (
                <td key={index}>
                  <a
                    href={item.url}
                  >
                    {cell}
                  </a>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  );
};

export default List;

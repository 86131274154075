import { gql } from "@apollo/client";

export const getCustomerPayoutsQuery = gql`
  query getCustomerPayouts(
    $take: Int = 10
    $skip: Int = 10
    $orderBy: [fs_customer_payout_order_by!] = {}
    $where: fs_customer_payout_bool_exp = {}
  ) {
    fs_customer_payout(limit: $take, offset: $skip, order_by: $orderBy, where: $where) {
      alias_id
      amount
      case_id
      created_at
      currency
      customer_iban
      customer_name
      id
      payment_reference
      status
      updated_at
      vertical
    }
    aggregate: fs_customer_payout_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

import className from "classnames";
import { ValueContext } from "process-ui-builder";
import { CircleIcon, DoneIcon, EditIcon } from "process-ui-builder/components/icons";
import { resolveValue } from "process-ui-builder/utils";
import React, { useContext } from "react";
import { Props } from "./types";

const Stepper: React.FC<Props> = ({
  config,
  currentStep,
  checkedSteps,
  activeStep,
  onStepChange,
}) => {
  const valueContext = useContext(ValueContext);

  //use current inactive just like done with conditions
  const stepClassName = (stepId: string, disabled?: boolean) => {
    return className("step", {
      current: stepId === activeStep,
      disabled: disabled === true,
    });
  };
  const getIcon = (stepId: string) => {
    if (stepId === currentStep) {
      return EditIcon;
    } else if (checkedSteps?.includes(stepId)) {
      return DoneIcon;
    } else {
      return CircleIcon;
    }
  };

  const onStepClick = (id: string, disabled?: boolean) => {
    if (!disabled) onStepChange(id);
  };

  return (
    <React.Fragment>
      <div className="stepper">
        {config.map((step) => {
          const disabled = resolveValue(step.disabled, valueContext);

          return (
            <div
              key={step.id}
              className={stepClassName(step.id, disabled)}
              onClick={() => onStepClick(step.id, disabled)}
            >
              <div className="step--circle">{getIcon(step.id)}</div>
              <div className="step--title">{step.label}</div>
              <div className="step--bar-left"></div>
              <div className="step--bar-right"></div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Stepper;

import React, { useState } from "react";
import { Alert, Button, ButtonGroup, Intent } from "@blueprintjs/core";
import { PaymentStatus } from "../types";
interface Props {
  status: string;
  id: string;
  onResolvePayment: (id: string, resolvedStatus: PaymentStatus) => Promise<void>;
}

const ALLOW_APPROVAL = [PaymentStatus.WAITING_FOR_APPROVAL, PaymentStatus.PAYMENT_REJECTED];

// TODO add "PAYMENT_APPROVED" below when functionality allows approved payments to be deleted from batch list
const ALLOW_REJECTION = [PaymentStatus.WAITING_FOR_APPROVAL];

export const ActionButtons: React.FC<Props> = ({ status, id, onResolvePayment }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertAction, setAlertAction] = useState<PaymentStatus>();

  const handleButton = (action: PaymentStatus) => {
    setShowAlert(true);
    setAlertAction(action);
  };

  const handleAlertConfirm = () => {
    if (alertAction)
      onResolvePayment(id, alertAction).then(() => {
        setShowAlert(false);
      });
  };

  return (
    <>
      <ButtonGroup fill={true}>
        <Button
          className="customer-payout-action-button"
          onClick={() => handleButton(PaymentStatus.PAYMENT_APPROVED)}
          text="Approve"
          fill={true}
          intent={Intent.PRIMARY}
          icon="tick"
          disabled={!ALLOW_APPROVAL.includes(status as PaymentStatus)}
        />

        <Button
          className="customer-payout-action-button"
          onClick={() => handleButton(PaymentStatus.PAYMENT_REJECTED)}
          text="Reject"
          fill={true}
          intent={Intent.DANGER}
          icon="cross"
          disabled={!ALLOW_REJECTION.includes(status as PaymentStatus)}
        />
      </ButtonGroup>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        icon={alertAction === PaymentStatus.PAYMENT_APPROVED ? "tick" : "cross"}
        intent={alertAction === PaymentStatus.PAYMENT_APPROVED ? Intent.PRIMARY : Intent.DANGER}
        isOpen={showAlert}
        onCancel={() => setShowAlert(false)}
        onConfirm={handleAlertConfirm}
        canEscapeKeyCancel={true}
      >
        <p>
          Are you sure you want to{" "}
          <b>{alertAction === PaymentStatus.PAYMENT_APPROVED ? "approve" : "reject"}</b> this
          payment?
        </p>
      </Alert>
    </>
  );
};

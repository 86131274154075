import { FormGroupSchema } from "process-ui-builder/config/types";

export const FitClaim: FormGroupSchema = {
  id: "claim_data",
  type: "form",
  size: {
    colSpan: 4,
    rowSpan: 2,
  },
  title: "Claim Data",
  readOnly: true,
  fields: [
    {
      id: "vertical",
      label: "Vertical",
      type: "text",
      value: { path: "case.vertical" },
    },
    {
      id: "debtor",
      label: "Debtor",
      type: "text",
      value: { path: "case.debtorName" },
    },
    {
      id: "company_id",
      label: "Company",
      type: "text",
      emptyValue: true,
      value: { path: "case.companyId" },
    },
    {
      id: "monthly_payment",
      label: "Monthly Payment",
      type: "money",
      value: { path: "case.claim.monthlyPayment" },
    },

    {
      id: "lockdown1",
      label: "Lockdown 1:",
      options: [
        { label: "March 2020", value: "2020-03" },
        { label: "April 2020", value: "2020-04" },
      ],
      type: "tagList",
      value: { path: "case.claim.lockdownPeriod" },
    },
    {
      id: "lockdown2",
      label: "Lockdown 2:",
      options: [
        { label: "November 2020", value: "2020-11" },
        { label: "December 2020", value: "2020-12" },
        { label: "January 2021", value: "2021-01" },
        { label: "February 2021", value: "2021-02" },
      ],
      type: "tagList",

      value: { path: "case.claim.lockdownPeriod" },
    },
    {
      id: "lockdown3",
      label: "Lockdown 3:",
      options: [
        { label: "March 2021", value: "2021-03" },
        { label: "April 2021", value: "2021-04" },
        { label: "May 2021", value: "2021-05" },
      ],
      type: "tagList",
      value: { path: "case.claim.lockdownPeriod" },
    },
    {
      id: "membership_number",
      label: "Membership Number",
      type: "text",
      value: { path: "case.claim.membershipNumber" },
    },
  ],
};

import React from "react";
import { Intent, Alert } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { useAdminRefuseOfferMutation } from "types/graphql.types";

interface IProps {
  caseId: string;
  vertical: string;
  isOpen: boolean;
  onRefuseOfferSuccess: () => void;
  onRefuseOfferFailure: () => void;
  onHide: () => void;
}

const ConfirmRefuseOfferModal: React.FC<IProps> = observer(
  ({ caseId, vertical, isOpen, onRefuseOfferSuccess, onRefuseOfferFailure, onHide }) => {
    const [refuseOfferMutation] = useAdminRefuseOfferMutation();

    const handleRefuseOffer = async () => {
      const input = {
        case: {
          id: caseId,
        },
      };

      const resp = await refuseOfferMutation({
        variables: {
          input: { configName: `${vertical.toLowerCase()}-claim-purchasing`, input },
        },
      });

      onHide();

      if (resp.errors?.length) {
        onRefuseOfferFailure();
        return;
      }
      onRefuseOfferSuccess();
    };

    return (
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isOpen}
        onCancel={onHide}
        onConfirm={handleRefuseOffer}
      >
        <p>
          This action means that the current offer will not be valid anymore as it has been refused
          by the customer.
        </p>
      </Alert>
    );
  }
);
export default ConfirmRefuseOfferModal;

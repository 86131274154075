import { Card, Collapse, H5, Icon } from "@blueprintjs/core";
import _ from "lodash";
import { ValueContext } from "process-ui-builder";
import { useContext, useEffect, useState } from "react";
import TextBox from "../../TextBox";
import "./style.scss";

export interface InsuredPerson {
  insuranceNumber: string;
  insuredSince: string;
  name: string;
  tariffs: string[];
}
interface IProps {
  person?: InsuredPerson;
  count: number;
  isOpen: boolean;
  disableCollapse?: boolean;
  onDeletePerson?: (count: number) => void;
  readOnly?: boolean;
}

const Person: React.FunctionComponent<IProps> = ({
  person,
  count,
  isOpen,
  disableCollapse,
  readOnly,
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(isOpen);
  const valueContext = useContext(ValueContext);
  const tariffs = _.get(valueContext, `case.claim.insuredPersons[${count}].tariffs`) || [];

  const toggleCollapsible = () => {
    if (!disableCollapse) {
      setIsCollapsibleOpen(!isCollapsibleOpen);
    }
  };

  useEffect(() => {
    setIsCollapsibleOpen(isOpen);
  }, [isOpen]);

  const getTariffs = () => {
    return tariffs.map((_tariff: string, index: number) => (
      <TextBox
        key={index}
        fieldOption={{
          label: `Tariff ${index + 1}`,
          readOnly,
          value: { path: `case.claim.insuredPersons[${count}].tariffs[${index}]` },
        }}
      />
    ));
  };

  return (
    <Card interactive={true} elevation={1} className="person">
      <H5 onClick={toggleCollapsible}>
        <Icon icon={!isCollapsibleOpen ? "caret-right" : "caret-down"} iconSize={18} />
        {count === 0 ? "Main Person" : `Person ${count + 1}`}
      </H5>
      {person && (
        <Collapse isOpen={!isCollapsibleOpen}>
          <pre onClick={toggleCollapsible}>
            Name: {person.name}
            <br />
            Insurance Number: {person.insuranceNumber}
          </pre>
        </Collapse>
      )}
      <Collapse isOpen={isCollapsibleOpen}>
        <TextBox
          fieldOption={{
            label: "Name",
            readOnly,
            value: { path: `case.claim.insuredPersons[${count}].name` },
          }}
        />
        {getTariffs()}
        <TextBox
          fieldOption={{
            id: `insured_since_${count}`,
            label: "Insured Since",
            readOnly,
            value: { path: `case.claim.insuredPersons[${count}].insuredSince` },
          }}
        />
        <TextBox
          fieldOption={{
            label: "Insurance Number",
            readOnly,
            value: { path: `case.claim.insuredPersons[${count}].insuranceNumber` },
          }}
        />
      </Collapse>
    </Card>
  );
};
export default Person;

import { FormField } from "process-ui-builder/config";

export const formatMoneyVerticals = (
  value: number | null | undefined,
  currency: string = "€"
): string => {
  if (value == null || undefined) {
    return "0,00";
  } else {
    const amount = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    return `${amount} ${currency}`;
  }
};

export const getNumberValueFromLocale = (value: any, locale: string = "de-DE"): number => {
  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
  const group = new RegExp(`[${parts.find((d) => d.type === "group")?.value}]`, "g");
  const decimal = new RegExp(`[${parts.find((d) => d.type === "decimal")?.value}]`);

  return Number(value.trim().replace(group, "").replace(decimal, "."));
};

export const getLocaleFromFields = (fields: any, fieldName: string) => {
  const filteredFields = fields.filter((field: FormField) => field.id === fieldName);
  return filteredFields.length ? filteredFields[0].money?.locale : "de-DE";
};

export const convertValueToNumber = (number: string | number): number => {
  if (typeof number === "string") {
    return Number(number.replace(/\./g, "").replace(/,/g, "."));
  } else if (typeof number === "number") {
    return number;
  }
  throw new Error(`Invalid number type: ${typeof number}, value: ${number}`);
};

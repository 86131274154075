import React, { useContext, useMemo } from "react";

import { DocumentManagerContext } from "document-manager/DocumentManager";
import { getOptionsWithEmptyOption } from "document-manager/utils";

import { RequestDocumentsButton } from "./request-documents";
import { SelectFilesButton } from "./uploads";

import "./styles.scss";

export const Footer: React.FC = () => {
  const { categories } = useContext(DocumentManagerContext);
  const categoryOptions = useMemo(() => {
    const categoriesOptions = categories?.map((category) => {
      return { label: category.label, value: category.id };
    });
    return getOptionsWithEmptyOption(categoriesOptions, "Select a document category");
  }, [categories]);

  return (
    <div className="footer">
      <SelectFilesButton categories={categoryOptions} />
      <RequestDocumentsButton categories={categoryOptions} />
    </div>
  );
};

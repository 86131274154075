import React, { createContext, useContext } from "react";
import { Card, Elevation, NonIdealState } from "@blueprintjs/core";

import { TContext } from "./types";
import { DocumentTable } from "./document-table";
import { Header } from "./header";
import { Footer } from "./footer";

import "./styles.scss";

export const DocumentManagerContext = createContext<TContext>({});

export const DocumentManager: React.FC = () => {
  const { documents } = useContext(DocumentManagerContext);
  return (
    <Card elevation={Elevation.ONE} className="document-manager base-card">
      <Header />
      {documents ? <DocumentTable /> : <NonIdealState icon={"document"} title="No Documents" />}
      <Footer />
    </Card>
  );
};

import React, { useState } from "react";
import { Icon, Intent } from "@blueprintjs/core";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  url: string;
  children?: React.ReactNode;
}

const PreviewFile: React.FC<IProps> = ({ url }) => {
  const [numPages, setNumPages] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
      <a href={url} target="_blank" rel="noreferrer">
        <Icon icon="download" iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} />
      </a>
    </div>
  );
};

export default PreviewFile;

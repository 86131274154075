import { AuthActionTypes, REMOVE_ROLES, SET_ROLES } from "../actions/roles";

interface Roles {
  roles?: string[];
}

type RolesReducerTypes = Roles;

const initialState: RolesReducerTypes = {};

export default function reducer(state = initialState, action: AuthActionTypes): RolesReducerTypes {
  switch (action.type) {
    case SET_ROLES: {
      return {
        ...state,
        roles: action.payload.roles,
      };
    }
    case REMOVE_ROLES: {
      return {
        ...state,
        roles: [],
      };
    }
    default:
      break;
  }
  return state;
}

import { Card, Elevation, H5 } from "@blueprintjs/core";
import get from "lodash/get";
import { ValueContext } from "process-ui-builder";
import React, { useContext } from "react";
import { CLAIM_AMOUNT_OPTIONS, SOURCE_OF_ESTIMATION_OPTIONS } from "./const";
import Persons from "./Persons";
import SelectBox from "./SelectBox";
import "./style.scss";
import TextBox from "./TextBox";

const PkvClaim: React.FunctionComponent = () => {
  const valueContext = useContext(ValueContext);
  const isEstimatedClaimAmount = get(valueContext, "case.claim.is_estimated_claim_amount");
  const readOnly = true;

  return (
    <Card elevation={Elevation.ONE} className="base-card pkv-claim-card">
      <H5>Claim Data</H5>
      <TextBox
        fieldOption={{
          id: "vertical",
          label: "Vertical",
          readOnly,
          value: { path: "case.vertical" },
        }}
      />
      <TextBox
        fieldOption={{
          id: "debtor",
          label: "Debtor",
          readOnly,
          value: { path: "case.debtorName" },
        }}
      />
      <TextBox
        fieldOption={{
          id: "company_id",
          label: "Company",
          readOnly,
          value: { path: "case.companyId" },
        }}
      />
      <TextBox
        fieldOption={{
          label: "Insurance Increase Date",
          id: "insurance_increase_date",
          readOnly,
          value: { path: "case.claim.insuranceIncreaseDate" },
        }}
      />

      <SelectBox
        fieldOption={{
          id: "claim_amount_option",
          label: "Claim Amount",
          readOnly,
          optionsData: CLAIM_AMOUNT_OPTIONS,
          value: { path: "case.claim.isEstimatedClaimAmount" },
          emptyValue: true,
        }}
      />

      {isEstimatedClaimAmount && (
        <SelectBox
          fieldOption={{
            id: "source_of_estimation",
            label: "Source of estimation",
            readOnly,
            optionsData: SOURCE_OF_ESTIMATION_OPTIONS,
            value: { path: "case.claim.sourceOfEstimation" },
            emptyValue: true,
          }}
        />
      )}
      <Persons readOnly={readOnly} />
    </Card>
  );
};
export default PkvClaim;

import { gql } from "@apollo/client";
import { Case_List_View_Bool_Exp, Maybe, Order_By } from "../../types/graphql.types";

export const getListQuery = (vertical: string) => {
  vertical = vertical.toLowerCase();

  return gql`
    query adminListCases(
      $limit: Int
      $offset: Int
      $orderByAliasId: order_by
      $orderByLastName: order_by
      $orderByEmail: order_by
      $orderByClaim: ${vertical}_claim_order_by
      $orderByCaseState: order_by
      $orderByOfferState: order_by
      $orderByCreatedAt: order_by
      $orderByUpdatedAt: order_by
      $orderByClaimAmount: order_by
      $where: case_list_view_bool_exp
    ) {
      cases: case_list_view(
        where: $where
        order_by: {
          alias_id: $orderByAliasId
          last_name: $orderByLastName
          email: $orderByEmail
          ${vertical}_claim: $orderByClaim
          state: $orderByCaseState
          offer_state: $orderByOfferState
          created_at: $orderByCreatedAt
          updated_at: $orderByUpdatedAt
          claim_amount: $orderByClaimAmount
        }
        limit: $limit
        offset: $offset
      ) {
        id
        alias_id
        state
        offer_state
        created_at
        updated_at
        first_name
        last_name
        email
        claim_amount

        claim: ${vertical}_claim {
          company {
            name
          }
        }
      }

      aggregate: case_list_view_aggregate(where: $where) {
        aggregate {
          count
        }
      }

      companyOptions: ${vertical}_company(order_by: { name: asc }) {
        label: name
        value: id
      }
    }
  `;
};

export interface AdminListCasesQuery {
  cases: {
    id: string;
    alias_id: string;
    state: string;
    offer_state: string;
    created_at: string;
    updated_at: string;
    claim_amount: Maybe<number>;

    claim: {
      company: {
        name: string;
      };
    };

    first_name: string;
    last_name: string;
    email: string;
  }[];

  aggregate: {
    aggregate: {
      count: number;
    };
  };

  companyOptions: {
    label: string;
    value: string;
  }[];
}

export interface AdminListCasesQueryVariables {
  limit: number;
  offset: number;
  orderByAliasId?: Maybe<Order_By>;
  orderByLastName?: Maybe<Order_By>;
  orderByClaim?: {
    company?: {
      name?: Maybe<Order_By>;
    };
  };
  orderByState?: Maybe<Order_By>;
  orderByCreatedAt?: Maybe<Order_By>;
  orderByUpdatedAt?: Maybe<Order_By>;
  orderByClaimAmount?: Maybe<Order_By>;
  where?: Maybe<Case_List_View_Bool_Exp>;
}
